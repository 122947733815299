import { Button, Icon, Image, Skeleton, Stack, Text, Tooltip } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import { IScroll, IShortcutListData } from '../../../../Interfaces/IDirectLinks';
import getShortcutIcon from '../../../../utils/getShortcutIcon';
import DirectLinkPending from './DirectLinkPending';
import { StyledDirectLinks, StyledDirectLinksGlobalStyles, StyledDivButtons } from './styled';

interface IBDirectLinks {
  datos: IScroll;
  MAvailables: IShortcutListData[];
  shortcutList: IShortcutListData[];
  onOpen: () => void;
  isLoading: boolean;
}

function DirectLinksDefault({ datos, MAvailables, shortcutList, onOpen, isLoading }: IBDirectLinks): JSX.Element {
  const location = useLocation();
  const { status, toolTip } = datos;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleWidth);

  const handleOnEdit = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Editar accesos directos y tarjetas',
      item_id: 'Editar accesos directos',
    });
    onOpen();
  };

  const renderShortcutBody = (categoria, nombreAtajo) => (
    <>
      <Tooltip label={nombreAtajo} hasArrow isDisabled={location.pathname !== '/' || windowWidth <= 1342 ? false : !toolTip} mt='3.5'>
        <div className='custom-icon'>
          <Image src={getShortcutIcon(categoria)} maxWidth='50px' />
        </div>
      </Tooltip>

      {nombreAtajo.length > 20 ? (
        <Tooltip label={nombreAtajo} hasArrow placement='bottom' mt='4'>
          <Text className='shortcut-text' textStyle='bodyMd'>
            {categoria} <Text className='shortcut-text__category' textStyle='titleSmBold'>{`${nombreAtajo.substring(0, 20)}...`}</Text>
          </Text>
        </Tooltip>
      ) : (
        <Text className='shortcut-text' textStyle='bodyMd'>
          {categoria}{' '}
          <Text className='shortcut-text__category' textStyle='titleSmBold'>
            {nombreAtajo}
          </Text>
        </Text>
      )}
    </>
  );

  const pushAnalyticsEventOnOpen = (category, name) =>
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Acceso directo',
      item_id: `${category} - ${name}`,
    });

  const renderShortcut = ({ categoria, nombreAtajo, url, openMode }: IShortcutListData) => {
    return (
      <OpenLink
        url={url}
        openMode={openMode}
        reuseTab
        onClick={() => {
          pushAnalyticsEventOnOpen(categoria, nombreAtajo);
        }}
      >
        {renderShortcutBody(categoria, nombreAtajo)}
      </OpenLink>
    );
  };

  return (
    <>
      <StyledDirectLinksGlobalStyles />
      <StyledDivButtons className={`StyledDivButtons ${status || location.pathname !== '/' ? 'fixed' : ''}`}>
        <DirectLinkPending status={status} toolTip={toolTip} />
        <StyledDirectLinks className={`directLinks ${status || location.pathname !== '/' ? 'fixed' : ''}`}>
          {isLoading ? (
            <Stack role='group' spacing={8} direction='row' width='100%'>
              <Skeleton role='listitem' height='65px' width='20%' startColor='tertiary.50' endColor='danger.50' />
              <Skeleton role='listitem' height='65px' width='20%' startColor='tertiary.50' endColor='danger.50' />
              <Skeleton role='listitem' height='65px' width='20%' startColor='tertiary.50' endColor='danger.50' />
              <Skeleton role='listitem' height='65px' width='20%' startColor='tertiary.50' endColor='danger.50' />
            </Stack>
          ) : (
            shortcutList &&
            MAvailables?.length > 0 && (
              <ul id='directLinks'>
                {MAvailables.map((item: IShortcutListData) => (
                  <li key={`${item.id}`}>{renderShortcut(item)}</li>
                ))}

                <li className='edit'>
                  <Button onClick={handleOnEdit}>
                    <Icon as={FiEdit2} color='#2172DC' w={5} h={5} />
                  </Button>
                </li>
              </ul>
            )
          )}
        </StyledDirectLinks>
      </StyledDivButtons>
    </>
  );
}

export default DirectLinksDefault;
