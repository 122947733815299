// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsFromError, useCriticalError, useErrorMessage } from '@react-ib-mf/style-guide-ui';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import plainText from '../../lang/es.json';
import { getMenu } from '../../services/api';
import SidebarMenu from './SidebarMenu';

function Sidebar() {
  const [, setCriticalError] = useCriticalError();
  const { showErrorMessage } = useErrorMessage();

  const {
    error: menuError,
    data,
    isFetching,
  } = useQuery('menu', () => getMenu().then(res => res?.data?.data?.options), { onError: pushAnalyticsFromError });

  useEffect(() => {
    if (menuError) {
      setCriticalError({
        title: plainText.criticalError.sidebar.tittle,
        analiticOnReload: {
          event: 'click_modal',
          content_group: 'Home',
          modal_name: 'Error de Servicio - Error menu lateral',
          item_id: 'CTA Recargar',
        },
      });
      showErrorMessage({ title: plainText.toast.error.asideSidebar, error: menuError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuError]);

  return <SidebarMenu dataMenu={data || []} isLoading={isFetching} />;
}
export default Sidebar;
