import { Avatar, Button, FormControl, FormLabel, Icon, Input } from '@chakra-ui/react';
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-unresolved
import { useErrorMessage } from '@react-ib-mf/style-guide-ui';
import React, { useEffect, useState } from 'react';
import { FiSettings, FiShield, FiUser } from 'react-icons/fi';
import { RiLinkedinFill } from 'react-icons/ri';
import { useQuery } from 'react-query';

import {
  FONT_PROFILE_MODAL_AVATAR,
  FONT_PROFILE_MODAL_FORM,
  FONT_PROFILE_MODAL_LINK_BUTTON,
  FONT_PROFILE_MODAL_NAV_LINK,
  FONT_PROFILE_MODAL_NAVIGATION,
} from '../../../designTokens/fonts';
import plainText from '../../../lang/es.json';
import { getCaracteristicaTelf, getDetallePerfil } from '../../../services/api';
import { getAvatarName } from '../../../utils/names';
import SelectCaracteristica from './customSelect/SelectCaracteristica';
import SelectNacionalidad from './customSelect/SelectNacionalidad';
import options from './options';
import ShareData from './ShareData';

export interface INacionalidad {
  characteristic: string;
  countryCode: string;
  countryId: number;
  countryName: string;
}

interface ProfileDetailsProps {
  showShareData: boolean;
  setIsCheckedShareData: (shareData: boolean) => void;
  isCheckedShareData: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
}

function ProfileDetails({ showShareData, setIsCheckedShareData, isCheckedShareData, isFetching, isLoading, isError }: ProfileDetailsProps) {
  const { showErrorMessage } = useErrorMessage();
  const { data, error } = useQuery('detalles-perfil', () => getDetallePerfil().then(res => res.data));
  const { data: caracteristicaTelf, error: errorPaises } = useQuery('caracteristica-telefonica', () =>
    getCaracteristicaTelf().then(res => res.data),
  );

  // nacionalidad y caracteristica retornan el objeto al que se le dio click del select
  const [nacionalidad, setNacionalidad] = useState<INacionalidad>({
    characteristic: '',
    countryCode: '',
    countryId: 0,
    countryName: '',
  });
  const [caracteristica, setCaracteristica] = useState<INacionalidad>({
    characteristic: ``,
    countryCode: '',
    countryId: 0,
    countryName: '',
  });

  // flag que maneja el estado de los links para cambiar el estilo cuando esta activo
  const isLinkActive = true;
  // opcion por default
  const defaultOpt: INacionalidad = {
    countryCode: 'AR',
    characteristic: data && `+${data?.data?.caracteristica}`,
    countryId: 0,
    countryName: '',
  };
  const formatCuil = (str: string) => {
    if (!str) {
      return ' ';
    }
    const first = str.slice(0, 2);
    const restOfStr = str.slice(2, str.length - 1);
    const last = str.slice(str.length - 1, str.length);
    return `${first}-${restOfStr}-${last}`;
  };
  const formatTelefono = (str: string) => {
    if (!str) {
      return ' ';
    }
    const first = str.slice(0, 3);
    const restOfStr = str.slice(3, str.length - 4);
    const last = str.slice(str.length - 4, str.length);
    return `${first}-${restOfStr}-${last}`;
  };
  useEffect(() => {
    if (error || errorPaises) {
      showErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorPaises]);

  const inputTextStyle = {
    width: '200px',
  };
  const inputCuilStyle = {
    width: '136px',
  };
  const inputMailStyle = {
    width: '271px',
  };
  const inputTelStyle = {
    width: '174px',
  };

  return (
    <ProfileData>
      <Avatar name={getAvatarName(data?.data?.nombre, data?.data?.apellido)} size='xl' className='avatar' />
      <div className='body'>
        <div className='navigation'>
          <h2>{plainText.label.editProfile}</h2>
          <div className='navigation--links'>
            <ul>
              <li className={isLinkActive ? 'active' : ''}>
                <Icon as={FiUser} w={5} h={5} />
                <a href='/#'>{plainText.label.personalData}</a>
              </li>
              <li>
                <Icon color='secondary.70' as={FiShield} w={5} h={5} />
                <p>{plainText.label.securityPassword}</p>
              </li>
              <li>
                <Icon color='secondary.70' as={FiSettings} w={5} h={5} />
                <p>{plainText.label.settingsPreferences}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className='form'>
          <div className='linkedin-btn'>
            <Button disabled>
              <i className='icon'>
                <Icon as={RiLinkedinFill} w={4} h={4} />
              </i>
              {plainText.button.linkedIn}
            </Button>
          </div>
          <div className='form--container'>
            <FormControl className='group'>
              <div className='row'>
                <div className='field'>
                  <FormLabel htmlFor='nombre'>{plainText.label.name}</FormLabel>
                  <Input
                    className='profile-details-input'
                    id='nombre'
                    type='text'
                    sx={inputTextStyle}
                    value={data?.data?.nombre ?? ''}
                    size='sm'
                    isDisabled
                  />
                </div>
                <div className='field'>
                  <FormLabel htmlFor='apellido'>{plainText.label.lastName}</FormLabel>
                  <Input
                    className='profile-details-input'
                    id='apellido'
                    type='text'
                    sx={inputTextStyle}
                    value={data?.data?.apellido ?? ''}
                    size='sm'
                    isDisabled
                  />
                </div>
              </div>
              <div className='row'>
                <div className='field'>
                  <FormLabel htmlFor='nacionalidad'>{plainText.label.nationality}</FormLabel>
                  <SelectNacionalidad
                    nacionalidad={nacionalidad}
                    setNacionalidad={setNacionalidad}
                    paises={options.data}
                    defaultOption={data?.data?.codigoPais || ''}
                    disable
                  />
                </div>
                <div className='field'>
                  <FormLabel htmlFor='cuil'>{plainText.label.cuil}</FormLabel>
                  {data && data.data?.codigoPais !== 'AR' ? (
                    <Input
                      className='profile-details-input'
                      id='cuil'
                      type='text'
                      value={data?.data?.cuil ?? ''}
                      sx={inputCuilStyle}
                      size='sm'
                      isDisabled
                    />
                  ) : (
                    <Input
                      className='profile-details-input'
                      id='cuil'
                      type='text'
                      value={formatCuil(data?.data?.cuil) ?? ''}
                      sx={inputCuilStyle}
                      size='sm'
                      isDisabled
                    />
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='field'>
                  <FormLabel htmlFor='correo'>{plainText.label.email}</FormLabel>
                  <Input
                    className='profile-details-input'
                    id='correo'
                    type='text'
                    value={data?.data?.correoElectronico ?? ''}
                    sx={inputMailStyle}
                    size='sm'
                    isDisabled
                  />
                </div>
                <div className='field'>
                  <FormLabel htmlFor='telefono'>{plainText.label.phone}</FormLabel>
                  <div className='caracteristica-telf'>
                    <SelectCaracteristica
                      caracteristica={caracteristica}
                      setCaracteristica={setCaracteristica}
                      paises={caracteristicaTelf?.data}
                      defaultOption={defaultOpt}
                      disable
                    />
                    <Input
                      className='profile-details-input semi-rounded-input'
                      id='telefono'
                      type='text'
                      value={formatTelefono(data?.data?.telefono) ?? ''}
                      sx={inputTelStyle}
                      size='sm'
                      isDisabled
                    />
                  </div>
                </div>
              </div>
              {showShareData && (
                <div className='row'>
                  <div className='field'>
                    <ShareData
                      onSave={setIsCheckedShareData}
                      isCheckedValue={isCheckedShareData}
                      isFetching={isFetching}
                      isLoading={isLoading}
                      isError={isError}
                    />
                  </div>
                </div>
              )}
            </FormControl>
          </div>
        </div>
      </div>
      {/* <Button className='save-btn'>Guardar</Button> */}
    </ProfileData>
  );
}

export default ProfileDetails;

const ProfileData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 0 0 35px;
  height: 100%;

  .profile-details-input {
    padding: 0px 12px;
  }

  .semi-rounded-input {
    border-radius: 0 5px 5px 0;
  }

  .avatar {
    position: absolute;
    top: -145px;
    width: 171px;
    height: 171px;
    border-color: transparent;
    background-color: var(--chakra-colors-primary-100);
    color: white;
    & .chakra-avatar {
      &__initials {
        font-size: ${FONT_PROFILE_MODAL_AVATAR.fontSize};
        font-family: ${FONT_PROFILE_MODAL_AVATAR.fontFamily};
        font-weight: ${FONT_PROFILE_MODAL_AVATAR.fontWeight};
      }
    }
  }

  .save-btn {
    border: none;
    background-color: var(--chakra-colors-primary-100);
    color: var(--chakra-colors-warning-50);
    padding: 12px;
    width: 172px;
    height: 48px;
    border-radius: 8px;
    margin: 50px 0 42px 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: var(--chakra-colors-tertiary-120);
      border-color: var(--chakra-colors-tertiary-120);
      color: white;
    }
  }
  .body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    .navigation {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 30%;
      height: 100%;
      margin-top: 24px;
      h2 {
        font-size: ${FONT_PROFILE_MODAL_NAVIGATION.fontSize};
        line-height: ${FONT_PROFILE_MODAL_NAVIGATION.lineHeight};
      }
      .navigation--links {
        ul {
          width: 200px;
          margin-top: 16px;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            list-style: none;
            margin: 0 0 24px 0;
            &.active {
              color: var(--chakra-colors-primary-110);
              border-bottom: 2px solid var(--chakra-colors-primary-110);
              a {
                color: var(--chakra-colors-primary-110);
              }
            }
            a,
            p {
              margin: 0;
              font-size: ${FONT_PROFILE_MODAL_NAV_LINK.fontSize};
              padding: 6px;
              text-decoration: none;
              color: var(--chakra-colors-secondary-70);
            }
          }
        }
      }
    }
    .form {
      width: 75%;
      height: 100%;
      .linkedin-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
        button {
          :disabled {
            background-color: var(--chakra-colors-grey-40);
            color: var(--chakra-colors-secondary-70);
            cursor: default;
            opacity: 0.5;
          }
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          background-color: var(--chakra-colors-warning-50);
          border: none;
          border-radius: 6px;
          width: 177px;
          height: 34px;
          padding: 7px;
          font-size: ${FONT_PROFILE_MODAL_LINK_BUTTON.fontSize};
          font-weight: ${FONT_PROFILE_MODAL_LINK_BUTTON.fontWeight};
          :enabled {
            color: var(--chakra-colors-info-110);
            .icon {
              background-color: var(--chakra-colors-info-110);
            }
          }
          cursor: pointer;
          .icon {
            height: 22px;
            width: 22px;
            background-color: var(--chakra-colors-secondary-70);
            border-radius: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            :enabled {
              background-color: var(--chakra-colors-info-110);
            }
            svg {
              position: absolute;
              fill: white;
            }
          }
          &:hover:enabled {
            background-color: var(--chakra-colors-warning-50);
          }
        }
      }
      .form--container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        .group {
          margin-top: 65px;
          display: flex;
          flex-direction: column;
          .row {
            display: flex;
            justify-content: flex-start;
            .field {
              display: flex;
              flex-direction: column;
              margin-bottom: 17px;
              p:enabled {
                color: var(--chakra-colors-tertiary-100);
              }
              &:not(&:last-child) {
                margin-right: 36px;
              }
              label {
                font-size: ${FONT_PROFILE_MODAL_FORM.fontSize};
                font-weight: ${FONT_PROFILE_MODAL_FORM.fontWeight};
                color: var(--chakra-colors-secondary-90);
                margin-left: 2px;
                margin-bottom: 3px;
              }
              .caracteristica-telf {
                display: flex;
                justify-content: flex-start;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
`;
