// eslint-disable-next-line import/prefer-default-export
export const AUTHORIZATION_STATUS = {
  SUCCESSFUL: '00',
  OPERATOR_NOT_ENABLED: '10',
  PENDING_VINCULATION_KEY: '20',
  BLOCKED_BY_VINCULATION_KEY: '21',
  TYC_PENDING: '30',
  TYC_PENDING_NOT_POSTPONE: '31',
};

export const TYPE_MIGRATION = {
  Y: 'Y',
  HARDTOKEN: 'HT',
  SOFTTOKEN: 'ST',
};
