import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { FiChevronRight, FiHelpCircle } from 'react-icons/fi';
import { BrowserRouter, Link } from 'react-router-dom';

import HeaderComponentInterface, { Breads } from '../../../../Interfaces/IHeaderComponent';
import { CustomChakraProvider } from '../../../../providers/CustomChakraProvider';
import StyledHeaderBase from './StyledHeaderBase';

function HeaderBase({
  title,
  description,
  tooltipText,
  breadcrumb,
  tooltipTextComponent,
  idHeader,
  customStyle,
}: HeaderComponentInterface) {
  const getBreadcrumb = () =>
    breadcrumb &&
    breadcrumb.map(({ id, name, linkTo }: Breads) => (
      <BreadcrumbItem data-testid={`bread-${id}`} key={id} className={linkTo ? '' : 'itemInactive'}>
        {linkTo ? (
          <BreadcrumbLink as={Link} to={linkTo}>
            {name}
          </BreadcrumbLink>
        ) : (
          <BreadcrumbLink cursor='default' _hover={{ textDecoration: 'none' }}>
            {name}
          </BreadcrumbLink>
        )}
      </BreadcrumbItem>
    ));

  const formatWeightText = text => {
    const parts = text.split('*');
    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return <span key={part}>{part}</span>; // Texto normal
      }
      return (
        <span key={part} style={{ fontWeight: 'bold' }}>
          {part}
        </span>
      ); // Texto en negrita
    });
  };

  const descriptionFormatted = description?.split('\n').map(line => (
    <React.Fragment key={line}>
      {formatWeightText(line)}
      <br />
    </React.Fragment>
  ));

  return (
    <BrowserRouter>
      <CustomChakraProvider>
        <StyledHeaderBase style={{ width: customStyle }}>
          {breadcrumb === undefined || breadcrumb.length === 0 ? null : (
            <Breadcrumb role='none' separator={<Icon as={FiChevronRight} display='flex' w='18px' h='18px' />} className='breadcrumb'>
              {getBreadcrumb()}
            </Breadcrumb>
          )}
          {tooltipText === undefined || tooltipText === '' ? null : (
            <h1 className='title' id={idHeader}>
              {title}
              {tooltipText === undefined || tooltipText === '' ? null : (
                <Tooltip hasArrow label={tooltipText} aria-label='A tooltip' placement='right'>
                  <span>
                    <Icon as={FiHelpCircle} className='icon' w={6} h={6} />
                  </span>
                </Tooltip>
              )}
            </h1>
          )}
          {tooltipTextComponent !== undefined || tooltipTextComponent !== null ? (
            <h1 className='title' id={idHeader}>
              {title}
              {tooltipTextComponent === undefined || tooltipTextComponent === null ? null : (
                <Tooltip hasArrow label={tooltipTextComponent} aria-label='A tooltip' placement='right'>
                  <span>
                    <Icon as={FiHelpCircle} className='icon' w={6} h={6} />
                  </span>
                </Tooltip>
              )}
            </h1>
          ) : null}
          {description === undefined || description === '' ? null : (
            <p role='contentinfo' className='description'>
              {descriptionFormatted}
            </p>
          )}
        </StyledHeaderBase>
      </CustomChakraProvider>
    </BrowserRouter>
  );
}

export default HeaderBase;
