import '@fontsource/red-hat-display/300.css';
import '@fontsource/red-hat-display/400.css';
import '@fontsource/red-hat-display/500.css';
import '@fontsource/red-hat-display/600.css';
import '@fontsource/red-hat-display/700.css';
import '@fontsource/red-hat-display/800.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '../fonts/adobe-blank.css';

import { PortalManager } from '@chakra-ui/portal';
import { ChakraProvider, ChakraProviderProps, extendTheme } from '@chakra-ui/react';
import { EnvironmentProvider } from '@chakra-ui/react-env';
import { toCSSVar } from '@chakra-ui/styled-system';
import { ColorModeProvider, CSSVars, GlobalStyle } from '@chakra-ui/system';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
// eslint-disable-next-line import/no-unresolved
import { theme as ThemeData } from '@react-ib-mf/style-guide-ui';
import { AvatarChakra } from '@wow/avatar';
import { BadgeChakra } from '@wow/badge';
import { ButtonChakra } from '@wow/button';
import { CheckboxChakra } from '@wow/checkbox';
import { FormChakra } from '@wow/form-control';
import { FormLabelChakra } from '@wow/form-label';
import { InputChakra } from '@wow/input';
import { themeChakra } from '@wow/themes';
import { TooltipChakra } from '@wow/tooltip';
import { useMemo } from 'react';

export interface CustomChakraProviderProps extends ChakraProviderProps {
  addCSSVars?: boolean;
}

/**
 * The global provider that must be added to make all Chakra components
 * work correctly
 */
export function CustomChakraProvider({
  children,
  addCSSVars = false,
  colorModeManager,
  portalZIndex,
  resetCSS = false,
  environment,
  theme = extendTheme({
    ...ThemeData,
    textStyles: {
      ...themeChakra.textStyles,
    },
    colors: {
      ...themeChakra.colors,
    },
    shadows: {
      ...themeChakra.shadows,
    },
    components: {
      ...ThemeData.components,
      Avatar: AvatarChakra,
      Button: ButtonChakra,
      Checkbox: CheckboxChakra,
      Form: FormChakra,
      FormLabel: FormLabelChakra,
      Input: InputChakra,
      Spinner: {},
      Tooltip: TooltipChakra,
      Badge: BadgeChakra,
    },
  }),
  cssVarsRoot,
}: CustomChakraProviderProps): JSX.Element {
  const childrenWithEnvironment = <EnvironmentProvider environment={environment}>{children}</EnvironmentProvider>;
  const computedTheme = useMemo(() => toCSSVar(theme), [theme]);
  return (
    <ChakraProvider>
      <EmotionThemeProvider theme={computedTheme}>
        {addCSSVars && <CSSVars root={cssVarsRoot} />}
        <ColorModeProvider colorModeManager={colorModeManager} options={theme.config}>
          {resetCSS && <GlobalStyle />}
          {addCSSVars && <GlobalStyle />}
          {portalZIndex ? <PortalManager zIndex={portalZIndex}>{childrenWithEnvironment}</PortalManager> : childrenWithEnvironment}
        </ColorModeProvider>
      </EmotionThemeProvider>
    </ChakraProvider>
  );
}
