/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import { Log, UserManager, WebStorageStateStore } from 'oidc-client-ts';

import IDENTITY_CONFIG from './IDENTITY_CONFIG';
import { cleanLastActivity, removeSibSessionFlag } from './sibSession';

export default class AuthService {
  UserManager;

  private userInfoUrl;

  private introspectionUrl;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    });

    if (process.env.ENABLE_OIDC_LOG) {
      Log.setLogger(console);
      Log.setLevel(Log.DEBUG);
    }

    this.UserManager.events.addSilentRenewError();

    this.UserManager.events.addAccessTokenExpired(() => {
      this.signinSilent();
    });
    this.setUserInfoUrl();
    this.setIntrospectionUrl();
  }

  private setUserInfoUrl = async () => {
    this.userInfoUrl = await this.UserManager.getUserInfoEndpoint();
  };

  private setIntrospectionUrl = async () => {
    this.introspectionUrl = await this.UserManager.getIntrospectionEndpoint();
  };

  hasAuthParams = (location = window.location) => {
    // response_mode: query
    let searchParams = new URLSearchParams(location.search);
    if ((searchParams.get('code') || searchParams.get('error')) && searchParams.get('state')) {
      return true;
    }

    // response_mode: fragment
    searchParams = new URLSearchParams(location.hash.replace('#', '?'));
    if ((searchParams.get('code') || searchParams.get('error')) && searchParams.get('state')) {
      return true;
    }

    return false;
  };

  signinRedirectCallback = () => this.UserManager.signinRedirectCallback();

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (args?: any): Promise<void> => {
    const redirectUri = args?.operadorId ? '/' : window.location.pathname + window.location.search;
    localStorage.setItem('redirectUri', redirectUri);
    return this.UserManager.signinRedirect({ extraQueryParams: args });
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`),
    );

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  isAuthenticatedUserEndpoint = async (): Promise<boolean> => {
    const user = await this.UserManager.getUser();
    if (!user?.access_token || !this.userInfoUrl) return false;
    return fetch(`${this.userInfoUrl}?access_token=${user.access_token}`)
      .then(response => {
        return response.ok;
      })
      .catch(() => {
        return false;
      });
  };

  // falla el request al endpoint
  isAuthenticatedIntrospection = async (): Promise<boolean> => {
    const user = await this.UserManager.getUser();
    const urlencoded = new URLSearchParams();
    urlencoded.append('token', user.access_token);
    urlencoded.append('client_id', IDENTITY_CONFIG.client_id);
    urlencoded.append('client_secret', ' ');
    const myHeaders = new Headers();
    // El auth esta recontra harcodeado
    myHeaders.append('Authorization', `Basic e3tjbGllbnRJZH19Ont7Y2xpZW50U2VjcmV0fX0=`);
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    const redirect: RequestRedirect = 'follow';
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect,
    };

    await fetch(this.introspectionUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.ok;
        }
        return response.ok;
      })
      .catch(() => {
        return false;
      });
    return false;
  };

  signinSilent = () => {
    this.UserManager.signinSilent();
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  // ------ logout ----------
  /*
    Este logout conciste en seguir el flujo, OIDC de manera (SOLO) local.
    Logout de CAS se realiza mediante el logout de SIB. 
    Esto elimina la sesion del usuario en CAS, ademas de la sesion
    del usuario en shiro.
    Cuando en plataforma2, NO EXISTA nada relacionado con sib, entonces
    se debe usar para logout la propiedad "logoutRedirect"
  */
  // ------------------------
  logout = () => {
    removeSibSessionFlag();
    cleanLastActivity();
    this.UserManager.signoutNoRedirect();
    this.UserManager.clearStaleState();
    sessionStorage.clear();

    window.location.replace(process.env.URL_LOGOFF_SIB);
  };

  logoutRedirect = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState();
  };

  querySessionStatus = () => {
    return this.UserManager.querySessionStatus();
  };

  removeUser = () => {
    this.UserManager.removeUser();
  };

  getUserInfoEndpoint = () => {
    return this.UserManager.getUserInfoEndpoint();
  };

  validateSession = async () => {
    if (!(await this.isAuthenticatedUserEndpoint())) {
      removeSibSessionFlag();
      this.signinRedirect();
    }
  };
}
