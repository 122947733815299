// eslint-disable-next-line import/no-unresolved
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Help from './header/Help';
import NotificationsV1 from './header/Notifications';
import Search from './header/Search';
import VideoHelp from './header/VideoHelp';
import Wizard from './header/Wizard';

function SearchNotification() {
  const { pathname } = useLocation();
  const [NotificationsComponent, setNotificationsComponent] = useState(null);

  useEffect(() => {
    if (process.env.ENABLE_BELL_NOTIFICATIONS === 'true') {
      // eslint-disable-next-line import/no-unresolved
      import('@react-ib-mf/notifications-ui').then(({ Notifications }) => {
        setNotificationsComponent(<Notifications />);
      });
    } else {
      setNotificationsComponent(<NotificationsV1 />);
    }
  }, []);

  return (
    <Wrap className='sb_wrap'>
      <Search />
      <VideoHelp />
      <Wizard disabled={pathname !== '/'} />
      {NotificationsComponent}
      <Help />
    </Wrap>
  );
}

export default SearchNotification;

const Wrap = styled.div`
  display: flex;
  gap: 13px;
  background-color: transparent;
  border-radius: 8px;
  height: max-content;
`;
