import { useEffect, useState } from 'react';
import { useIdleTimer as idleTimer } from 'react-idle-timer';

const TIME_MULTIPLIER = 1000;

const TIMEOUT = 14 * TIME_MULTIPLIER * 60;
const PROMPT_TIMEOUT = 1 * TIME_MULTIPLIER * 60;

const useIdleTimer = (onIdle: () => void) => {
  const [prompted, setPrompted] = useState(false);

  const onPrompt = () => {
    setPrompted(true);
  };

  const closePrompt = () => {
    setPrompted(false);
    activate();
  };

  useEffect(() => {
    let interval;
    if (prompted) {
      interval = setInterval(() => {
        const remaining = Math.ceil(getRemainingTime() / TIME_MULTIPLIER);

        // Close prompt to allow multiple tabs
        if (remaining > PROMPT_TIMEOUT / TIME_MULTIPLIER) {
          setPrompted(false);
        }

        setRemainingTime(remaining);
      }, 1000);
    }
    return () => interval && clearInterval(interval);
    // we don't know if the function getRemainingTime change the reference.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompted]);

  const { activate, getRemainingTime, ...rest } = idleTimer({
    onIdle,
    onPrompt,
    timeout: TIMEOUT,
    promptTimeout: PROMPT_TIMEOUT,
    events: ['keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove'],
    debounce: 500,
    eventsThrottle: 200,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 1,
    leaderElection: false,
  });
  const [remainingTime, setRemainingTime] = useState(PROMPT_TIMEOUT / TIME_MULTIPLIER);

  return {
    prompted,
    remainingTime,
    closePrompt,
    activate,
    ...rest,
  };
};

export default useIdleTimer;
