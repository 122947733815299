/**
 * Utility used to keep the Sib session alive when using P2
 */

import { getCookie, removeCookie, setCookie } from './cookies';

export const LAST_ACTIVITY_COOKIE = 'activity'; // Cookie name to store the last activity
export const SIB_MAX_IDLE_TIME = 14; // Time before show sib timeout warning popup
export const SIB_LOGIN_COOKIE = 'sibLogin'; // Cookie name to store a flag if there is a sib session

export const storeLastActivity = () => {
  setCookie(LAST_ACTIVITY_COOKIE, new Date().getTime().toString());
};

export const cleanLastActivity = () => {
  removeCookie(LAST_ACTIVITY_COOKIE);
};

export const removeSibSessionFlag = () => {
  removeCookie(SIB_LOGIN_COOKIE);
};

export const hasSibSession = () => !!getCookie(SIB_LOGIN_COOKIE);

export const getDifferenceInMinutes = (time: number): number => {
  const now = new Date().getTime();
  return (now - time) / 60000;
};

export const isSibActive = () => {
  const lastSibActivity = getCookie(LAST_ACTIVITY_COOKIE);
  if (lastSibActivity && !Number.isNaN(lastSibActivity)) {
    const difference = getDifferenceInMinutes(+lastSibActivity);
    return Math.round(difference) < SIB_MAX_IDLE_TIME;
  }
  return false;
};

export const keepSibAlive = () => {
  fetch('https://qasib.interbanking.com.ar/homepage.do?dispatch=keepAlive', { mode: 'no-cors' });
};
