import styled from 'styled-components';

import { FONT_CHANGE_ENTITY_LINK, FONT_CHANGE_ENTITY_TEXT } from '../../../designTokens/fonts';

const StyledChangeEntity = styled.div`
  h3 {
    font-size: ${FONT_CHANGE_ENTITY_TEXT.fontSize};
    font-weight: ${FONT_CHANGE_ENTITY_TEXT.fontWeight};
    line-height: ${FONT_CHANGE_ENTITY_TEXT.lineHeight};
    color: var(--chakra-colors-tertiary-100);
    margin: 8px;
  }
  ul {
    padding: 0;
  }
  li {
    padding-left: 15px;
    padding-right: 10px;
    list-style-type: none;
  }
  p {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .link {
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: var(--chakra-colors-tertiary-30);
    }
    &.selected {
      cursor: default;
      color: var(--chakra-colors-primary-100);
      font-weight: ${FONT_CHANGE_ENTITY_LINK.fontWeight};
    }
  }
  .entityList {
    margin-right: 10px;
    margin-left: 10px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--chakra-colors-secondary-50);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--chakra-colors-secondary-100);
      border-radius: 5px;
    }
  }
`;

export default StyledChangeEntity;
