/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Button, Icon, Text, Tooltip } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink, pushAnalyticsEvent, useHasNewPlan } from '@react-ib-mf/style-guide-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import briefcaseIcon from '../../../assets/images/icons/Briefcase.svg';
import consuInterIcon from '../../../assets/images/icons/consum.svg';
import logOutIcon from '../../../assets/images/icons/Log-out.svg';
import planInterIcon from '../../../assets/images/icons/plan2.svg';
import settingIcon from '../../../assets/images/icons/Settings.svg';
import userIcon from '../../../assets/images/icons/User.svg';
import { ProfileServiceCall } from '../../../Interfaces/IDropdownProfile';
import plainText from '../../../lang/es.json';
import { patchLastLoginDate } from '../../../services/api';
import { getCookie, setCookie } from '../../../utils/cookies';
import { getCurrentDate } from '../../../utils/dates';
import { getAvatarName } from '../../../utils/names';
import getFormattedRole from '../../../utils/roles';
import { MoreOptions } from './StyledUserInfo';

interface UserInfoProps {
  data: ProfileServiceCall;
  toggleDropdownProfile: () => void;
  showChangeEntity: () => void;
  openProfileModal: () => void;
  openLogoutModal: () => void;
  showChangeCompany: boolean;
}

function UserInfo({ data, toggleDropdownProfile, showChangeEntity, openProfileModal, openLogoutModal, showChangeCompany }: UserInfoProps) {
  const toggleDropDownProfileWithanalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Menu header',
      item_id: 'Desplegar perfil de operador',
    });

    toggleDropdownProfile();
  };

  const openProfileModalwithAnalitycs = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Menu de operador',
      item_id: 'Perfil',
    });

    toggleDropdownProfile();
    navigate('/perfil');
  };

  const showChangeEntitywithAnalitycs = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Menu de operador',
      item_id: 'Cambiar de empresa',
    });

    toggleDropdownProfile();
    showChangeEntity();
  };

  const openLogoutModalWithAnalitycs = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Menu de operador',
      item_id: 'Cerrar sesión',
    });

    toggleDropdownProfile();
    openLogoutModal();
  };

  const navigate = useNavigate();

  const navigateConsumption = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Menu de operador',
      item_id: 'Mi consumo',
    });

    toggleDropdownProfile();
    navigate('consumo');
  };

  const contractedPlanURL = 'menuRoot%3Dadmin.abm%26menuSelection%3Dadmin%7Cabm%7CadmOfferingContratado';
  const dataPlanCompany = 'menuRoot%3Dadmin.abm%26menuSelection%3Dadmin%7Cabm%7CadmClienteGeneral';

  // User has new plan
  const [hasNewPlan] = useHasNewPlan();

  // Last login check
  const { refetch: lastLoginRefetch } = useQuery(
    'last-login',
    () => patchLastLoginDate({ lastSuccessLoginDate: getCurrentDate() }).then(res => res?.data),
    { enabled: false },
  );
  const [lastLogin, setLastLogin] = useState(plainText.message.unavailableInfo);

  const LAST_LOGIN_COOKIE_NAME = 'lastLogin';
  const LAST_LOGIN_COOKIE_MIN_LENGHT = 12;
  const lastLoginCookie = getCookie(LAST_LOGIN_COOKIE_NAME);

  const lastLoginRefetchCallback = useCallback(() => {
    lastLoginRefetch({ throwOnError: true })
      .then(response => {
        setLastLogin(encodeURIComponent(response?.data?.data?.secondLastSuccessLoginDate));
        setCookie(LAST_LOGIN_COOKIE_NAME, encodeURIComponent(response?.data?.data?.secondLastSuccessLoginDate));
      })
      .catch(() => {
        setLastLogin(plainText.message.unavailableInfo);
      });
  }, [lastLoginRefetch]);

  useEffect(() => {
    const hasLastLoginCookie = !!lastLoginCookie && lastLoginCookie.length > LAST_LOGIN_COOKIE_MIN_LENGHT;

    if (hasLastLoginCookie) {
      // If the cookie exists then set the value to lastLogin
      setLastLogin(lastLoginCookie);
    } else {
      // If the cookie does not exist then call API to create a new one and set the value to lastLogin
      lastLoginRefetchCallback();
    }
  }, [lastLoginCookie, lastLoginRefetchCallback]);

  const companyRef = useRef(null);
  const nameRef = useRef(null);
  const [isCompanyMaxLength, setIsCompanyMaxLength] = useState(false);
  const isNameMaxLength = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;

  useEffect(() => {
    const checkIfTruncated = () => {
      const element = companyRef.current;
      if (!element) return;

      const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
      const maxLines = 2;
      const maxHeight = lineHeight * maxLines;

      if (element.scrollHeight > maxHeight) {
        setIsCompanyMaxLength(true);
      } else {
        setIsCompanyMaxLength(false);
      }
    };

    checkIfTruncated();

    // Agregar un listener en caso de que el resize del navegador pueda afectar
    window.addEventListener('resize', checkIfTruncated);
    return () => window.removeEventListener('resize', checkIfTruncated);
  }, [data]); // Dependencias para reactivar el efecto si `data` cambia.

  return (
    <>
      <div data-testid='btn-toggleDropdownProfile' className='head_area' role='presentation' onClick={toggleDropDownProfileWithanalytics}>
        <Avatar name={getAvatarName(data?.data?.firstName, data?.data?.lastName)} className='profile_avatar' />
        <div className='name'>
          {(!isNameMaxLength || !isCompanyMaxLength) && (
            <>
              <Tooltip hasArrow label={data?.data?.fullName} isDisabled={!isNameMaxLength}>
                <Text textStyle='titleSmBold' ref={nameRef}>
                  {data?.data?.fullName}
                </Text>
              </Tooltip>
              <Tooltip hasArrow label={data?.data?.company} isDisabled={!isCompanyMaxLength}>
                <Text textStyle='titleSm' ref={companyRef} id='companyText'>
                  {data?.data?.company}
                </Text>
              </Tooltip>
            </>
          )}

          {isNameMaxLength && isCompanyMaxLength && (
            <Tooltip
              isDisabled={false}
              hasArrow
              label={
                <div>
                  {data?.data?.fullName}
                  <br />
                  {data?.data?.company}
                </div>
              }
            >
              <div>
                <strong ref={nameRef}>{data?.data?.fullName}</strong>
                <small ref={companyRef} id='companyText'>
                  {data?.data?.company}
                </small>
              </div>
            </Tooltip>
          )}
        </div>
        <Button className='opt-btn more-options'>
          <Icon as={FiChevronDown} className='icon' w={6} h={6} />
        </Button>
      </div>
      <MoreOptions className='dropdown'>
        <Text textStyle='labelXs' className='last_login'>
          {plainText.message.lastAccess} {decodeURIComponent(lastLogin) || plainText.message.unavailableInfo}
        </Text>
        <div className='profile_type'>
          <Button data-testid='btn-openProfile' onClick={openProfileModalwithAnalitycs}>
            <div className='profile_name'>
              <i className='icon'>
                <img src={userIcon} alt='Perfil' />
              </i>
              <Text textStyle='titleMd'>{plainText.label.profile}</Text>
            </div>
            <div className='profile_mode'>
              <span className='admin'>
                <Text textStyle='bodySm'>{getFormattedRole(data?.data?.options[1]?.description)}</Text>
              </span>
            </div>
          </Button>
        </div>

        <ul className='profile_menu'>
          {data?.data?.hasCompanyDataPermission && (
            <li>
              <OpenLink url={dataPlanCompany} openMode='urlComplete' reuseTab>
                <Button onClick={toggleDropdownProfile}>
                  <i className='icon'>
                    <img src={settingIcon} alt='Datos de empresa' />
                  </i>
                  <span>{plainText.label.settings}</span>
                </Button>
              </OpenLink>
            </li>
          )}

          <li>
            <OpenLink url={contractedPlanURL} openMode='urlComplete' reuseTab>
              <Button onClick={toggleDropdownProfile}>
                <i className='icon'>
                  <img src={planInterIcon} alt='Plan contratado' />
                </i>
                <span>{plainText.label.myPlan}</span>
              </Button>
            </OpenLink>
          </li>

          {hasNewPlan && (
            <li>
              <Button onClick={navigateConsumption}>
                <i className='icon'>
                  <img src={consuInterIcon} alt='Consumo' />
                </i>
                <span>{plainText.label.myConsumption}</span>
              </Button>
            </li>
          )}

          {showChangeCompany && (
            <li className='with_submenu'>
              <Button data-testid='btn-changeCompany' onClick={showChangeEntitywithAnalitycs}>
                <i className='icon'>
                  <img src={briefcaseIcon} alt='Cambiar de empresa' />
                </i>
                <span>{plainText.button.changeEntity}</span>
              </Button>
            </li>
          )}

          <li>
            <Button data-testid='btn-logout' onClick={openLogoutModalWithAnalitycs}>
              <i className='icon'>
                <img src={logOutIcon} alt='Cerrar sesión' />
              </i>
              <span>{plainText.button.logout}</span>
            </Button>
          </li>
        </ul>
      </MoreOptions>
    </>
  );
}

export default UserInfo;
