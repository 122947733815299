/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';

interface UseAivoArgs {
  src: string;
  token: string;
  user?: AivoUser;
  onClose?: () => void;
  onError?: (error: Event) => void;
  styles?: string;
}

interface AivoUser {
  name: string;
  lastname: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $aivo: any;

// eslint-disable-next-line no-console
const useAivo = ({ src, token, user, onClose, onError = console.error, styles }: UseAivoArgs) => {
  const [aivoInstance, setAivoInstance] = useState(null);

  const putStyles = customStyles => {
    const element = document.querySelector('#AgentAppContainer');

    if (element) {
      const foundElement = element.shadowRoot.getElementById('customAivoStyles');
      const style = foundElement || document.createElement('style');
      style.id = 'customAivoStyles';
      style.innerHTML = customStyles;
      !foundElement && element.shadowRoot.appendChild(style);
    }
  };

  useEffect(() => {
    const $head = document.getElementsByTagName('head')[0];
    const initialize = () => {
      function onLoadScript() {
        $aivo.run(token);
        $aivo.shadowRoot(false);
        $aivo.ready(() => {
          try {
            if ($aivo?.user) {
              user && $aivo.user?.set(user);
              onClose && $aivo?.subscribe?.onCloseWindow(onClose);
              onClose && $aivo?.subscribe?.onMinimizeWindow(onClose);
              styles && putStyles(styles);
              setAivoInstance($aivo);
            } else {
              console.warn('[aivo] $aivo.user no está'); // eslint-disable-line no-console
            }
          } catch (error) {
            console.error('[aivo] Error al establecer parámetros de usuario:', error); // eslint-disable-line no-console
          }
        });
      }

      const scriptAivo = document.createElement('script');
      scriptAivo.type = 'text/javascript';
      scriptAivo.src = src;
      scriptAivo.onload = onLoadScript;
      scriptAivo.onerror = onError;
      scriptAivo.classList.add('agent-launcher');
      scriptAivo.defer = true;

      $head.appendChild(scriptAivo);
    };
    try {
      initialize();
    } catch (aivoError) {
      onError(aivoError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, user]);

  return { aivo: aivoInstance, updateStyles: putStyles };
};

export default useAivo;
