import styled from 'styled-components';

import imageHeaderBg from '../../assets/images/header/bgValidation.png';
import { FONT_SIMPLE_BANNER_AVATAR, FONT_SIMPLE_BANNER_ENTITY_NAME, FONT_SIMPLE_BANNER_NAME } from '../../designTokens/fonts';

const StyledSimpleBanner = styled.div`
  .simple-banner {
    background-image: url(${imageHeaderBg});
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 116px;
    display: flex;
    align-items: center;

    &__logo {
      width: 75px;
      height: 54px;
      margin-left: 90px;
      margin-right: auto;
    }

    &__profile {
      margin-left: auto;
      margin-right: 118px;
      display: flex;

      &__avatar {
        margin-right: 12px;

        & .chakra-avatar {
          width: 40px;
          height: 40px;
          border-color: transparent;
          background-color: var(--chakra-colors-primary-100);
          color: white;

          &__initials {
            font-size: ${FONT_SIMPLE_BANNER_AVATAR.fontSize};
            font-family: ${FONT_SIMPLE_BANNER_AVATAR.fontFamily};
            font-weight: ${FONT_SIMPLE_BANNER_AVATAR.fontWeight};
          }
        }
      }

      &__data {
        display: flex;
        justify-content: center;
        flex-direction: column;

        &__name {
          font-style: ${FONT_SIMPLE_BANNER_NAME.fontStyle};
          font-family: ${FONT_SIMPLE_BANNER_NAME.fontFamily};
          font-size: ${FONT_SIMPLE_BANNER_NAME.fontSize};
          font-weight: ${FONT_SIMPLE_BANNER_NAME.fontWeight};
          line-height: ${FONT_SIMPLE_BANNER_NAME.lineHeight};
          letter-spacing: ${FONT_SIMPLE_BANNER_NAME.letterSpacing};
          display: flex;
          align-items: center;
          color: var(--chakra-colors-grey-120);
        }

        &__entity-name {
          font-style: ${FONT_SIMPLE_BANNER_ENTITY_NAME.fontStyle};
          font-family: ${FONT_SIMPLE_BANNER_ENTITY_NAME.fontFamily};
          font-size: ${FONT_SIMPLE_BANNER_ENTITY_NAME.fontSize};
          font-weight: ${FONT_SIMPLE_BANNER_ENTITY_NAME.fontWeight};
          line-height: ${FONT_SIMPLE_BANNER_ENTITY_NAME.lineHeight};
          letter-spacing: ${FONT_SIMPLE_BANNER_ENTITY_NAME.letterSpacing};
          display: flex;
          align-items: center;
          color: var(--chakra-colors-grey-120);
        }
      }
    }
  }
`;

export default StyledSimpleBanner;
