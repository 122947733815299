import styled from 'styled-components';

import { FONT_HEADER_BASE_BREADCRUMB, FONT_HEADER_BASE_DESCRIPTION, FONT_HEADER_BASE_TITLE } from '../../../../designTokens/fonts';

const StyledHeaderBase = styled.div`
  .chakra-breadcrumb__list {
    padding-left: 0px;
    margin-top: 0px;
  }
  .breadcrumb {
    font-style: ${FONT_HEADER_BASE_BREADCRUMB.fontStyle};
    font-family: ${FONT_HEADER_BASE_BREADCRUMB.fontFamily};
    font-size: ${FONT_HEADER_BASE_BREADCRUMB.fontSize};
    font-weight: ${FONT_HEADER_BASE_BREADCRUMB.fontWeight};
    letter-spacing: ${FONT_HEADER_BASE_BREADCRUMB.letterSpacing};
    align-items: center;
    // color: var(--chakra-colors-tertiary-100);
    color: #5b31b2;
  }
  .itemInactive a {
    // color: var(--chakra-colors-secondary-100);
    color: #565e71;
  }
  .title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: ${FONT_HEADER_BASE_TITLE.fontStyle};
    font-family: ${FONT_HEADER_BASE_TITLE.fontFamily};
    font-size: ${FONT_HEADER_BASE_TITLE.fontSize};
    font-weight: ${FONT_HEADER_BASE_TITLE.fontWeight};
    line-height: ${FONT_HEADER_BASE_TITLE.lineHeight};
    display: flex;
    align-items: center;
    color: var(--chakra-colors-grey-120);
  }
  .description {
    margin-top: 12px;
    margin-bottom: 0px;
    font-style: ${FONT_HEADER_BASE_DESCRIPTION.fontStyle};
    font-family: ${FONT_HEADER_BASE_DESCRIPTION.fontFamily};
    font-size: ${FONT_HEADER_BASE_DESCRIPTION.fontSize};
    font-weight: ${FONT_HEADER_BASE_DESCRIPTION.fontWeight};
    line-height: ${FONT_HEADER_BASE_DESCRIPTION.lineHeight};
    align-items: center;
    color: var(--chakra-colors-grey-120);
  }
  .icon {
    margin-left: 10px;
    color: var(--chakra-colors-primary-90) !important;
  }
  .footer {
    padding-bottom: 20px;
  }
`;
export default StyledHeaderBase;
