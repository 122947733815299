import { Icon, Tooltip } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@react-ib-mf/style-guide-ui';
import { FiHelpCircle } from 'react-icons/fi';
import styled from 'styled-components';

import message from '../../lang/es.json';

function Help() {
  return (
    <OpenLink url='/preguntas-frecuentes' openMode='component'>
      <Tooltip hasArrow label={message.helpButton.tooltipText}>
        <StyledHelperButton className='help' id='help'>
          <Icon as={FiHelpCircle} w={7} h={7} className='custom-icon-help' />
        </StyledHelperButton>
      </Tooltip>
    </OpenLink>
  );
}
export default Help;

const StyledHelperButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: 0;
  position: relative;
  padding: 0;
  cursor: pointer;

  svg {
    // color: var(--chakra-colors-primary-100);
    color: #2172dc;
  }
`;
