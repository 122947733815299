import 'react-alice-carousel/lib/alice-carousel.css';

import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useLocation } from 'react-router-dom';

import WelcomePopupPicture1 from '../../assets/images/welcome-carrusel-step-1.png';
import plainText from '../../lang/es.json';
import { showWizard } from '../../utils/wizard';
import {
  StyledActionButtonContainer,
  StyledCarouselContainer,
  StyledCarouselContentBody,
  StyledCarouselContentPicture,
  StyledCarouselContentSubTitle,
  StyledCarouselContentTitle,
  StyledModalCloseButtonContainer,
} from './styled';

function WelcomePopup() {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  // Commented code if it's required in the future
  // const [activeIndex, setActiveIndex] = useState(0);
  // const slideNext = () => {
  //   // Find the elements to apply transition/animation
  //   const element = document.getElementById(`to-animate-${activeIndex}`);
  //   element.classList.add('fade');

  const { pathname } = useLocation();

  const showPopup = localStorage.getItem('welcomePopupCompleted') == null && pathname === '/';

  type ButtonName = 'Ver mas tarde' | 'Continuar' | 'Cerrar';
  const pushAnalyticsOnClick = (buttonName: ButtonName) =>
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Modal de bienvenida Home',
      item_id: buttonName,
    });

  useEffect(() => {
    if (showPopup) {
      pushAnalyticsEvent({
        event: 'impression_modal',
        content_group: 'Home',
        modal_name: 'Modal de bienvenida Home',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const auxOnClose = (buttonName: ButtonName) => () => {
    localStorage.setItem('welcomePopupCompleted', 'true');
    pushAnalyticsOnClick(buttonName);
    onClose();
  };

  const handleStartWizard = () => {
    auxOnClose('Continuar')();
    showWizard();
  };

  const welcomePopupContent = [
    {
      id: 1,
      picture: WelcomePopupPicture1,
      title: plainText.message.welcomePopupTitle,
      subtitle: plainText.message.welcomePopupSubtitle,
      body: plainText.message.welcomePopupBody,
      controls: [
        {
          id: 1,
          buttonLabel: plainText.button.watchLater,
          onClick: auxOnClose('Ver mas tarde'),
          variant: 'primary-outline',
          dataTestId: 'btn-watchLater',
        },
        {
          id: 2,
          buttonLabel: plainText.button.continue,
          onClick: handleStartWizard,
          variant: 'primary',
          dataTestId: 'btn-continue',
        },
      ],
    },
  ];

  // Commented code if it's required in the future
  // const carouselDots: React.ReactElement[] = [];
  // for (let i = 0; i < welcomePopupContent.length; i += 1) {
  //   carouselDots.push(<StyledCarouselDot key={i} className={i === activeIndex ? 'active-dot' : 'inactive-dot'} />);
  // }

  const carouselContent = welcomePopupContent.map((content, index) => {
    return (
      <div key={content.id}>
        <div id={`to-animate-${index}`}>
          <StyledCarouselContentPicture>
            <img src={Object.keys(content.picture).length !== 0 ? content.picture : null} alt='' />{' '}
          </StyledCarouselContentPicture>
          <StyledCarouselContentTitle> {content.title} </StyledCarouselContentTitle>
          <StyledCarouselContentSubTitle> {content.subtitle} </StyledCarouselContentSubTitle>
          <StyledCarouselContentBody> {content.body} </StyledCarouselContentBody>
        </div>

        {/* Commented code if it's required in the future */}
        {/* <StyledCarouselDotContainer> {carouselDots} </StyledCarouselDotContainer> */}

        <StyledActionButtonContainer>
          {content.controls.map(control => {
            return (
              <Button key={control.id} data-testId={control.dataTestId} onClick={control.onClick} variant={control.variant}>
                {control.buttonLabel}
              </Button>
            );
          })}
        </StyledActionButtonContainer>
      </div>
    );
  });

  if (showPopup) {
    return (
      <Modal onClose={auxOnClose('Cerrar')} isOpen={isOpen} isCentered preserveScrollBarGap autoFocus={false}>
        <ModalOverlay />

        <ModalContent maxW='710px' h='570px' borderRadius='16px'>
          <StyledModalCloseButtonContainer>
            <ModalCloseButton aria-label='welcome-popup-exit-button' />
          </StyledModalCloseButtonContainer>

          <ModalBody padding='44px 30px'>
            <StyledCarouselContainer>
              <AliceCarousel items={carouselContent} disableDotsControls disableButtonsControls />
            </StyledCarouselContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  return null;
}

export default WelcomePopup;
