// eslint-disable-next-line import/no-unresolved
import { PDFReader } from '@react-ib-mf/style-guide-ui';
import { memo } from 'react';

interface PDFViewerProps {
  document: number[] | string;
  onFinishRead?: () => void;
}

const PDFViewer = memo(({ document, onFinishRead }: PDFViewerProps) => {
  return <PDFReader file={document} onFinishRead={onFinishRead} />;
});

export default PDFViewer;
