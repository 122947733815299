import styled from 'styled-components';

import {
  FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION,
  FONT_VINCULATION_VIEW_BLOCKED_TITLE,
  FONT_VINCULATION_VIEW_CANCEL,
  FONT_VINCULATION_VIEW_DESCRIPTION,
  FONT_VINCULATION_VIEW_TITLE,
  FONT_VINCULATION_VIEW_WARNING,
} from '../../designTokens/fonts';

const StyledVinculationView = styled.div`
  .vinculation-view {
    &__content {
      &__form {
        display: flex;
        flex-flow: column;
        width: max-content;
        margin-top: 20px;
        margin-left: 86px;

        &__title {
          font-style: ${FONT_VINCULATION_VIEW_TITLE.fontStyle};
          font-family: ${FONT_VINCULATION_VIEW_TITLE.fontFamily};
          font-size: ${FONT_VINCULATION_VIEW_TITLE.fontSize};
          font-weight: ${FONT_VINCULATION_VIEW_TITLE.fontWeight};
          line-height: ${FONT_VINCULATION_VIEW_TITLE.lineHeight};
          color: var(--chakra-colors-grey-120);
          margin-bottom: 12px;
        }
        &__description {
          font-style: ${FONT_VINCULATION_VIEW_DESCRIPTION.fontStyle};
          font-family: ${FONT_VINCULATION_VIEW_DESCRIPTION.fontFamily};
          font-size: ${FONT_VINCULATION_VIEW_DESCRIPTION.fontSize};
          font-weight: ${FONT_VINCULATION_VIEW_DESCRIPTION.fontWeight};
          line-height: ${FONT_VINCULATION_VIEW_DESCRIPTION.lineHeight};
          letter-spacing: ${FONT_VINCULATION_VIEW_DESCRIPTION.letterSpacing};
          color: var(--chakra-colors-grey-120);
          width: 416px;
          margin-bottom: 40px;
        }

        &__input {
          width: 320px;
          height: 48px;

          & .chakra-input__right-element {
            cursor: pointer;
          }

          &__icon {
            & svg {
              width: 20px;
              height: 20px;
            }

            & .input-incorrect {
              color: var(--chakra-colors-error-100);
            }
          }
        }

        &__password-warning {
          font-style: ${FONT_VINCULATION_VIEW_WARNING.fontStyle};
          font-family: ${FONT_VINCULATION_VIEW_WARNING.fontFamily};
          font-size: ${FONT_VINCULATION_VIEW_WARNING.fontSize};
          font-weight: ${FONT_VINCULATION_VIEW_WARNING.fontWeight};
          line-height: ${FONT_VINCULATION_VIEW_WARNING.lineHeight};
          letter-spacing: ${FONT_VINCULATION_VIEW_WARNING.letterSpacing};
          height: 15px;
          color: var(--chakra-colors-error-100);
          margin-bottom: 25px;
          width: 320px;
        }

        &__action {
          display: flex;
          align-items: center;
          justify-content: right;
          width: 320px;
          height: 48px;

          &__cancel {
            font-style: ${FONT_VINCULATION_VIEW_CANCEL.fontStyle};
            font-family: ${FONT_VINCULATION_VIEW_CANCEL.fontFamily};
            font-size: ${FONT_VINCULATION_VIEW_CANCEL.fontSize};
            font-weight: ${FONT_VINCULATION_VIEW_CANCEL.fontWeight};
            line-height: ${FONT_VINCULATION_VIEW_CANCEL.lineHeight};
            letter-spacing: ${FONT_VINCULATION_VIEW_CANCEL.letterSpacing};
            text-align: center;
            text-decoration-line: underline;
            color: var(--chakra-colors-primary-100);
            margin-right: 26px;

            &:hover {
              cursor: pointer;
              color: var(--chakra-colors-primary-90);
            }
          }

          &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            height: 40px;
            text-align: center;
          }
        }
      }

      &__blocked-key {
        display: flex;
        align-items: center;
        flex-flow: column;
        margin-top: 95px;

        &__picture {
          width: 80px;
          height: 95px;
          margin-bottom: 11px;
        }
        &__title {
          font-style: ${FONT_VINCULATION_VIEW_BLOCKED_TITLE.fontStyle};
          font-family: ${FONT_VINCULATION_VIEW_BLOCKED_TITLE.fontFamily};
          font-size: ${FONT_VINCULATION_VIEW_BLOCKED_TITLE.fontSize};
          font-weight: ${FONT_VINCULATION_VIEW_BLOCKED_TITLE.fontWeight};
          line-height: ${FONT_VINCULATION_VIEW_BLOCKED_TITLE.lineHeight};
          text-align: center;
          color: var(--chakra-colors-grey-120);
          margin-bottom: 16px;
        }
        &__description {
          font-style: ${FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION.fontStyle};
          font-family: ${FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION.fontFamily};
          font-size: ${FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION.fontSize};
          font-weight: ${FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION.fontWeight};
          line-height: ${FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION.lineHeight};
          letter-spacing: ${FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION.letterSpacing};
          text-align: center;
          margin-bottom: 46px;
        }
      }
    }
  }

  .chakra-spinner {
    margin-right: 8px;
  }
`;

export default StyledVinculationView;
