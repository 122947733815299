import { themeChakra } from '@wow/themes';

// OBJETIVO: Centralizar los estilos de cada componente en un único archivo para poder cambiarlos rápidamente
// sin tener que navegar por el componente. Esto usando la tipografia disponible del theme de wow, Libreria de DS.
// http://devdesignsystem-ui.apps.dev.ib.ar/?path=/docs/design-tokens-typography--page

// SIDE BAR
export const FONT_SIDE_BAR_BTN = {
  fontSize: themeChakra.textStyles.labelLgBold.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.labelLgBold.lineHeight,
};

export const FONT_SIDE_BAR_ITEM = {
  fontSize: themeChakra.textStyles.titleSm.fontSize,
  fontWeight: themeChakra.textStyles.titleSm.fontWeight,
  lineHeight: themeChakra.textStyles.titleSm.lineHeight,
};

export const FONT_SIDE_BAR_ITEM_HOVER = {
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
};

export const FONT_SIDE_BAR_NEW_BADGE = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

// ASIDE
export const FONT_ASIDE_NEW_OPTION = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
};

// CHANGE ENTITY
export const FONT_CHANGE_ENTITY_TEXT = {
  fontSize: themeChakra.textStyles.h6.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_CHANGE_ENTITY_LINK = {
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
};

// PROFILE DETAILS
export const FONT_PROFILE_MODAL_NAVIGATION = {
  fontSize: themeChakra.textStyles.titleXl.fontSize,
  lineHeight: themeChakra.textStyles.h4.lineHeight,
};

export const FONT_PROFILE_MODAL_NAV_LINK = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_PROFILE_MODAL_LINK_BUTTON = {
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
};

export const FONT_PROFILE_MODAL_FORM = {
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
};

export const FONT_PROFILE_MODAL_AVATAR = {
  fontSize: themeChakra.textStyles.displaySm.fontSize,
  fontFamily: themeChakra.textStyles.h5.fontFamily,
  fontWeight: themeChakra.textStyles.h5.fontWeight,
};

// SELECT CARACTERISTICA
export const FONT_SELECT_CAR_CUSTOM_SEL = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_SELECT_CAR_CUSTOM_SEL_CONTENT = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

// SELECT NACIONALIDAD
export const FONT_SELECT_NAL_CUSTOM_SEL = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_SELECT_NAL_CUSTOM_SEL_CONTENT = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

// USER INFO
export const FONT_USER_INFO_AVATAR = {
  fontSize: themeChakra.textStyles.h5.fontSize,
  fontFamily: themeChakra.textStyles.h5.fontFamily,
  fontWeight: themeChakra.textStyles.labelSm.fontWeight,
};

export const FONT_USER_INFO_NAME = {
  fontSize: themeChakra.textStyles.titleMd.fontSize,
};

export const FONT_USER_INFO_MORE_OPTIONS = {
  fontSize: themeChakra.textStyles.h6.fontSize,
};

export const FONT_USER_INFO_PROFILE_NAME = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

export const FONT_USER_INFO_PROFILE_MODE = {
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
};

export const FONT_USER_INFO_BUTTON = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

export const FONT_USER_INFO_ITEM = {
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
};

// LOGOUT CONFIRMATION
export const FONT_LOGOUT_CONFIRM = {
  fontFamily: themeChakra.textStyles.h3.fontFamily,
  fontWeight: themeChakra.textStyles.h3.fontWeight,
  fontSize: themeChakra.textStyles.h3.fontSize,
};

// HEADER NAV
export const FONT_HEADER_NAV_WELCOME_BIG = {
  fontSize: themeChakra.textStyles.h2Bold.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.h4.lineHeight,
};

export const FONT_HEADER_NAV_WELCOME_SMALL = {
  fontStyle: 'normal',
  fontSize: themeChakra.textStyles.titleMdBold.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.h4.lineHeight,
};

// DIRECT LINKS
export const FONT_DIRECT_LINKS_MODAL = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
};

export const FONT_DIRECT_LINKS_TITLE = {
  fontStyle: 'normal',
  fontSize: themeChakra.textStyles.h5.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_DIRECT_LINKS_SHORTCUT = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelMd.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
};

export const FONT_DIRECT_LINKS_SHORTCUT_CATEGORY = {
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
};

export const FONT_DIRECT_LINKS_TEXT = {
  fontStyle: 'normal',
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

// DIRECT LINKS POPUP
export const FONT_DIRECT_LINKS_POPUP = {
  fontSizeTextMinus: themeChakra.textStyles.labelSm.fontSize,
  fontSizeTextPlus: themeChakra.textStyles.labelLg.fontSize,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
};

export const FONT_DIRECT_LINKS_POPUP_NAME = {
  fontStyle: 'normal',
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.bodyMdSemi.fontWeight,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

export const FONT_DIRECT_LINKS_POPUP_MESSAGE_FAV = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
};

export const FONT_DIRECT_LINKS_POPUP_FILTER_TITLE = {
  fontSize: themeChakra.textStyles.h5.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

export const FONT_DIRECT_LINKS_POPUP_FILTER_TEXT = {
  fontSize: themeChakra.textStyles.titleMd.fontSize,
};

export const FONT_DIRECT_LINKS_POPUP_FIELD = {
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
};

export const FONT_DIRECT_LINKS_BOLDER = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
};

// HEADER BASE
export const FONT_HEADER_BASE_BREADCRUMB = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

export const FONT_HEADER_BASE_TITLE = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontSize: themeChakra.textStyles.h2.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.h2.lineHeight,
};

export const FONT_HEADER_BASE_DESCRIPTION = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  lineHeight: themeChakra.textStyles.bodyLg.lineHeight,
};

// IDLE MODAL
export const FONT_IDLE_MODAL_HEADER = {
  fontFamily: themeChakra.textStyles.h3.fontFamily,
  fontWeight: themeChakra.textStyles.h3.fontWeight,
  fontSize: themeChakra.textStyles.h3.fontSize,
};

export const FONT_IDLE_MODAL_BODY = {
  fontFamily: themeChakra.textStyles.bodyMd.fontFamily,
  fontWeight: themeChakra.textStyles.bodyMd.fontWeight,
  fontSize: themeChakra.textStyles.bodyMd.fontSize,
};

// SIMPLE BANNER
export const FONT_SIMPLE_BANNER_AVATAR = {
  fontSize: themeChakra.textStyles.titleMd.fontSize,
  fontFamily: themeChakra.textStyles.h6.fontFamily,
  fontWeight: themeChakra.textStyles.labelSm.fontWeight,
};

export const FONT_SIMPLE_BANNER_NAME = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

export const FONT_SIMPLE_BANNER_ENTITY_NAME = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  lineHeight: themeChakra.textStyles.labelMd.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

// TYC
export const FONT_TYC_DESCRIPTION = {
  fontFamily: `${themeChakra.textStyles.bodyMd.fontFamily}, AdobeBlank`,
  fontSize: themeChakra.textStyles.bodyMd.fontSize,
  fontWeight: themeChakra.textStyles.bodyMd.fontWeight,
};

// TOAST
export const FONT_TOAST = {
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.titleMd.lineHeight,
};

// VINCULATION VIEW
export const FONT_VINCULATION_VIEW_TITLE = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.h3.fontFamily,
  fontSize: themeChakra.textStyles.h3.fontSize,
  fontWeight: themeChakra.textStyles.h3.fontWeight,
  lineHeight: themeChakra.textStyles.h3.lineHeight,
};

export const FONT_VINCULATION_VIEW_DESCRIPTION = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.bodyMd.fontFamily,
  fontSize: themeChakra.textStyles.bodyMd.fontSize,
  fontWeight: themeChakra.textStyles.bodyMd.fontWeight,
  lineHeight: themeChakra.textStyles.bodyMd.lineHeight,
  letterSpacing: themeChakra.textStyles.bodyMd.letterSpacing,
};

export const FONT_VINCULATION_VIEW_WARNING = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelSm.fontSize,
  fontWeight: themeChakra.textStyles.labelSm.fontWeight,
  lineHeight: themeChakra.textStyles.labelSm.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

export const FONT_VINCULATION_VIEW_CANCEL = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

export const FONT_VINCULATION_VIEW_BLOCKED_TITLE = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.h1.fontFamily,
  fontSize: themeChakra.textStyles.h3.fontSize,
  fontWeight: themeChakra.textStyles.labelLgBold.fontWeight,
  lineHeight: themeChakra.textStyles.h3.lineHeight,
};

export const FONT_VINCULATION_VIEW_BLOCKED_DESCRIPTION = {
  fontStyle: 'normal',
  fontFamily: themeChakra.textStyles.labelLg.fontFamily,
  fontSize: themeChakra.textStyles.labelLg.fontSize,
  fontWeight: themeChakra.textStyles.labelLg.fontWeight,
  lineHeight: themeChakra.textStyles.labelLg.lineHeight,
  letterSpacing: themeChakra.textStyles.titleMd.letterSpacing,
};

// WELCOME POPUP
export const FONT_WELCOME_POPUP_CONTAINER = {
  fontStyle: 'normal',
};

export const FONT_WELCOME_POPUP_TITLE = {
  fontFamily: themeChakra.textStyles.h3.fontFamily,
  fontSize: themeChakra.textStyles.h3.fontSize,
  fontWeight: themeChakra.textStyles.h3.fontWeight,
  lineHeight: themeChakra.textStyles.h3.lineHeight,
  letterSpacing: themeChakra.textStyles.h3.letterSpacing,
};

export const FONT_WELCOME_POPUP_SUBTITLE = {
  fontFamily: themeChakra.textStyles.h4.fontFamily,
  fontSize: themeChakra.textStyles.h4.fontSize,
  fontWeight: themeChakra.textStyles.h4.fontWeight,
  lineHeight: themeChakra.textStyles.h4.lineHeight,
  letterSpacing: themeChakra.textStyles.h4.letterSpacing,
};

export const FONT_WELCOME_POPUP_BODY = {
  fontFamily: themeChakra.textStyles.bodyMd.fontFamily,
  fontSize: themeChakra.textStyles.bodyMd.fontSize,
  fontWeight: themeChakra.textStyles.bodyMd.fontWeight,
  lineHeight: themeChakra.textStyles.bodyMd.lineHeight,
  letterSpacing: themeChakra.textStyles.bodyMd.letterSpacing,
};

// WIZARD POPUP
export const FONT_WIZARD_POPUP = {
  fontSizeTitle: themeChakra.textStyles.titleMd.fontSize,
  letterSpacingTitle: themeChakra.textStyles.titleMd.letterSpacing,
  fontSizeDescription: themeChakra.textStyles.bodyMd.fontSize,
  letterSpacingDescription: themeChakra.textStyles.bodyMd.letterSpacing,
};
