import { useState } from 'react';

interface UseToggleOptionArgs<T> {
  defaultValue?: T | null;
  compareFn?: (a: T, b: T) => boolean;
}

const defaultCompareFn = <T,>(a: T, b: T) => a === b;

const useToggleOption = <T,>({ defaultValue = null, compareFn = defaultCompareFn }: UseToggleOptionArgs<T> = {}) => {
  const [option, setOption] = useState<T>(null);
  const toggle = (newValue: T) => {
    setOption(previousValue => (compareFn(previousValue, newValue) ? defaultValue : newValue));
  };

  return { option, toggle };
};

export default useToggleOption;
