/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from 'oidc-client-ts';
import { createContext, useState } from 'react';

import AuthService from '../utils/authService';
import { useAuthorizer } from '../utils/GlobalState';

interface AuthProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

interface AuthContextProps {
  signinRedirectCallback: () => Promise<User>;
  logout: () => void;
  signoutRedirectCallback: () => void;
  isAuthenticated: () => boolean;
  signinRedirect: (args?: any) => Promise<void>;
  signinSilentCallback: () => void;
  createSigninRequest: () => void;
  hasAuthParams: () => boolean;
  getUser: () => Promise<User>;
  querySessionStatus: () => Promise<unknown>;
  signinSilent: () => void;
  removeUser: () => void;
  getUserInfoEndpoint: () => void;
  isAuthenticatedUserEndpoint: () => Promise<boolean>;
  isAuthenticatedIntrospection: () => Promise<boolean>;
  validateSession: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthConsumer = AuthContext.Consumer;

export function AuthProvider({ children }: AuthProviderProps) {
  const [authService] = useState(() => new AuthService());

  const [, setAuthorizer] = useAuthorizer();
  setAuthorizer({ logout: authService.logout, validateSession: authService.validateSession });

  return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>;
}
