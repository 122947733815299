/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios from 'axios';
import { useEffect } from 'react';

const useAxiosErrorHandler = handlers => {
  useEffect(() => {
    const errorInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        const status = error?.response?.status;
        const handler = status && handlers[status];
        const ignoreGlobalCatch = error?.config?.params?.ignoreGlobalCatch;
        handler && !ignoreGlobalCatch && handler();
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.response.eject(errorInterceptor);
    };
  }, [handlers]);
};

export default useAxiosErrorHandler;
