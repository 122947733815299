import { getSibFormId } from '../../utils/sibForms';

interface SibFormField {
  name: string;
  value: string;
}

interface SibFormProps {
  identifier: string;
  fields: SibFormField[];
  method?: string;
}

function SibForm({ identifier, method = 'POST', fields }: SibFormProps) {
  return (
    <form id={getSibFormId(identifier)} hidden style={{ display: 'none' }} method={method} action={process.env.URL_HOME_SIB}>
      {fields.map(field => (
        <input key={field.name} type='hidden' name={field.name} id={field.name} value={field.value} />
      ))}
    </form>
  );
}

export default SibForm;
