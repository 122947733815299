import { Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import Flag from 'react-flagkit';
import { FiChevronDown } from 'react-icons/fi';

import { FONT_SELECT_CAR_CUSTOM_SEL, FONT_SELECT_CAR_CUSTOM_SEL_CONTENT } from '../../../../designTokens/fonts';
import { INacionalidad } from '../ProfileDetails';

interface SelectCaracteristicaProps {
  caracteristica: INacionalidad;
  setCaracteristica: React.Dispatch<React.SetStateAction<INacionalidad>>;
  paises: INacionalidad[];
  defaultOption: INacionalidad;
  disable: boolean;
}

function SelectCaracteristica({ caracteristica, setCaracteristica, defaultOption, paises, disable }: SelectCaracteristicaProps) {
  const [isActive, setIsActive] = useState(false);
  const opcionesCaracteristicas = paises?.slice();
  const handleOpenDrop = () => {
    if (!disable) {
      setIsActive(!isActive);
    }
  };
  return (
    <CustomSelectWrapper>
      <div className={disable ? 'custom-select disabled' : 'custom-select'}>
        <div className='custom-select-btn' role='presentation' onClick={handleOpenDrop}>
          {caracteristica.countryCode.length > 0 ? (
            <>
              <Flag country={caracteristica.countryCode} />
              <p>{caracteristica.characteristic}</p>
            </>
          ) : (
            <>
              <Flag country={defaultOption.countryCode} />
              <p>{defaultOption.characteristic}</p>
            </>
          )}
          {!disable && <Icon as={FiChevronDown} className='icon' w={3} h={3} />}
        </div>
        {isActive && (
          <div className='custom-select-content'>
            {opcionesCaracteristicas &&
              opcionesCaracteristicas.map(item => (
                <div
                  className='custom-select-item'
                  role='presentation'
                  key={item?.characteristic}
                  id={item?.characteristic}
                  onClick={() => {
                    setCaracteristica(item);
                    setIsActive(false);
                  }}
                >
                  <Flag country={item?.countryCode} />
                  <p>+{item?.characteristic}</p>
                </div>
              ))}
          </div>
        )}
      </div>
    </CustomSelectWrapper>
  );
}

export default SelectCaracteristica;

const CustomSelectWrapper = styled.div`
  width: 98px;
  height: 32px;
  border-right: none;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  position: relative;
  .custom-select {
    height: 100%;
    .custom-select-btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      p,
      img,
      svg {
        margin: 0;
      }
      p {
        font-size: ${FONT_SELECT_CAR_CUSTOM_SEL.fontSize};
        color: var(--chakra-colors-tertiary-100);
      }
    }
    .custom-select-content {
      position: absolute;
      top: 110%;
      padding: 0 6px;
      border: 1px solid var(--chakra-colors-secondary-60);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 99px;
      background-color: white;
      z-index: 1;
      .custom-select-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        transition: all 0.2s;
        img,
        svg {
          margin: 0;
        }
        p {
          margin: 0 0 0 14px;
          font-size: ${FONT_SELECT_CAR_CUSTOM_SEL_CONTENT.fontSize};
          color: var(--chakra-colors-tertiary-100);
        }
      }
    }
  }
  .disabled {
    opacity: 1;
    cursor: not-allowed;
    background: #f2f0f4;
    border: 1px solid #c7c6ca;
    color: #c7c6ca;
    border-radius: 5px 0 0 5px;
  }
`;
