import adminIcon from '../assets/images/icons/administracion.svg';
import archivoIcon from '../assets/images/icons/archivos.svg';
import consultIcon from '../assets/images/icons/consultas.svg';
import documentosIcon from '../assets/images/icons/documentos.svg';
import facturasIcon from '../assets/images/icons/facturas.svg';
import inversionesIcon from '../assets/images/icons/inversiones.svg';
import mensajesIcon from '../assets/images/icons/mensajes.svg';
import otrosIcon from '../assets/images/icons/otros-servicios.svg';
import pagosIcon from '../assets/images/icons/pago-servicios.svg';
import pendingsIcon from '../assets/images/icons/pendientes.svg';
import reportIcon from '../assets/images/icons/reportes.svg';
import transferIcon from '../assets/images/icons/transferencias.svg';

const getShortcutIcon = category => {
  const iconSrc = {
    Consultas: consultIcon,
    Transferencias: transferIcon,
    Reportes: reportIcon,
    Archivos: archivoIcon,
    Administración: adminIcon,
    Documentos: documentosIcon,
    Mensajes: mensajesIcon,
    Facturas: facturasIcon,
    Pagos: pagosIcon,
    Inversiones: inversionesIcon,
    Pendientes: pendingsIcon,
  };

  return iconSrc[category] || otrosIcon;
};

export default getShortcutIcon;
