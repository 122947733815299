import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import React, { useEffect, useRef } from 'react';

import { FONT_IDLE_MODAL_BODY, FONT_IDLE_MODAL_HEADER } from '../../designTokens/fonts';
import plainText from '../../lang/es.json';

interface IdleModalProps {
  isOpen: boolean;
  onClose: () => void;
  remainingTime: number;
}

function IdleModal({ isOpen, onClose, remainingTime }: IdleModalProps) {
  const cancelRef = useRef();
  const pushAnalyticsAcceptSesion = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Sesion por expirar',
      item_id: 'CTA - Aceptar',
    });
    onClose();
  };
  useEffect(() => {
    if (isOpen) {
      pushAnalyticsEvent({
        event: 'impression_modal',
        content_group: 'Home',
        modal_name: 'Sesion por expirar',
      });
    }
  }, [isOpen]);
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={pushAnalyticsAcceptSesion}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            data-testid='sesion'
            fontFamily={FONT_IDLE_MODAL_HEADER.fontFamily}
            fontSize={FONT_IDLE_MODAL_HEADER.fontSize}
            fontWeight={FONT_IDLE_MODAL_HEADER.fontWeight}
          >
            {plainText.message.sessionToExpire}
          </AlertDialogHeader>
          <AlertDialogBody
            fontFamily={FONT_IDLE_MODAL_BODY.fontFamily}
            fontSize={FONT_IDLE_MODAL_BODY.fontSize}
            fontWeight={FONT_IDLE_MODAL_BODY.fontWeight}
          >
            {`${plainText.message.sessionToExpireAlertStart} ${remainingTime} ${plainText.message.sessionToExpireAlertEnd}`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button border='none' _focus={{ boxShadow: 'none' }} colorScheme='blue' ml={3} onClick={pushAnalyticsAcceptSesion}>
              {plainText.button.accept}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default IdleModal;
