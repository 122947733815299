import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import React, { useRef } from 'react';

import { FONT_LOGOUT_CONFIRM } from '../../designTokens/fonts';
import plainText from '../../lang/es.json';

interface LogoutConfirmationProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function LogoutConfirmation({ isOpen, onCancel, onConfirm }: LogoutConfirmationProps) {
  const cancelRef = useRef();
  const pushAnalyticsEventGeneral = option => {
    pushAnalyticsEvent({
      event: 'click_modal',
      content_group: 'Home',
      modal_name: 'Confirmacion cierre de sesion',
      item_id: option,
    });
  };
  const onCancelWithAnalitycs = () => {
    pushAnalyticsEventGeneral('Cancelar');
    onCancel();
  };
  const onConfirmWithAnalitycs = () => {
    pushAnalyticsEventGeneral('Salir');
    onConfirm();
  };
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCancelWithAnalitycs}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            data-testid='name'
            fontFamily={FONT_LOGOUT_CONFIRM.fontFamily}
            fontSize={FONT_LOGOUT_CONFIRM.fontSize}
            fontWeight={FONT_LOGOUT_CONFIRM.fontWeight}
          >
            {plainText.message.logoutConfirmation}
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button variant='primary-inverse' data-testid='cancel-button' onClick={onCancelWithAnalitycs}>
              {plainText.button.cancel}
            </Button>
            <Button data-testid='exit-button' onClick={onConfirmWithAnalitycs} ml={3}>
              {plainText.button.exit}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default LogoutConfirmation;
