/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useScriptSrc = (src: string, type?: string): void => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.type = type || 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

const useScriptInLine = (innerText: string, type?: string): void => {
  useEffect(() => {
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = innerText;
    inlineScript.async = true;
    inlineScript.type = type || 'application/javascript';
    document.body.append(inlineScript);
    return () => {
      inlineScript.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerText]);
};

const useScriptGTM = (innerHTMLHead: string, srcBody: string): void => {
  useEffect(() => {
    // Get the HEAD tag
    const headTag = document.getElementsByTagName('head')[0];

    // First script to add
    const headFirstScript = document.createElement('script');
    headFirstScript.innerHTML = `window.dataLayer = window.dataLayer || [];`;

    // Head comments creation
    const headCommentGTMStart = document.createComment('Google Tag Manager');
    const headCommentGTMEnd = document.createComment('End Google Tag Manager');

    // Second script to add
    const headSecondScript = document.createElement('script');
    headSecondScript.innerHTML = innerHTMLHead;

    // Adding the created DOM elements into the head
    headTag.insertBefore(headCommentGTMEnd, headTag.firstChild);
    headTag.insertBefore(headSecondScript, headTag.firstChild);
    headTag.insertBefore(headCommentGTMStart, headTag.firstChild);
    headTag.insertBefore(headFirstScript, headTag.firstChild);

    /// //////////////////////////////////////////////////////////////////////////////////

    // Get the BODY tag
    const bodyTag = document.getElementsByTagName('body')[0];

    // Nodes creation
    const bodyNoScriptNode = document.createElement('noscript');
    const bodyIframeNode = document.createElement('iframe');
    bodyIframeNode.src = srcBody;
    bodyIframeNode.style.height = '0';
    bodyIframeNode.style.width = '0';
    bodyIframeNode.style.display = 'none';
    bodyIframeNode.style.visibility = 'hidden';
    bodyNoScriptNode.appendChild(bodyIframeNode);

    // Body comments creation
    const bodyCommentGTMStart = document.createComment('Google Tag Manager (noscript)');
    const bodyCommentGTMEnd = document.createComment('End Google Tag Manager (noscript)');

    // Adding the created DOM element into the body
    bodyTag.insertBefore(bodyCommentGTMEnd, bodyTag.firstChild);
    bodyTag.insertBefore(bodyNoScriptNode, bodyTag.firstChild);
    bodyTag.insertBefore(bodyCommentGTMStart, bodyTag.firstChild);

    return () => {
      headFirstScript.remove();
      headSecondScript.remove();

      bodyNoScriptNode.remove();
      bodyIframeNode.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerHTMLHead, srcBody]);
};

export { useScriptGTM, useScriptInLine, useScriptSrc };
