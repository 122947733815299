import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 0;
  height: 16px;
  margin-left: 5px;
  background: transparent;
  border: none;
  color: var(--chakra-colors-primary-100);
`;

export default StyledButton;
