import { Box, Button, Image, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';

import { TypeMigration } from '../../App';
import qrToken from '../../assets/images/migration-qr-token.png';
import step2Token from '../../assets/images/migration-step2-token.png';
import step3Token from '../../assets/images/migration-step3-token.png';
import { AuthContext } from '../../providers/authProvider';
import StyledMigrationInfoView from './styled';

function RenewToken({
  typeToken,
  viewBtnLogout,
  setShowMigrationInfo,
  setSessionKey,
}: {
  typeToken: string;
  viewBtnLogout?: boolean;
  setShowMigrationInfo?: React.Dispatch<React.SetStateAction<TypeMigration>>;
  setSessionKey?: (args: { auth: boolean }) => void;
}) {
  const { logout } = useContext(AuthContext);
  const handleButtonClick = () => {
    setShowMigrationInfo(null);
    setSessionKey({ auth: true });
    sessionStorage.setItem('CheckMigrateTokenLegacy', 'Y');
  };

  return (
    <StyledMigrationInfoView>
      <Box className='migration-info-view__header'>
        <Image className='migration-info-view__header__logo' />
      </Box>

      <Text className='migration-info-view__title' pt='5' data-testid='txt-migration-info-view-title' textStyle='h3'>
        Renová tu {typeToken} para seguir operando
      </Text>
      <Text className='migration-info-view__description' data-testid='txt-migration-info-view-description' textStyle='bodyMd'>
        Para operar en Interbanking, necesitás el Token de la App. Descargá la App, activá el <br />
        Token y disfrutá de la comodidad de operar desde tu celular de forma rápida y segura.
      </Text>
      <Text className='migration-info-view__question' textAlign='center' pb='8' textStyle='bodyMd'>
        Tené en cuenta que IB Multitoken y el token físico ya no funcionan en Interbanking,
        <br /> pero siguen siendo los únicos válidos para ICBC Multipay.
      </Text>

      <Text className='migration-info-view__subtitle' data-testid='txt-migration-info-view-subtitle' textStyle='titleMdBold'>
        Vinculá tu cuenta en simples pasos:
      </Text>

      <div className='migration-info-view__step-grid grid-token'>
        <Box className='migration-info-view__step-grid__step grid-item-1'>
          <Text textStyle='bodyMd' data-testid='txt-migration-info-view-step-1-description'>
            <b>1) </b>Descargá la App desde Play Store o App Store <br /> o escaneá el código QR de forma rápida y segura.
          </Text>
        </Box>

        <Box className='migration-info-view__step-grid__step grid-item-2'>
          <Text textStyle='bodyMd' data-testid='txt-migration-info-view-step-2-description'>
            <b>2) </b> Una vez en la App, presioná el botón Vincular con <br /> usuario y contraseña.
          </Text>
        </Box>

        <Box className='migration-info-view__step-grid__step grid-item-3'>
          <Text textStyle='bodyMd' data-testid='txt-migration-info-view-step-3-description'>
            <b>3) </b> Ingresá tus credenciales, validá tu identidad, ¡y listo! <br /> Ya podés usar el Token de la App.
          </Text>
        </Box>

        <Box className='migration-info-view__step-grid__picture grid-item-4'>
          <Image className='img-step1-token' data-testid='img-migration-info-view-step-1' src={qrToken} />
        </Box>

        <Box className='migration-info-view__step-grid__picture grid-item-5'>
          <Image className='img-step2-token' data-testid='img-migration-info-view-step-2' src={step2Token} />
        </Box>

        <Box className='migration-info-view__step-grid__picture grid-item-6'>
          <Image className='img-step2-token' data-testid='img-migration-info-view-step-3' src={step3Token} />
        </Box>
      </div>

      <Text className='migration-info-view__disclaimer' data-testid='txt-migration-info-view-subtitle' textStyle='bodyMd'>
        ¿Necesitás ayuda? Contactate con Soporte al <b>54 11 5554 2999</b> o <b>54 11 3987 2999</b>.
      </Text>

      <Box className='migration-info-view__footer' data-testid='btn-migration-info-view-home'>
        {viewBtnLogout ? (
          <Button
            onClick={() => {
              logout();
            }}
            variant='primary-text'
          >
            Cerrar sesión
          </Button>
        ) : (
          <Button
            variant='primary-text'
            onClick={() => {
              handleButtonClick();
            }}
          >
            Continuar
          </Button>
        )}
      </Box>
    </StyledMigrationInfoView>
  );
}

export default RenewToken;
