import { Box, Button, Checkbox, Icon, Text, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';

import plainText from '../../../../lang/es.json';
import StyledButton from './StyledButton';

interface ShareDataProps {
  onSave: (value: boolean) => void;
  isCheckedValue: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
}

function ShareData({ onSave, isCheckedValue, isFetching, isLoading, isError }: ShareDataProps) {
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isChecked, setIsChecked] = useState(isCheckedValue);

  const onChangeCheckbox = value => {
    if (!isSaveButtonEnabled) setIsSaveButtonEnabled(true);
    setIsChecked(value);
  };

  const onClickSave = () => onSave(isChecked);

  return (
    <Box display='flex' flexDir='column' alignItems='end'>
      <Box
        width='579px'
        marginBottom='40px'
        borderRadius='12px'
        padding='10px 25px 25px'
        boxShadow='0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)'
      >
        <Box display='flex' alignItems='center' marginBottom='5px'>
          <Text data-testid='lbl-shareBalanceTile' textStyle='titleMdBold' color='secondary.90' margin='0' marginBottom='2px'>
            {plainText.updateProfile.title}
          </Text>
          <Tooltip data-testid='lbl-shareBalanceTooltip' placement='top' hasArrow label={plainText.updateProfile.tooltip}>
            <StyledButton data-testid='icon-shareBalanceHelp'>
              <Icon as={FiHelpCircle} h='16px' w='16px' />
            </StyledButton>
          </Tooltip>
        </Box>
        <Text textStyle='labelXs' color='secondary.90' margin='0' marginBottom='20px'>
          {plainText.updateProfile.disclaimer.text1}
          <Text as='span' textStyle='labelXsBold'>
            {plainText.updateProfile.disclaimer.bold1}
          </Text>
          {plainText.updateProfile.disclaimer.text2}
          <Text as='span' textStyle='labelXsBold'>
            {plainText.updateProfile.disclaimer.bold2}
          </Text>
          {plainText.updateProfile.disclaimer.text3}
          <Text as='span' textStyle='labelXsBold'>
            {plainText.updateProfile.disclaimer.bold3}
          </Text>
          {plainText.updateProfile.disclaimer.text4}
        </Text>
        <Checkbox
          data-testid='check-shareBalance'
          disabled={isFetching || isLoading || isError}
          isChecked={isChecked}
          onChange={e => onChangeCheckbox(e.target.checked)}
        >
          <Text as='span' textStyle={isChecked ? 'bodyMdSemi' : 'bodyMd'} color={isChecked ? 'primary.100' : 'secondary.90'}>
            {plainText.updateProfile.shareBalance}
          </Text>
        </Checkbox>
      </Box>
      <Button
        mx='auto'
        marginBottom='15px'
        loadingText={plainText.button.process}
        isLoading={isLoading}
        onClick={onClickSave}
        disabled={!isSaveButtonEnabled}
        data-testid='btn-saveProfile'
      >
        {plainText.button.save}
      </Button>
    </Box>
  );
}

export default ShareData;
