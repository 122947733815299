import styled from 'styled-components';

import {
  FONT_WELCOME_POPUP_BODY,
  FONT_WELCOME_POPUP_CONTAINER,
  FONT_WELCOME_POPUP_SUBTITLE,
  FONT_WELCOME_POPUP_TITLE,
} from '../../designTokens/fonts';

const StyledCarouselContainer = styled.div`
  & li.alice-carousel__stage-item {
    width: 100% !important;
    height: 505px;
  }

  #to-animate-0,
  #to-animate-1 {
    opacity: 1;
    transition: opacity 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;

    &.fade {
      opacity: 0;
    }
  }

  & div {
    font-style: ${FONT_WELCOME_POPUP_CONTAINER.fontStyle};
  }
`;

const StyledModalCloseButtonContainer = styled.div`
  z-index: 2;

  & button {
    background: none;
    border: none;
    height: 30px;
    top: 15px;
    right: 39px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`;
const StyledCarouselContentPicture = styled.div`
  & img {
    display: block;
    margin: 0px auto 36px;
    height: 182px;
  }
`;
const StyledCarouselContentTitle = styled.div`
  font-family: ${FONT_WELCOME_POPUP_TITLE.fontFamily};
  font-size: ${FONT_WELCOME_POPUP_TITLE.fontSize};
  font-weight: ${FONT_WELCOME_POPUP_TITLE.fontWeight};
  line-height: ${FONT_WELCOME_POPUP_TITLE.lineHeight};
  letter-spacing: ${FONT_WELCOME_POPUP_TITLE.letterSpacing};
  color: var(--chakra-colors-grey-120);
  text-align: center;
  margin-bottom: 10px;
`;
const StyledCarouselContentSubTitle = styled.div`
  font-family: ${FONT_WELCOME_POPUP_SUBTITLE.fontFamily};
  font-size: ${FONT_WELCOME_POPUP_SUBTITLE.fontSize};
  font-weight: ${FONT_WELCOME_POPUP_SUBTITLE.fontWeight};
  line-height: ${FONT_WELCOME_POPUP_SUBTITLE.lineHeight};
  letter-spacing: ${FONT_WELCOME_POPUP_SUBTITLE.letterSpacing};
  color: var(--chakra-colors-grey-120);
  text-align: center;
  margin-bottom: 10px;
`;
const StyledCarouselContentBody = styled.div`
  font-family: ${FONT_WELCOME_POPUP_BODY.fontFamily};
  font-size: ${FONT_WELCOME_POPUP_BODY.fontSize};
  font-weight: ${FONT_WELCOME_POPUP_BODY.fontWeight};
  line-height: ${FONT_WELCOME_POPUP_BODY.lineHeight};
  letter-spacing: ${FONT_WELCOME_POPUP_BODY.letterSpacing};
  text-align: center;
  color: black;
  width: 526px;
  margin: auto;
  margin-bottom: 81px;
`;
const StyledCarouselDotContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 29px 0px 37px;
`;
const StyledCarouselDot = styled.div`
  margin: 0 5px;

  &.active-dot {
    background: var(--chakra-colors-tertiary-110);
    border-radius: 99px;
    height: 10px;
    width: 25px;
  }
  &.inactive-dot {
    background: var(--chakra-colors-secondary-60);
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
`;
const StyledActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin: 0px 16px;
    height: 48px;
    width: 124px;
  }
`;

export {
  StyledActionButtonContainer,
  StyledCarouselContainer,
  StyledCarouselContentBody,
  StyledCarouselContentPicture,
  StyledCarouselContentSubTitle,
  StyledCarouselContentTitle,
  StyledCarouselDot,
  StyledCarouselDotContainer,
  StyledModalCloseButtonContainer,
};
