// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

.css-1xy7m1b {
  background-color: transparent;
  border: none;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;AACd","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\n.hidden {\n  display: none !important;\n}\n\n.css-1xy7m1b {\n  background-color: transparent;\n  border: none;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
