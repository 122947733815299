import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import React from 'react';
import styled from 'styled-components';

import imageDetailedProfile from '../../../assets/images/header/fondoPerfil.png';
import ProfileDetails from './ProfileDetails';

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  showShareData: boolean;
  setIsCheckedShareData: (shareData: boolean) => void;
  isCheckedShareData: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
}

function ProfileModal({
  isOpen,
  onClose,
  showShareData,
  setIsCheckedShareData,
  isCheckedShareData,
  isFetching,
  isLoading,
  isError,
}: ProfileModalProps) {
  const pushCloseModal = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      content_group: 'Home',
      modal_name: 'Datos perfil de operador',
      item_id: 'CTA Cerrar',
    });
  };
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset='slideInBottom'
      closeOnOverlayClick={false}
      size='5xl'
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius='16px'>
        <Bg>
          <ModalCloseButton className='closeModal' onClick={pushCloseModal} />
          <ModalHeader />
        </Bg>
        <ModalBody>
          <ProfileDetails
            showShareData={showShareData}
            setIsCheckedShareData={setIsCheckedShareData}
            isCheckedShareData={isCheckedShareData}
            isFetching={isFetching}
            isLoading={isLoading}
            isError={isError}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ProfileModal;

const Bg = styled.div`
  background-image: url(${imageDetailedProfile});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 200px;
  color: transparent;

  .closeModal {
    background-color: transparent;
    color: black;
    border: 0;
    cursor: pointer;
  }
`;
