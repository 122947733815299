/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable global-require */
import { Box, Button, Image, Text } from '@chakra-ui/react';

import { TypeMigration } from '../../App';
import StyledMigrationInfoView from './styled';

interface MigrationInfoViewProps {
  dateLimitRenewOtpLegacy: string;
  setShowMigrationInfo?: React.Dispatch<React.SetStateAction<TypeMigration>>;
  setSessionKey: ({ auth }: { auth: boolean }) => void;
}

function MigrationInfoView({
  dateLimitRenewOtpLegacy = '15 de Enero de 2025',
  setShowMigrationInfo,
  setSessionKey,
}: MigrationInfoViewProps) {
  const handleButtonClick = () => {
    setShowMigrationInfo(null);
    setSessionKey({ auth: true });
    sessionStorage.setItem('CheckMigrateTokenLegacy', 'Y');
  };

  return (
    <StyledMigrationInfoView>
      <Box className='migration-info-view__header'>
        <Image className='migration-info-view__header__logo' />
      </Box>

      <Text className='migration-info-view__title' data-testid='txt-migration-info-view-title' textStyle='h3'>
        ¡Actualizá tu Token y seguí operando!
      </Text>
      <Text className='migration-info-view__description' data-testid='txt-migration-info-view-description' textStyle='bodyMd'>
        El <b>{dateLimitRenewOtpLegacy}</b>, el Token* que usás dejará de estar disponible, y vas a <br /> necesitar el Token de la app
        Interbanking para seguir operando.
        <br /> Si ya lo tenés, cerrá sesión en la App y volvé a ingresar.
      </Text>

      <div className='migration-info-view__qr-wrapper'>
        <div className='migration-info-view__qr-banner'>
          <Box className='migration-info-view__qr-banner__picture grid-item-6'>
            <Image data-testid='img-migration-info-qr' src={require('../../assets/images/migration-qr.png')} />
          </Box>
          <div>
            <Text className='migration-info-view__qr-description' data-testid='txt-migration-info-qr-description' textStyle='bodyMd'>
              Si no tenés la App, descargala desde Play Store o App Store.
              <br />O escaneá el código QR de forma rápida y segura.
            </Text>
          </div>
        </div>
      </div>

      <Text className='migration-info-view__subtitle' data-testid='txt-migration-info-view-subtitle' textStyle='titleMdBold'>
        Con la App lista, ¡vinculá tu cuenta en pocos pasos!
      </Text>

      <div className='migration-info-view__step-grid'>
        <Box className='migration-info-view__step-grid__picture grid-item-4'>
          <Image data-testid='img-migration-info-view-step-1' src={require('../../assets/images/migration-step1.png')} />
        </Box>

        <Box className='migration-info-view__step-grid__picture grid-item-5'>
          <Image data-testid='img-migration-info-view-step-2' src={require('../../assets/images/migration-step2.png')} />
        </Box>

        <Box className='migration-info-view__step-grid__picture grid-item-6'>
          <Image data-testid='img-migration-info-view-step-3' src={require('../../assets/images/migration-step3.png')} />
        </Box>

        <Box className='migration-info-view__step-grid__step grid-item-1'>
          <Text textStyle='bodyMd' data-testid='txt-migration-info-view-step-1-description'>
            <b>1)</b> Entrá a la plataforma, hacé clic en tu nombre ubicado en el menú superior derecho y seleccioná <b>Perfil.</b>
          </Text>
        </Box>

        <Box className='migration-info-view__step-grid__step grid-item-2'>
          <Text textStyle='bodyMd' data-testid='txt-migration-info-view-step-2-description'>
            <b>2)</b> En el módulo Perfil, seleccioná <b>Adhesión a la App</b> y hacé clic en <b>Adherir a la App.</b>
          </Text>
        </Box>

        <Box className='migration-info-view__step-grid__step grid-item-3'>
          <Text textStyle='bodyMd' data-testid='txt-migration-info-view-step-3-description'>
            <b>3)</b> Elegí la opción correspondiente: <b>Vinculación con la app Interbanking</b> o <b>Cambiá tu Hardtoken por la App.</b>
          </Text>
        </Box>
      </div>

      <Text className='migration-info-view__disclaimer' data-testid='txt-migration-info-view-subtitle' textStyle='titleMdBold'>
        *IB Multitoken y el token físico ya no funcionarán en Interbanking, pero seguirán siendo los únicos válidos para ICBC Multipay.
      </Text>

      <Box className='migration-info-view__footer' data-testid='btn-migration-info-view-home'>
        <Button
          variant='primary-text'
          onClick={() => {
            handleButtonClick();
          }}
        >
          Ir al inicio
        </Button>
      </Box>
    </StyledMigrationInfoView>
  );
}

export default MigrationInfoView;
