/* eslint-disable @typescript-eslint/no-unused-expressions */
// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import menuResponseMock from '../../test/__mocks__/menu/menu_response-mock';
import { IMenuResponse } from '../Interfaces/IMenu';

axios.defaults.baseURL = process.env.API_BASE_URL;

axios.interceptors.request.use(async config => {
  let token = getToken();
  const myConfig = config;
  if (token) {
    myConfig.headers.Authorization = `Bearer ${token}`;
    process.env.API_USER_KEY && (myConfig.headers.user_key = process.env.API_USER_KEY);
  } else {
    setTimeout(() => {
      token = getToken();
    }, 3000);
    myConfig.headers.Authorization = `Bearer ${token}`;
    process.env.API_USER_KEY && (myConfig.headers.user_key = process.env.API_USER_KEY);
  }
  return myConfig;
});

export const getMenuMockup = () => {
  return new Promise<IMenuResponse>(resolve => {
    setTimeout(() => {
      return resolve(menuResponseMock);
    }, 3000);
  });
};

export const getAuthorization = () => axios.get(`home/authorizations`);
export const getBankApproval = () => axios.get('/home/approvals/banks');
export const getCaracteristicaTelf = () => axios.get('home/country');
export const getCompanies = () => axios.get('home/cliente/perfil/menu/empresas');
export const getDashboardShortcuts = () => axios.get('home/shortcuts');
export const getDetallePerfil = () => axios.get('home/cliente/perfil');
export const getMenu = () => axios.get('home/menu');
export const getPerfilMenu = () => axios.get('home/cliente/perfil/menu');

export const patchBankApproval = data => axios.patch('/home/approvals/banks', data);
export const patchLastLoginDate = data => axios.patch('/home/users/lastLoginDate', data);
export const patchVinculationKey = data =>
  axios.patch(
    'home/authorizations/clients/link-up',
    data,
    { params: { ignoreGlobalCatch: true } }, // Use to ignore useAxiosErrorHandler
  );

export const postUpdateTyC = data => axios.put('home/authorizations/documents/TYC/accept', data);

export const putDashboardShortcuts = data => axios.put('home/favoritos', data);
