import { Button, Image, Skeleton, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { Badge } from '@wow/badge';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import useInMenuAivo from '../../hooks/useInMenuAivo';
import useToggleOption from '../../hooks/useToggleOption';
import { IMenu, IMenuResponseDataOptions } from '../../Interfaces/IMenu';
import plainText from '../../lang/es.json';
import getShortcutIcon from '../../utils/getShortcutIcon';
import ChatbotButton from './ChatbotButton';
import ScrollbarMenuContent from './ScrollbarMenuContent';
import { StyledBackOpacity, StyledSidebar } from './StyledSidebar';

interface SideBarMenuProps {
  dataMenu: Array<IMenuResponseDataOptions> | undefined;
  isLoading?: boolean;
}

function SidebarMenu({ isLoading = false, dataMenu }: SideBarMenuProps) {
  const { option: activeOption, toggle: handleClick } = useToggleOption();
  const [isShown, setIsShown] = useState(false);
  const [wheelDeltaY, setWheelDeltaY] = useState<number>(0);
  const { aivo, onClose, onOpen } = useInMenuAivo();
  const sideMenuRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();
      setWheelDeltaY(e.deltaY);
    };

    const sideMenuRendered = sideMenuRef?.current;

    if (sideMenuRendered) {
      sideMenuRendered.addEventListener('wheel', handleScroll);
    }
    return () => {
      if (sideMenuRendered) sideMenuRendered.removeEventListener('wheel', handleScroll);
    };

    // I put this boolean as dependency becouse sideMenuRef is null before the second render
  }, [isLoading]);

  const pushAnalyticsEventLogo = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Menu lateral',
      item_id: 'Volver al inicio',
    });
  };

  const pushAnalyticsEventPrimerNivel = (firstLevelName, secondLevelName) =>
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Menu lateral',
      item_id: `${firstLevelName} - ${secondLevelName}`,
    });

  if (isShown) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }

  function renderSubItems(subitem, item) {
    // Calculate url and use component as openMode when is thirdLevel
    const { openMode, url } =
      subitem.openMode === 'thirdLevel'
        ? { openMode: 'component', url: `/menu/${encodeURIComponent(item.name)}/${encodeURIComponent(subitem.id)}` }
        : subitem;

    return (
      <OpenLink
        onClick={() => pushAnalyticsEventPrimerNivel(item.name, subitem.name)}
        url={url}
        openMode={openMode}
        data-testid={subitem.id}
        className='wordBreakSpan'
        reuseTab
      >
        <Text textStyle='labelMd' className='firstLevelItem'>
          {subitem.name}
        </Text>
        <Text textStyle='labelMd'>{subitem.newOption && <Badge variant='brand-dark'>{plainText.badge.new}</Badge>}</Text>
      </OpenLink>
    );
  }

  const renderOption = (item: IMenu) => {
    return (
      <li key={item.id} className={isShown && activeOption === item.id ? 'active' : ''}>
        <Button
          data-testid={`item-${item.id}`}
          onClick={() => {
            handleClick(item.id);
          }}
        >
          <Image src={getShortcutIcon(item.name)} className='colorBone' />

          <Text textStyle='labelMd' className='firstLevelItem'>
            {item.name}
          </Text>
          <Text textStyle='labelMd'>{item.newOption && <Badge variant='brand-dark'>{plainText.badge.new}</Badge>}</Text>

          <i className='arrow' />
        </Button>
        {activeOption === item.id && isShown && (
          <ul>{item?.menuSectionList?.map((subitem: IMenu) => <li key={subitem.id}>{renderSubItems(subitem, item)}</li>)}</ul>
        )}
      </li>
    );
  };

  if (isLoading) {
    return (
      <Skeleton
        startColor='tertiary.50'
        endColor='transparent'
        width='67px'
        height='100vh'
        position='fixed'
        borderRadius='0px 20px 0px 0px'
        left='0'
        top='0'
      />
    );
  }

  return (
    <>
      <StyledSidebar
        onMouseEnter={() => {
          setIsShown(true);
          onOpen();
        }}
        ref={sideMenuRef}
        onMouseLeave={() => {
          setIsShown(false);
          onClose();
        }}
        className='sidePanel'
        data-testid='sidePanel'
        id='sidePanel'
      >
        <Link to='/' onClick={pushAnalyticsEventLogo}>
          <div className='logo_container'>
            <div className='logo' />
          </div>
        </Link>

        <ScrollbarMenuContent wheelDeltaY={wheelDeltaY} setWheelDeltaY={setWheelDeltaY} option={activeOption}>
          <ul className='nav_list'>{dataMenu && dataMenu.map((option: IMenu) => renderOption(option))}</ul>
        </ScrollbarMenuContent>

        <ChatbotButton onClick={aivo?.chat?.open} />
      </StyledSidebar>
      <StyledBackOpacity />
    </>
  );
}

export default SidebarMenu;
