/* eslint-disable import/no-unresolved */
import { Image, Text, Tooltip } from '@chakra-ui/react';
import { OpenLink } from '@react-ib-mf/style-guide-ui';
import React from 'react';
import { useLocation } from 'react-router-dom';

import getShortcutIcon from '../../../../utils/getShortcutIcon';
import { StyledDirectLinksPending } from './styled';

// eslint-disable-next-line react/prop-types
export default function DirectLinkPending({ status, toolTip }) {
  const url = process.env.URL_PENDIENTES_SIB;
  const categoria = 'Tus pendientes';
  const nombreAtajo = 'Tareas por hacer';
  const location = useLocation();

  return (
    <StyledDirectLinksPending id='directLinkPending' className={`directLinks ${status || location.pathname !== '/' ? 'fixed' : ''}`}>
      <div>
        <OpenLink className='directLinkPending' url={url} openMode='newTab' reuseTab>
          <Tooltip hasArrow label={`${nombreAtajo}`} isDisabled={location.pathname !== '/' ? false : !toolTip} mt='3.5'>
            <div className='custom-icon'>
              <Image src={getShortcutIcon('Pendientes')} maxWidth='50px' />
            </div>
          </Tooltip>

          <Text className='shortcut-text' textStyle='bodyMd'>
            {categoria}{' '}
            <Text className='shortcut-text__category' textStyle='titleSmBold'>
              {nombreAtajo}
            </Text>
          </Text>
        </OpenLink>
      </div>
    </StyledDirectLinksPending>
  );
}
