/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Button, Heading, HStack, Icon, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, throttle, useShowRelatedToMyBalance, useWindowDimensions } from '@react-ib-mf/style-guide-ui';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi';

import { FONT_WIZARD_POPUP } from '../../designTokens/fonts';
import { IFixedCoordinates, IWizardPopupSteps } from '../../Interfaces/IWizardPopup';
import plainText from '../../lang/es.json';
import { getModalName, pushAnalyticOnChangeStep } from '../../utils/wizard';
import WizardPopupGlobalStyles from './WizardPopupGlobalStyles';

function WizardPopup() {
  const wizardContent = {
    steps: [
      {
        title: plainText.wizardPopup.stepNewPerfil.title,
        description: plainText.wizardPopup.stepNewPerfil.description,
        btnNext: true,
        btnPrevious: false,
        placement: 'bottom',
        placementMode: 'coordinates',
        fixedCoordinates: {
          right: '36px',
          top: '112px',
        },
      },
      {
        title: plainText.wizardPopup.stepNewMenu.title,
        description: plainText.wizardPopup.stepNewMenu.description,
        btnNext: true,
        btnPrevious: true,
        targetSelector: '#sidePanel',
        placement: 'right',
        placementMode: 'target',
      },
      {
        title: plainText.wizardPopup.stepDirectLinksPending.title,
        description: plainText.wizardPopup.stepDirectLinksPending.description,
        btnNext: true,
        btnPrevious: true,
        targetSelector: '#directLinkPending',
        placement: 'bottom',
        placementMode: 'target',
      },
      {
        title: plainText.wizardPopup.stepDirectLinks.title,
        description: plainText.wizardPopup.stepDirectLinks.description,
        btnNext: true,
        btnPrevious: true,
        targetSelector: '#directLinks',
        placement: 'bottom',
        placementMode: 'target',
      },
      {
        title: plainText.wizardPopup.stepAccountStatus.title,
        description: plainText.wizardPopup.stepAccountStatus.description,
        btnNext: true,
        btnPrevious: true,
        targetSelector: '#bank-boxes-container',
        placement: 'top-left',
        placementMode: 'target',
      },
      {
        title: plainText.wizardPopup.stepCustomGraphics.title,
        description: plainText.wizardPopup.stepCustomGraphics.description,
        btnNext: true,
        btnPrevious: true,
        targetSelector: '#charts_boxes',
        placement: 'top',
        placementMode: 'target',
      },
      {
        title: plainText.wizardPopup.stepNewVirtualAssist.title,
        description: plainText.wizardPopup.stepNewVirtualAssist.description,
        btnNext: true,
        btnPrevious: true,
        placement: 'left',
        placementMode: 'coordinates',
        fixedCoordinates: {
          left: '72px',
          top: 'calc(100vh - 240px)',
        },
      },
      {
        title: plainText.wizardPopup.stepHelp.title,
        description: plainText.wizardPopup.stepHelp.description,
        btnNext: true,
        btnPrevious: true,
        targetSelector: '#help',
        placement: 'bottom',
        placementMode: 'target',
      },
    ],
    tooltipWidth: 280,
    tooltipHeight: 230,
    tooltipOffset: 8,
  };

  const [showRelatedToMyBalance] = useShowRelatedToMyBalance();

  // Remove banks step when this is required
  const totalStep = wizardContent.steps.length;
  if (!showRelatedToMyBalance) {
    wizardContent.steps = wizardContent.steps.filter(step => step.targetSelector !== '#bank-boxes-container');
  }

  const [styleStepNumber, setStyleStepNumber] = useState(1);

  // Capture the resize event in order to reubicate the tooltip
  const { width, height } = useWindowDimensions();

  // Set the current step value to control the tooltip properties
  const [stateCurrentStep, setStateCurrentStep] = useState<IWizardPopupSteps>({
    ...wizardContent.steps[0],
    step: 1,
  });

  // Set the tooltip position value
  const [tooltipPosition, setTooltipPosition] = useState<IFixedCoordinates>({
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
  });

  // Calculate position for the first wizard step
  useLayoutEffect(() => {
    calculatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto scroll when wizard step changes
  useEffect(() => {
    if (stateCurrentStep?.targetSelector && document.readyState === 'complete' && document.body.classList.contains('wizard_active')) {
      const target = document?.querySelector(stateCurrentStep.targetSelector);
      if (target) {
        const elementRect = target.getBoundingClientRect();
        const absoluteElementTop = elementRect && elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - window.innerHeight / 2;
        elementRect && window.scrollTo({ top: middle, behavior: 'smooth' });
      }
    }
  }, [stateCurrentStep?.targetSelector]);

  useEffect(() => {
    if (document.readyState === 'complete') {
      calculatePosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCurrentStep, width, height]);

  useEffect(() => {
    if (document.readyState === 'complete' && document.body.classList.contains('wizard_active')) {
      pushAnalyticOnChangeStep(stateCurrentStep.step);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCurrentStep]);

  function closeWizard() {
    // Remove classes used in the wizard
    updateStepClasses();
    document.body.classList.remove('wizard_active');

    // Reset step status and position
    setStateCurrentStep({
      ...wizardContent.steps[0],
      step: 1,
    });
  }

  // Relocate the wizard on scroll event
  useEffect(() => {
    const handleScroll = () => {
      setTimeout(() => {
        setStateCurrentStep(prev => ({ ...prev }));
      }, 200);
    };

    window.addEventListener('scroll', throttle(handleScroll, 150));
  }, []);

  const pushAnalyticsAndClose = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: getModalName(stateCurrentStep.step),
      item_id: 'Cerrar',
    });
    closeWizard();
  };

  function calculatePosition() {
    if (stateCurrentStep.placementMode === 'coordinates') {
      // Set new position using the fixed data
      setTooltipPosition(prev => {
        return {
          ...prev,
          top: stateCurrentStep.fixedCoordinates.top || 'auto',
          left: stateCurrentStep.fixedCoordinates.left || 'auto',
          right: stateCurrentStep.fixedCoordinates.right || 'auto',
          bottom: stateCurrentStep.fixedCoordinates.bottom || 'auto',
        };
      });
    } else if (stateCurrentStep.placementMode === 'target') {
      const defaultTarget = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      };
      // Get target position
      const target = document?.querySelector(stateCurrentStep.targetSelector);
      const targetPos = target?.getClientRects()[0] || defaultTarget;

      let offSetX = 0;
      let offSetY = 0;

      // Calculate the offset to ubicate the tooltip
      if (stateCurrentStep.placement) {
        switch (stateCurrentStep.placement) {
          case 'bottom':
            offSetX = targetPos.width / 2 - wizardContent.tooltipWidth / 2;
            offSetY = targetPos.height + wizardContent.tooltipOffset;
            break;

          case 'top':
            offSetX = targetPos.width / 2 - wizardContent.tooltipWidth / 2;
            offSetY = -wizardContent.tooltipHeight - wizardContent.tooltipOffset;
            break;

          case 'left':
            offSetX = -wizardContent.tooltipWidth - wizardContent.tooltipOffset;
            offSetY = targetPos.height / 2 - wizardContent.tooltipHeight / 2;
            break;

          case 'right':
            offSetX = targetPos.width + wizardContent.tooltipOffset;
            offSetY = targetPos.height / 2 - wizardContent.tooltipHeight / 2;
            break;

          case 'top-left':
            offSetX = 0;
            offSetY = -wizardContent.tooltipHeight - wizardContent.tooltipOffset;
            break;

          default:
            offSetX = 0;
            offSetY = 0;
            break;
        }
      }

      // Set new position
      setTooltipPosition(prev => {
        return {
          ...prev,
          top: `${targetPos.top + offSetY}px`,
          left: `${targetPos.left + offSetX}px`,
        };
      });
    }
  }

  function updateStepClasses(stepToAdd?, isPrevious?) {
    // Remove all the steps before add the new if it's required
    const CLASS_PREFIX = 'step';
    for (let index = 1; index <= totalStep; index += 1) {
      document.body.classList.remove(CLASS_PREFIX + index);
    }

    let auxStepToAdd = stepToAdd;
    const STEP_TO_REMOVE = 5;
    if (!showRelatedToMyBalance && stepToAdd >= STEP_TO_REMOVE) {
      auxStepToAdd = isPrevious ? styleStepNumber - 1 : stepToAdd + 1;
    }

    setStyleStepNumber(auxStepToAdd);

    // Add the current step class
    if (stepToAdd) document.body.classList.add(`step${auxStepToAdd}`);
  }

  const pushAnalyticOnModalClick = (isPrevious, isLastStep) => {
    const getItemId = () => {
      if (isPrevious) {
        return 'Retroceder';
      }
      if (isLastStep) {
        return 'Completar';
      }
      return 'Avanzar';
    };

    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: getModalName(stateCurrentStep.step),
      item_id: getItemId(),
    });
  };

  function handleClick(isPrevious, isLastStep = false) {
    pushAnalyticOnModalClick(isPrevious, isLastStep);

    // Check the new step number
    const newStepNumber = isPrevious ? stateCurrentStep.step - 1 : stateCurrentStep.step + 1;

    if (newStepNumber > wizardContent.steps.length) {
      // Close if there are not remaining steps
      closeWizard();
      document.body.classList.remove(`step${styleStepNumber}`);
    } else {
      // Update state and classes using the new step info
      setStateCurrentStep(prev => ({
        ...prev,
        step: isPrevious ? prev.step - 1 : prev.step + 1,
        title: wizardContent.steps[newStepNumber - 1].title,
        description: wizardContent.steps[newStepNumber - 1].description,
        btnNext: wizardContent.steps[newStepNumber - 1].btnNext,
        btnPrevious: wizardContent.steps[newStepNumber - 1].btnPrevious,
        placement: wizardContent.steps[newStepNumber - 1].placement,
        placementMode: wizardContent.steps[newStepNumber - 1].placementMode,
        targetSelector: wizardContent.steps[newStepNumber - 1].targetSelector || undefined,
        fixedCoordinates: wizardContent.steps[newStepNumber - 1].fixedCoordinates || undefined,
      }));

      updateStepClasses(newStepNumber, isPrevious);
    }
  }

  const renderPreviousStepButton = () =>
    stateCurrentStep.btnPrevious && (
      <Button
        className='actions-btn__left custom_btn'
        onClick={() => handleClick(true)}
        data-testid='btn-beforeStep'
        leftIcon={<FiChevronLeft />}
      />
    );

  const renderNextStepButton = () => {
    const isLastStep = stateCurrentStep.step === wizardContent.steps.length;
    return stateCurrentStep.btnNext && isLastStep ? (
      <Button
        className='actions-btn__right custom_btn'
        aria-hidden='true'
        data-testid='btn-nextStep'
        onClick={() => handleClick(false, isLastStep)}
        style={{
          display: 'flex',
          height: '40px',
          padding: '0px 12px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          width: 'auto',
        }}
      >
        <Text textStyle='labelMdBold'>Finalizar</Text>
      </Button>
    ) : (
      <Button
        className='actions-btn__right custom_btn'
        onClick={() => handleClick(false, isLastStep)}
        aria-hidden='true'
        data-testid='btn-nextStep'
        leftIcon={<FiChevronRight />}
      />
    );
  };

  return (
    <>
      <WizardPopupGlobalStyles />
      <Box
        className={`toolTipBox triangle toolTipBox${styleStepNumber}`}
        w={`${wizardContent.tooltipWidth}px`}
        h={`${wizardContent.tooltipHeight}px`}
        bg='grey.120'
        color='white'
        rounded='8px'
        textAlign='center'
        padding='10px 10px 30px 10px'
        data-testid='box-wizardStep'
        id='tooltipBox'
        position='absolute'
        flexDirection='column'
        justifyContent='space-between'
        top={tooltipPosition.top}
        left={tooltipPosition.left}
        right={tooltipPosition.right}
        bottom={tooltipPosition.bottom}
      >
        <HStack justifyContent='space-between'>
          <Text fontSize={FONT_WIZARD_POPUP.fontSizeDescription}>
            {stateCurrentStep.step}/{wizardContent.steps.length}
          </Text>

          <div className='bnt-close-wizard' onClick={pushAnalyticsAndClose} aria-hidden='true' data-testid='btn-closeWizard'>
            <Icon as={FiX} w={6} h={6} color='white' className='close' />
          </div>
        </HStack>

        <Heading
          as='h5'
          fontSize={FONT_WIZARD_POPUP.fontSizeTitle}
          margin='10px 0 10px 0'
          letterSpacing={FONT_WIZARD_POPUP.letterSpacingTitle}
        >
          {stateCurrentStep.title}
        </Heading>

        <Text
          fontSize={FONT_WIZARD_POPUP.fontSizeDescription}
          lineHeight='20px'
          margin='0 0 22px 0'
          letterSpacing={FONT_WIZARD_POPUP.letterSpacingDescription}
        >
          {stateCurrentStep.description}
        </Text>

        <div className='actions-btn'>
          {renderPreviousStepButton()}
          {renderNextStepButton()}
        </div>
      </Box>
    </>
  );
}

export default WizardPopup;
