import styled from 'styled-components';

import bigLogo from '../../assets/images/logo-big.svg';
import logoSmall from '../../assets/images/logo-small.svg';

export const StyledSidebar = styled.div`
  background-color: var(--chakra-colors-tertiary-110);
  color: var(--chakra-colors-tertiary-30);
  width: 64px;
  z-index: 151;
  border-radius: 0px 20px 0px 0px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;

  .logo_container {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .logo {
    width: 24px;
    height: 24px;
    background-image: url(${logoSmall});
    background-repeat: no-repeat;
  }

  .sidebar_nav {
    padding: 0;
    display: flex;
    width: 100%;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    flex: 1;
    display: flex;
    max-height: calc(100% - 144px);
  }

  .nav_container {
    height: 20px;
    overflow-y: auto;
    flex: 1;
  }

  .nav_container::-webkit-scrollbar {
    display: none;
  }

  .nav_list {
    list-style: none;
    height: 100%;
    padding: 0;
    margin: 0;
    > li {
      margin: 0;
      > button {
        border: 1px solid transparent;

        &:hover {
          background: rgba(255, 255, 255, 0.06);
          border: 1px solid rgba(255, 255, 255, 0.08);
        }

        &:focus {
          box-shadow: none;
        }

        // 1st level Enabled state
        p {
          color: var(--chakra-colors-tertiary-40);
          font-weight: 400;
        }
        img {
          filter: brightness(0) saturate(100%) invert(98%) sepia(99%) saturate(2396%) hue-rotate(183deg) brightness(101%) contrast(104%);
        }
        .arrow {
          border-color: var(--chakra-colors-tertiary-40);
        }

        // 1st level Hover state
        &:hover p {
          color: var(--chakra-colors-tertiary-50);
          font-weight: 400;
        }
        &:hover img {
          filter: brightness(0) saturate(100%) invert(77%) sepia(33%) saturate(885%) hue-rotate(200deg) brightness(100%) contrast(103%);
        }
        &:hover .arrow {
          border-color: var(--chakra-colors-tertiary-50);
        }

        // 1st level Pressed state
        &:active p {
          color: var(--chakra-colors-tertiary-60);
          font-weight: 400;
        }
        &:active img {
          filter: brightness(0) saturate(100%) invert(80%) sepia(35%) saturate(7127%) hue-rotate(210deg) brightness(103%) contrast(109%);
        }
        &:hover .arrow {
          border-color: var(--chakra-colors-tertiary-60);
        }

        padding: 0;
        height: 40px;
        border-radius: 8px;
        background-color: transparent;
        color: inherit;
        cursor: pointer;
        width: 100%;
        display: flex;
        gap: 16px;
        justify-content: start;
        gap: 16px;
        span {
          margin-left: auto;
        }
      }

      &.active {
        .sidebarnav_container {
          margin-top: 0px;
        }

        ul {
          list-style: none;
          display: block;
          padding: 8px 0;
          margin: 0;

          li {
            a {
              margin-bottom: 8px;
              height: 32px;
              display: flex;
              padding-left: 20px;
              -webkit-box-align: center;
              align-items: center;
              justify-content: space-between;
              color: inherit;
              text-decoration: none;
              gap: 8px;
              width: 100%;
              border-radius: 8px;

              & .chakra-badge {
                margin-right: 10px;
              }

              &:hover {
                background: rgba(255, 255, 255, 0.08);
              }

              p {
                // 2nd level Enabled state
                min-width: 8.5rem;
                color: var(--chakra-colors-tertiary-40);
                font-weight: 400;
              }

              &:hover p {
                // 2nd level Hover state
                color: var(--chakra-colors-tertiary-50);
                font-weight: 400;
              }

              &:active p {
                // 2nd level Pressed state
                color: var(--chakra-colors-tertiary-60);
                font-weight: 400;
              }
            }
          }
        }

        .arrow {
          transform: rotate(-135deg);
        }
      }
    }
  }

  .colorBone {
    flex-shrink: 0;
    height: 40px;
    width: 40px;
  }

  .chatbot {
    height: 40px;
    width: 100%;
    background-color: #000000;
  }

  .divider {
    flex: 0 0 1px;
    background-color: #ffffff3d;
    width: 100%;
  }

  .divider_container {
    height: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;

    > div {
      margin: 0;
    }
  }

  .arrow {
    margin-left: auto;
    border: solid var(--chakra-colors-tertiary-40);
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    margin-left: auto;
    padding-left: auto;
    margin-right: 13px;
    transition: all 0.3s ease-in-out;
  }

  .firstLevelItem {
    display: block;
    width: 120px;
    color: var(--chakra-colors-tertiary-30);
    text-align: start;
    overflow: hidden;
    white-space: normal;
  }

  .backOpacity {
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    top: 0;
    opacity: 0;
    width: 100%;
    display: none;
    overflow: hidden auto;
    position: fixed;
  }

  &:hover {
    width: 312px;
    padding: 32px 24px;

    a {
      width: 100%;
    }

    .logo_container {
      width: 100%;
      justify-content: start;
    }

    .logo {
      width: 116px;
      height: 30px;
      background-image: url(${bigLogo});
      background-repeat: no-repeat;
    }
  }
`;

export const StyledBackOpacity = styled.div`
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  top: 0;
  opacity: 0;
  width: 100%;
  display: none;
  overflow: hidden auto;
  position: fixed;

  ${StyledSidebar}:hover ~ & {
    display: block;
    opacity: 1;
    z-index: 150;
  }
`;
