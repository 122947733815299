import { Button, Tooltip } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';

import lifeBouy from '../../assets/images/icons/life-buoy.svg';
import plainText from '../../lang/es.json';
import { showWizard } from '../../utils/wizard';

interface WizardProps {
  disabled: boolean;
}

function Wizard({ disabled }: WizardProps) {
  const openWizard = () => {
    pushAnalyticsEvent({ event: 'select_content', conten_type: 'Menu header', item_id: 'CTA Wizard' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    showWizard();
  };

  return (
    <Tooltip hasArrow label={plainText.label.wizardTooltip}>
      <Button
        data-testid='btn-openWizard'
        onClick={openWizard}
        disabled={disabled}
        style={{ backgroundColor: 'transparent', cursor: disabled ? 'default' : 'pointer' }}
      >
        <i className='icon'>
          <img src={lifeBouy} alt='Consumo' />
        </i>
      </Button>
    </Tooltip>
  );
}

export default Wizard;
