// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';

export const getModalName = (step: number) => `Guia rapida - modal ${step} de 6`;

export const pushAnalyticOnChangeStep = (step: number) =>
  pushAnalyticsEvent({
    event: 'impression_modal',
    content_group: 'Home',
    modal_name: getModalName(step),
  });

export const showWizard = () => {
  document.body.classList.add('wizard_active');
  document.body.classList.add('step1');
  pushAnalyticOnChangeStep(1);
};
