import { Icon } from '@chakra-ui/react';
import React from 'react';
import { FiBell } from 'react-icons/fi';
import styled from 'styled-components';

function Notifications() {
  return (
    // <Button className='notifications'>
    //   <i className='badge pulse heartbeat'>
    //     <span>0</span>
    //   </i>

    //   <Icon as={FiBell} w={7} h={7} color='primary.700' />
    // </Button>

    <StyledNotificationsButton className='notifications'>
      {/* <i className='badge pulse heartbeat'>
        <span>0</span>
      </i> */}

      <Icon as={FiBell} w={7} h={7} color='primary.70' />
    </StyledNotificationsButton>
  );
}

export default Notifications;

const StyledNotificationsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: 0;
  position: relative;
  padding: 0;
  cursor: default;

  .badge {
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--chakra-colors-error-90);
    border-radius: 50%;
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.1);
    display: block;
    right: 8px;
    top: 2px;
  }

  .pulse {
    animation: scale 3s cubic-bezier(0.175, 0.885, 0.32, 1.3) infinite;
    &:before,
    &:after {
      content: '';
      position: absolute;
      border: var(--chakra-colors-error-90) solid 4px;
      border-radius: 100em;
      width: 100%;
      height: 100%;
      top: -4px;
      left: -4px;
      margin: auto;
      opacity: 0;

      animation: pulse 3s cubic-bezier(0.175, 0.885, 0.32, 1.1) infinite;
      animation-delay: 400ms;
    }
    &:before {
      animation-delay: 600ms;
    }

    span {
      display: none;
    }
  }

  .heartbeat {
    animation: heartbeat 3s cubic-bezier(0.175, 0.885, 0.32, 1.3) infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 0.2;
      transform: scale(1);
    }
    50% {
      opacity: 0;
      transform: scale(1.3);
    }
  }

  @keyframes heartbeat {
    0%,
    10%,
    20% {
      transform: scale(1);
    }
    5%,
    15% {
      transform: scale(1.1);
    }
  }
`;
