const options = {
  data: [
    {
      characteristic: '+54',
      countryCode: 'AR',
      countryId: 248,
      countryName: 'Argentina',
    },
    {
      characteristic: '588',
      countryCode: 'AF',
      countryId: 3,
      countryName: 'Afghanistan ',
    },
    {
      characteristic: '453',
      countryCode: 'AL',
      countryId: 6,
      countryName: 'Albania ',
    },
    {
      characteristic: '87',
      countryCode: 'DZ',
      countryId: 60,
      countryName: 'Algeria ',
    },
  ],
  success: true,
};

export default options;
