import { Icon } from '@chakra-ui/react';
import { IconButtonComponent } from '@wow/button';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

interface ScrollbarMenuContentProps {
  children: ReactNode | ReactNode[];
  wheelDeltaY: number;
  setWheelDeltaY: Dispatch<SetStateAction<number>>;
  option: unknown;
}

function ScrollbarMenuContent({ children, wheelDeltaY, setWheelDeltaY, option }: ScrollbarMenuContentProps) {
  const [isScrolleable, setIsScrolleable] = useState<boolean>(false);
  const listContainerRef = useRef<HTMLDivElement>();

  const handleResize = () => {
    const isNavScrolleable: boolean = listContainerRef.current?.scrollHeight > listContainerRef.current?.clientHeight;
    setIsScrolleable(isNavScrolleable);
  };

  const scrollTo = useCallback(scrollOffSet => {
    const scrollTop = listContainerRef.current.scrollTop + scrollOffSet;
    listContainerRef.current.scrollTo({
      behavior: 'smooth',
      top: scrollTop,
    });
  }, []);

  const scrollToView = useCallback(() => {
    if (wheelDeltaY) {
      listContainerRef?.current?.scrollTo({
        behavior: 'smooth',
        top: listContainerRef.current.scrollTop + wheelDeltaY,
      });

      // without the timeOut this method  is executed a lot of times in the same event.
      setTimeout(() => {
        setWheelDeltaY(0);
      }, 200);
    }
  }, [wheelDeltaY, setWheelDeltaY]);

  useEffect(() => {
    scrollToView();
  }, [scrollToView]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(listContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [option]);

  return (
    <div className='sidebar_nav'>
      <div className='divider_container'>
        <div className='divider' />
        <IconButtonComponent
          _focus={{ boxShadow: 'none' }}
          aria-label='Search Icon'
          data-testid='icon-chevron-up'
          margin='0'
          onClick={() => scrollTo(-120)}
          size='i-sm'
          variant='icon-dark-text'
          disabled={!isScrolleable}
          icon={<Icon as={FiChevronUp} color='tertiary.40' />}
        />
      </div>

      <div className='nav_container' data-testid='nav_container' ref={listContainerRef}>
        {children}
      </div>

      <div className='divider_container'>
        <IconButtonComponent
          _focus={{ boxShadow: 'none' }}
          aria-label='Search Icon'
          data-testid='icon-chevron-down'
          onClick={() => scrollTo(120)}
          size='i-sm'
          variant='icon-dark-text'
          disabled={!isScrolleable}
          icon={<Icon as={FiChevronDown} color='tertiary.40' />}
        />
        <div className='divider' />
      </div>
    </div>
  );
}

export default ScrollbarMenuContent;
