import './styles/index.css';

import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import ChangeEntityForm from './components/SibForm/ChangeEntityForm';
import { useScriptGTM } from './hooks/useScript';
import { AuthProvider } from './providers/authProvider';
import { CustomChakraProvider } from './providers/CustomChakraProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount, error: any) => {
        return failureCount < 1 && !['401', '403'].includes(error?.response?.status);
      },
      retryDelay: 2500,
    },
  },
});

export default function Root() {
  useScriptGTM(process.env.GTM_SNIPPET_HEAD, process.env.GTM_SNIPPET_BODY);

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <CustomChakraProvider addCSSVars>
          <ChangeEntityForm />
          <App />
        </CustomChakraProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}
