import { Icon } from '@chakra-ui/react';
import { FiSearch /* FiX */ } from 'react-icons/fi';
import styled from 'styled-components';
// import { Input } from '@chakra-ui/react';

// Esto esta comentado porque de manera temporal estas funciones estan inactivas -No borrar-

function Search() {
  return (
    // <SearchBar className={isActive ? 'active' : ''}>
    //   <div className='field'>
    //     <Input type='search' placeholder='Buscar...' />
    //   </div>

    <StyledSearchButton className='search' /* onClick={handleClick} */>
      {/* <Icon as={FiX} w={7} h={7} color='tertiary.110' className='close' /> */}
      <Icon as={FiSearch} w={7} h={7} color='grey.60' className='custom-icon-search' />
    </StyledSearchButton>
    // </SearchBar>
  );
}
export default Search;

const StyledSearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: 0;
  position: relative;
  padding: 0;
  cursor: default;

  svg {
    color: var(--chakra-colors-primary-70);
  }
`;

// Estos estilos esta comentado porque de manera temporal estas funciones estan inactivas -No borrar-

// const SearchBar = styled.div`
//   display: flex;
//   justify-content: end;

//   .field {
//     max-width: 0;
//     width: 100%;
//     transition: all 0.3s ease-in-out;
//     overflow: hidden;
//   }
//   input {
//     background-color: white;
//     border: solid 1px white;
//     border-radius: 50px;
//     padding: 10px 18px;
//     transition: all 0.3s ease-in-out;
//     width: 100%;
//   }
//   &:not(.active) {
//     .close {
//       display: none;
//     }
//   }

//   &.active {
//     .field {
//       max-width: 200px;
//     }

//     .search {
//       .custom-icon-search {
//         display: none;
//       }
//       /* svg {
//         display: none;
//       } */
//     }
//   }
// `;
