import { Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import Flag from 'react-flagkit';
import { FiChevronDown } from 'react-icons/fi';

import { FONT_SELECT_NAL_CUSTOM_SEL, FONT_SELECT_NAL_CUSTOM_SEL_CONTENT } from '../../../../designTokens/fonts';
import { INacionalidad } from '../ProfileDetails';

interface SelectNacionalidadProps {
  nacionalidad?: INacionalidad;
  setNacionalidad: React.Dispatch<React.SetStateAction<INacionalidad>>;
  paises: INacionalidad[];
  defaultOption: string;
  disable: boolean;
}

function SelectNacionalidad({ nacionalidad, setNacionalidad, paises, defaultOption, disable }: SelectNacionalidadProps) {
  const [isActive, setIsActive] = useState(false);
  const opcionesNacionalidad = paises?.slice();
  const handleOpenDrop = () => {
    if (!disable) {
      setIsActive(!isActive);
    }
  };
  return (
    <CustomSelectWrapper>
      <div data-testid='nacionalityField' className={disable ? 'custom-select disabled' : 'custom-select'}>
        <div className='custom-select-btn' data-testid='btn-openNacionalitySelector' role='presentation' onClick={handleOpenDrop}>
          {nacionalidad?.countryCode?.length > 0 ? (
            <>
              <Flag country={nacionalidad.countryCode} />
              <p>{nacionalidad.countryCode}</p>
              <Icon as={FiChevronDown} className='icon' w={4} h={4} />
            </>
          ) : (
            <>
              <Flag country={defaultOption} />
              <p>{defaultOption}</p>
              {!disable && <Icon as={FiChevronDown} className='icon' w={4} h={4} />}
            </>
          )}
        </div>
        {isActive && (
          <div data-testid='nacionalitySelector' className='custom-select-content'>
            {opcionesNacionalidad.map(item => (
              <div
                className='custom-select-item'
                role='presentation'
                key={item.countryCode}
                id={item.countryName}
                data-testid={`pais-${item.countryId}`}
                onClick={() => {
                  setNacionalidad(item);
                  setIsActive(false);
                }}
              >
                <Flag country={item.countryCode} />
                <p>{item.countryCode}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </CustomSelectWrapper>
  );
}

export default SelectNacionalidad;

const CustomSelectWrapper = styled.div`
  width: 98px;
  height: 32px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  .custom-select {
    height: 100%;
    .custom-select-btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      p,
      img,
      svg {
        margin: 0;
      }
      p {
        font-size: ${FONT_SELECT_NAL_CUSTOM_SEL.fontSize};
      }
    }
    .custom-select-content {
      position: absolute;
      top: 110%;
      padding: 0 6px;
      border: 1px solid var(--chakra-colors-secondary-60);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 98px;
      background-color: white;
      z-index: 1;
      .custom-select-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        transition: all 0.2s;
        img,
        svg {
          margin: 0;
        }
        p {
          margin: 0 0 0 14px;
          font-size: ${FONT_SELECT_NAL_CUSTOM_SEL_CONTENT.fontSize};
          color: var(--chakra-colors-tertiary-100);
        }
        &:hover {
          background-color: var(--chakra-colors-warning-50);
        }
      }
    }
  }
  .disabled {
    opacity: 1;
    cursor: not-allowed;
    background: #f2f0f4;
    border: 1px solid #c7c6ca;
    color: #c7c6ca;
    border-radius: 5px;
  }
`;
