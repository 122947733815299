// eslint-disable-next-line import/no-unresolved

import { ErrorComponent } from '@wow/error';

import plainText from '../../lang/es.json';

function ErrorScreen({ onRetry }: { onRetry: () => void }) {
  return (
    <div
      className='container'
      style={{
        height: '100vh',
        width: '100%',
        top: 0,
        left: 0,
        position: 'fixed',
        backgroundColor: 'white',
        zIndex: 2,
        display: 'flex',
      }}
    >
      <ErrorComponent
        titleText={plainText.errorScreen.app.title}
        bodyText={plainText.errorScreen.app.body}
        linkText={plainText.errorScreen.app.link}
        callbackAction={onRetry}
        isRouter={false}
      />
    </div>
  );
}

export default ErrorScreen;
