import {
  getOidcStoraged,
  pushAnalyticsFromError,
  useErrorMessage,
  useHasNewPlan,
  usePlanName,
  useUserRole,
  // eslint-disable-next-line import/no-unresolved
} from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import imageHeaderBg from '../../assets/images/header/bg.png';
import { FONT_HEADER_NAV_WELCOME_BIG, FONT_HEADER_NAV_WELCOME_SMALL } from '../../designTokens/fonts';
import { Company } from '../../Interfaces/IDropdownProfile';
import plainText from '../../lang/es.json';
import { getCompanies, getDashboardShortcuts, getPerfilMenu } from '../../services/api';
import DropdownProfile from '../dropdownProfile';
import SearchNotification from '../SearchNotification';
import DirectLinks from './components/directLinks';

interface HeaderNavProps {
  onChangeEntity: (company?: Company) => void;
  logout: () => void;
}

function HeaderNav({ onChangeEntity, logout }: HeaderNavProps) {
  const location = useLocation();
  const { showErrorMessage } = useErrorMessage();
  const { data, error } = useQuery('perfil', () => getPerfilMenu().then(res => res.data), { onError: pushAnalyticsFromError });
  const { data: companies, error: errorCompanies } = useQuery('companies', () => getCompanies().then(res => res?.data?.data), {
    onError: pushAnalyticsFromError,
  });

  const {
    data: shortcutsData,
    error: errorShortcuts,
    isLoading: isLoadingShortcuts,
  } = useQuery('atajos', () => getDashboardShortcuts().then(res => res.data), {
    retry: 2,
    onError: e => {
      showErrorMessage({ title: plainText.toast.error.headerNavDirectLinks, error: e });
      pushAnalyticsFromError(e);
    },
  });
  const [scroll, setScroll] = useState<boolean>(location.pathname !== '/');
  const [TooltipActive, setTooltipActive] = useState<boolean>(location.pathname !== '/');

  const [, setUserRole] = useUserRole();
  const [, setHasNewPlan] = useHasNewPlan();

  useEffect(() => {
    setUserRole(data?.data?.options[1]?.description);
    setHasNewPlan(data?.data?.hasNewPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/') {
        const elements = document.querySelector('.headerBg') as HTMLInputElement;
        if (window.pageYOffset >= elements?.offsetHeight) {
          document.body.classList.add('header_fixed');
          setScroll(true);
          setTooltipActive(true);
        } else {
          document.body.classList.remove('header_fixed');
          setScroll(false);
          setTooltipActive(false);
        }
      }
    };
    if (location.pathname !== '/') {
      document.body.classList.add('header_fixed');
    } else {
      handleScroll();
    }
    window.onscroll = handleScroll;
  }, [location]);

  // Store the current plan name
  const [, setPlanName] = usePlanName();
  useEffect(() => {
    setPlanName(data?.data?.options[2]?.label || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const currentError = error || errorCompanies;
    if (currentError) {
      showErrorMessage({ title: plainText.toast.error.headerNavProfileInfo, error: currentError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorCompanies]);

  const oidc = getOidcStoraged();
  const name = oidc?.profile?.nombre;

  return (
    <Bg
      id='header-id'
      className={isLoadingShortcuts || (location.pathname === '/' && !errorShortcuts && shortcutsData?.data?.length) ? 'addPadding' : ''}
    >
      <Header className='hdr'>
        <WelcomeUser className='welcome_user'>
          <h1>
            {plainText.message.hello}
            {name ? `, ${name}` : ''}
            {plainText.message.exclamation}
          </h1>
          <small>{plainText.message.startInterbanking}</small>
        </WelcomeUser>

        <RightHeader className='header_right'>
          <SearchNotification />
          <DropdownProfile logout={logout} data={data} companies={companies} onChangeEntity={onChangeEntity} />
        </RightHeader>
      </Header>
      <DirectLinks status={scroll} toolTip={TooltipActive} data={shortcutsData} isLoading={isLoadingShortcuts} />
    </Bg>
  );
}

export default HeaderNav;

const Bg = styled.div`
  background-image: url(${imageHeaderBg});
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 150;
  width: 100%;
  &.addPadding {
    padding-bottom: 10px;
  }
  .loading-screen {
    z-index: 155;
  }
`;

const Header = styled.div`
  padding-right: 40px;
  padding-top: 0px;
  padding-left: 128px;
  min-height: 142px;
  position: relative;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightHeader = styled.div`
  position: absolute;
  right: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 0.3s ease-in-out;
`;

const WelcomeUser = styled.div`
  h1 {
    margin: 0;
    font-size: ${FONT_HEADER_NAV_WELCOME_BIG.fontSize};
    font-weight: ${FONT_HEADER_NAV_WELCOME_BIG.fontWeight};
    line-height: ${FONT_HEADER_NAV_WELCOME_BIG.lineHeight};
    color: var(--chakra-colors-tertiary-110);
  }
  small {
    font-style: ${FONT_HEADER_NAV_WELCOME_SMALL.fontStyle};
    font-size: ${FONT_HEADER_NAV_WELCOME_SMALL.fontSize};
    font-weight: ${FONT_HEADER_NAV_WELCOME_SMALL.fontWeight};
    line-height: ${FONT_HEADER_NAV_WELCOME_SMALL.lineHeight};
    color: var(--chakra-colors-tertiary-110);
  }
`;
