/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { getErrorCode, useErrorMessage } from '@react-ib-mf/style-guide-ui';
// import React, { useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useMutation } from 'react-query';

import blockedKey from '../../assets/images/vinculation-blocked-key.svg';
import plainText from '../../lang/es.json';
import { patchVinculationKey } from '../../services/api';
import { AUTHORIZATION_STATUS } from '../../utils/constants';
import SimpleBanner from '../SimpleBanner';
import StyledVinculationView from './styled';

interface VinculationViewProps {
  vinculationData: any;
  backToSignin: () => void;
  refetchAuthorization: () => void;
}

interface VinculationKeyInt {
  linkingKey: string;
}

type PasswordStatus = 'input-empty' | 'input-filled' | 'input-incorrect';
type ContinueButtonStatus = 'btn-disabled' | 'btn-enabled' | 'btn-loading';

function VinculationView({ vinculationData, backToSignin, refetchAuthorization }: VinculationViewProps) {
  const [currentVinculationCodeStatus, setCurrentVinculationCodeStatus] = useState(`${vinculationData.codeStatus}`);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState<PasswordStatus>('input-empty');
  const [continueButtonStatus, setContinueButtonStatus] = useState<ContinueButtonStatus>('btn-disabled');

  const handleShowPassword = () => setShowPassword(!showPassword);
  const { showErrorMessage } = useErrorMessage();

  function onCopyPasteHandler(event) {
    event.preventDefault();
    return false;
  }

  function definePassword(event) {
    const passwordInput = event.target.value;
    setPassword(passwordInput);

    // Handle components status
    if (event.target.value.length === 0) {
      setPasswordStatus('input-empty');
      setContinueButtonStatus('btn-disabled');
    } else {
      setPasswordStatus('input-filled');
      setContinueButtonStatus('btn-enabled');
    }
  }

  function validate(input) {
    const isValidLength = input.length >= 8 && input.length <= 12;
    const hasLetter = /[a-zA-Z]/.test(input);
    const hasNumber = /\d/.test(input);
    const isAlphanumeric = /^[a-zA-Z\d]+$/.test(input);

    return isValidLength && hasLetter && hasNumber && isAlphanumeric;
  }

  function submitPassword() {
    if (!validate(password)) {
      setPasswordStatus('input-incorrect');
      return;
    }

    if (continueButtonStatus === 'btn-enabled' && password.length > 0) {
      const builtObj = {
        linkingKey: password,
      };

      mutate(builtObj);
    } else {
      setPasswordStatus('input-incorrect');
    }
  }

  const { mutate, isError, isSuccess, isLoading, error } = useMutation((vinculationKey: VinculationKeyInt) => {
    return patchVinculationKey(vinculationKey);
  });

  useEffect(() => {
    if (isLoading) {
      setContinueButtonStatus('btn-loading');
    } else if (isError) {
      if (getErrorCode(error) === 400) {
        // Handle components status
        setPasswordStatus('input-incorrect');
        setContinueButtonStatus('btn-disabled');
      } else if (getErrorCode(error) === 401) {
        // eslint-disable-next-line dot-notation
        if (error['response']?.data?.exception === 'Ocurrió un error al validar la clave de vinculación del operador') {
          // Handle components status
          setPasswordStatus('input-incorrect');
          setContinueButtonStatus('btn-disabled');
        } else {
          // exception = 'El operador ha sido bloqueado'
          // Set new code status to show block screen
          setCurrentVinculationCodeStatus('21');
        }
      } else {
        // If the response is 5XX show toast
        showErrorMessage({ title: plainText.toast.error.vinculationView });

        // Handle components status
        setContinueButtonStatus('btn-enabled');
      }
    } else if (isSuccess) {
      // Call autorization again to check other status
      refetchAuthorization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, isError]);

  // Create the different contents
  const contentPendingKey = (
    <div className='vinculation-view__content__form'>
      <div className='vinculation-view__content__form__title'>{plainText.message.vinculationKeyTitle}</div>
      <div className='vinculation-view__content__form__description'>
        {plainText.label.enterVinculationKey}
        <br /> {plainText.message.vinculationKeyContactAdmin}
      </div>

      <InputGroup className='vinculation-view__content__form__input'>
        <Input
          className='vinculation-view__content__form__input__box'
          type={showPassword ? 'text' : 'password'}
          placeholder={plainText.placeHolder.enterPassword}
          id='password-box'
          onChange={event => definePassword(event)}
          onCopy={event => onCopyPasteHandler(event)}
          onPaste={event => onCopyPasteHandler(event)}
          isDisabled={!!isLoading}
          isInvalid={passwordStatus === 'input-incorrect'}
        />

        <InputRightElement className='vinculation-view__content__form__input__icon' onClick={() => handleShowPassword()}>
          <Icon as={showPassword ? FiEye : FiEyeOff} className={passwordStatus} />
        </InputRightElement>
      </InputGroup>

      <div
        className='vinculation-view__content__form__password-warning'
        style={{ visibility: `${passwordStatus === 'input-incorrect' ? 'visible' : 'hidden'}` }}
      >
        {plainText.message.wrongInput}
      </div>

      <div className='vinculation-view__content__form__action'>
        {!isLoading && (
          <Button className='vinculation-view__content__form__action__cancel' onClick={backToSignin} variant='primary-text'>
            {plainText.button.cancel}
          </Button>
        )}

        <Button
          className='vinculation-view__content__form__action__button'
          loadingText={plainText.button.process}
          isLoading={isLoading}
          disabled={continueButtonStatus === 'btn-disabled'}
          onClick={() => submitPassword()}
          variant='primary'
        >
          {plainText.button.continue}
        </Button>
      </div>
    </div>
  );

  const contentBlockedByPendingKey = (
    <div className='vinculation-view__content__blocked-key'>
      <img className='vinculation-view__content__blocked-key__picture' src={blockedKey} alt='Blocked key' />
      <div className='vinculation-view__content__blocked-key__title'>{plainText.message.blockedKeyTitle}</div>
      <div className='vinculation-view__content__blocked-key__description'>{plainText.message.blockedKeyDescription}</div>
      <Button variant='primary' onClick={backToSignin}>
        {plainText.button.understood}
      </Button>
    </div>
  );

  // Check to pick the right content
  const vinculationViewContent =
    currentVinculationCodeStatus === AUTHORIZATION_STATUS.PENDING_VINCULATION_KEY ? contentPendingKey : contentBlockedByPendingKey;

  return (
    <StyledVinculationView>
      <div className='vinculation-view'>
        <SimpleBanner
          firstName={vinculationData.user.firstName || ''}
          lastName={vinculationData.user.lastName || ''}
          entityName={vinculationData.entity.name || ''}
        />

        <div className='vinculation-view__content'>{vinculationViewContent}</div>
      </div>
    </StyledVinculationView>
  );
}

export default VinculationView;
