import { useDisclosure } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, useErrorMessage, useToastOptions } from '@react-ib-mf/style-guide-ui';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Company, ProfileServiceCall } from '../../Interfaces/IDropdownProfile';
import plainText from '../../lang/es.json';
import { getBankApproval, patchBankApproval } from '../../services/api';
import ChangeEntity from './changeEntity/ChangeEntity';
import LogoutConfirmation from './logoutConfirmation';
import ProfileModal from './profileModal/ProfileModal';
import ErrorModal from './profileModal/ShareData/ErrorModal';
import StyledUserInfo, { StyledUserInfoContainer } from './userInfo/StyledUserInfo';
import UserInfo from './userInfo/UserInfo';

interface DropdownProfileProps {
  logout: () => void;
  data: ProfileServiceCall;
  companies: Array<Company>;
  onChangeEntity: (entity?: Company) => void;
}

function DropdownProfile({ logout, data, companies, onChangeEntity }: DropdownProfileProps) {
  const { isOpen: isOpenProfileModal, onOpen: openOpenProfileModal, onClose: closeOpenProfileModal } = useDisclosure();
  const { isOpen: isOpenLogoutConfirm, onOpen: openLogoutConfirm, onClose: closeLogoutConfirm } = useDisclosure();
  const { isOpen: isOpenChangeEntity, onClose: closeChangeEntity } = useDisclosure();
  const { isOpen: isOpenDropdownProfile, onClose: closeDropdownProfile, onToggle: toggleDropdownProfile } = useDisclosure();

  const [, setToastOptions] = useToastOptions();
  const { showErrorMessage } = useErrorMessage();

  const isCheckedByUser = useRef(); // temporarily stores the user approval
  const [isChecked, setIsChecked] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  // Desactivate temporaly
  // const [currentPlanName] = usePlanName();

  const menuRef = useRef(null);

  const renderDropdownBody = () => {
    // If the change entity feature is open
    if (isOpenChangeEntity && isOpenDropdownProfile) {
      return <ChangeEntity closeChangeEntity={closeChangeEntity} companies={companies} onChangeEntity={onChangeEntity} />;
    }

    // Show user info as default
    return (
      <UserInfo
        data={data}
        openLogoutModal={openLogoutConfirm}
        openProfileModal={openOpenProfileModal}
        showChangeEntity={onChangeEntity}
        toggleDropdownProfile={toggleDropdownProfile}
        showChangeCompany={companies?.length > 1}
      />
    );
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeDropdownProfile();
        closeChangeEntity();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, closeDropdownProfile, closeChangeEntity]);

  useEffect(() => {
    if (isOpenProfileModal) {
      pushAnalyticsEvent({
        event: 'impression_modal',
        content_group: 'Home',
        modal_name: 'Datos perfil de operador',
      });
    }
  }, [isOpenProfileModal]);

  useEffect(() => {
    if (isOpenLogoutConfirm) {
      pushAnalyticsEvent({
        event: 'impression_modal',
        content_group: 'Home',
        modal_name: 'Confirmacion cierre de sesion',
      });
    }
  }, [isOpenLogoutConfirm]);

  // Desactivate temporaly
  // const showShareDataApproval = data?.data?.options[1]?.description === 'Administrador General' && !!currentPlanName && !currentPlanName.includes('Gratuito');
  const showShareDataApproval = false;

  const { isLoading: loadingGetBankApproval, isError: isErrorGetBankApproval } = useQuery(
    'getBankApproval',
    () => getBankApproval().then(resp => resp?.data?.data),
    {
      enabled: showShareDataApproval,
      onError: () => showErrorMessage(),
      onSuccess: ({ approval }) => setIsChecked(approval),
    },
  );

  const { mutate: updateBankApproval, isLoading: loadingUpdateBankApproval } = useMutation(
    (value: boolean) => {
      return patchBankApproval({ approval: value });
    },
    {
      onError: () => {
        setShowErrorModal(true);
        showErrorMessage();
      },
      onSuccess: (_data, value) => {
        setShowErrorModal(false);
        setIsChecked(value);
        setToastOptions({
          status: 'success',
          description: plainText.toast.success.saveProfile,
          autoClose: true,
        });
      },
      onSettled: closeOpenProfileModal,
    },
  );

  const onCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const onSaveCheckedShareData = value => {
    isCheckedByUser.current = value;
    updateBankApproval(value);
  };

  return (
    <>
      <LogoutConfirmation isOpen={isOpenLogoutConfirm} onCancel={closeLogoutConfirm} onConfirm={logout} />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={onCloseErrorModal}
        onRetry={() => updateBankApproval(isCheckedByUser.current)}
        isLoading={loadingUpdateBankApproval}
      />
      <ProfileModal
        isOpen={isOpenProfileModal}
        onClose={closeOpenProfileModal}
        showShareData={showShareDataApproval}
        isCheckedShareData={isChecked}
        isError={isErrorGetBankApproval}
        isFetching={loadingGetBankApproval}
        isLoading={loadingUpdateBankApproval}
        setIsCheckedShareData={onSaveCheckedShareData}
      />
      <StyledUserInfoContainer>
        <StyledUserInfo id='userInfo' ref={menuRef} className={isOpenDropdownProfile ? 'active userInfo' : 'userInfo'}>
          {renderDropdownBody()}
        </StyledUserInfo>
      </StyledUserInfoContainer>
    </>
  );
}
export default DropdownProfile;
