import { Box, Button, Icon, Image, Text, Tooltip } from '@chakra-ui/react';
import {
  pushAnalyticsErrorEvent,
  pushAnalyticsEvent,
  useErrorMessage,
  useModificationsStorage,
  useToastOptions,
  // eslint-disable-next-line import/no-unresolved
} from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { FiMinus, FiPlus, FiPlusCircle } from 'react-icons/fi';
import { useMutation } from 'react-query';

import ImageFavoritos from '../../../../assets/images/no-favoritos.png';
import { FONT_DIRECT_LINKS_POPUP } from '../../../../designTokens/fonts';
import { IDirectLinksPopup, IShortcutDataPut, IShortcutListData } from '../../../../Interfaces/IDirectLinks';
import plainText from '../../../../lang/es.json';
import { putDashboardShortcuts } from '../../../../services/api';
import getShortcutIcon from '../../../../utils/getShortcutIcon';
import StyledModalContent from './StyledModalContent';

type ITemsProsSub = [string, IShortcutListData[]];

function DirectLinksPopup({ closeModal, shortcutList, setShortcutList }: IDirectLinksPopup) {
  // All the shortcut list for the popup
  const [shortcutListLocal, setShortcutListLocal] = useState<IShortcutListData[]>(shortcutList);
  // Available shortcuts by category
  const [availableShortcutList, setAvailableShortcutList] = useState<ITemsProsSub[]>([]);

  const { handleModification, aggregated, removed } = useModificationsStorage();

  const defaultStyle = {
    w: '100%',
    bg: 'grey.40',
    p: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    position: 'relative',
  };

  const iconWrap = {
    className: 'icon',
    w: '40px',
    h: '40px',
    border: '1px solid var(--chakra-colors-primary-100)',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const actionBtn = {
    minW: '0',
    w: '22px',
    h: '22px',
    p: '0',
    border: '0',
    borderRadius: '50%',
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    _hover: {
      bg: 'var(--chakra-colors-tertiary-110)',
    },
    _focus: {
      bg: 'parent',
    },
    _disabled: {
      bg: 'var(--chakra-colors-grey-60)',
      cursor: 'default',
      _hover: {
        bg: 'var(--chakra-colors-grey-60)',
      },
    },
    _active: {},
  };

  const changeSaveButtonStyle = bool => {
    const availableButtonProps = {
      borderColor: 'secondary.60',
      backgroundColor: 'secondary.60',
      cursor: 'default',
    };

    const disableButtonProps = {
      borderColor: 'primary.90',
      backgroundColor: 'primary.90',
      cursor: 'pointer',
    };

    if (bool) {
      saveButton.disabled = true;
      saveButton.style.borderColor = availableButtonProps.borderColor;
      saveButton.style.backgroundColor = availableButtonProps.backgroundColor;
      saveButton.style.cursor = availableButtonProps.cursor;
    } else {
      saveButton.disabled = false;
      saveButton.style.borderColor = disableButtonProps.borderColor;
      saveButton.style.backgroundColor = disableButtonProps.backgroundColor;
      saveButton.style.cursor = disableButtonProps.cursor;
    }
  };

  const {
    mutate,
    error: errorShortcut,
    isError: isErrorShortcut,
    isSuccess: isSuccessShortcut,
  } = useMutation(
    (newShortcutsData: IShortcutDataPut) => {
      return putDashboardShortcuts(newShortcutsData);
    },
    {
      onSuccess: () => {
        changeSaveButtonStyle(true);
        closeModalShortcuts(true);
        setShortcutList(shortcutListLocal);
      },
      onError: () => {
        changeSaveButtonStyle(true);
        setTimeout(() => {
          changeSaveButtonStyle(false);
        }, 1000);
      },
    },
  );

  const [, setToastOptions] = useToastOptions();
  const { showErrorMessage } = useErrorMessage();

  useEffect(() => {
    if (isErrorShortcut) {
      pushAnalyticsErrorEvent('home/favoritos');
      showErrorMessage({ title: plainText.toast.error.directLinksPopup, error: errorShortcut });
    } else if (isSuccessShortcut) {
      setToastOptions({
        description: plainText.toast.success.directLinksPopup,
        status: 'success',
        autoClose: true,
      });
    }
  }, [showErrorMessage, isErrorShortcut, errorShortcut, setToastOptions, isSuccessShortcut]);

  let saveButton;

  const saveItemsMenu = event => {
    pushAnalyticsEvent({
      event: 'modify_shortcuts',
      content_group: 'Home',
      accesos_agregados: aggregated.join(', '),
      accesos_quitados: removed.join(', '),
    });

    saveButton = event.currentTarget;
    const favoritesmap = shortcutListLocal.filter(shortcut => shortcut.favorito);
    const listShortcutFavorite = favoritesmap.map((shortcut, index) => ({
      idCategoria: shortcut.categoria,
      idFavorito: shortcut.idAtajo,
      orden: index,
    }));
    const newShortcutsBody = {
      tipo: 'atajo',
      favoritos: listShortcutFavorite,
    };
    changeSaveButtonStyle(true);
    mutate(newShortcutsBody);
  };

  const closeModalShortcuts = success => {
    closeModal(success);
  };

  const getAnalyticsItemId = shortcut => `${shortcut.categoria} - ${shortcut.nombreAtajo}`;

  const updateItemsMenu = (itemToChange: IShortcutListData, favorite: boolean) => {
    const analyticsItemId = getAnalyticsItemId(itemToChange);

    pushAnalyticsEvent({
      event: favorite ? 'add_shortcuts' : 'delete_shortcuts',
      content_group: 'Home',
      item_id: analyticsItemId,
    });

    handleModification(favorite, analyticsItemId);

    const data = shortcutListLocal.map(item => {
      if (item.idAtajo === itemToChange.idAtajo) {
        return { ...item, favorito: favorite };
      }
      return item;
    });
    setShortcutListLocal(data);
  };

  const onCancel = event => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Editar accesos directos',
      item_id: 'Cancelar',
    });
    closeModalShortcuts(event);
  };

  useEffect(() => {
    const availableShortcuts = shortcutListLocal.filter(shortcut => !shortcut.favorito);

    const tempResult = {};

    const result = availableShortcuts.reduce((_, shortcut) => {
      tempResult[shortcut.categoria] = tempResult[shortcut.categoria] || [];
      tempResult[shortcut.categoria].push(shortcut);
      return tempResult;
    }, tempResult);

    setAvailableShortcutList(Object.entries(result));
  }, [shortcutListLocal]);

  const favorites = shortcutListLocal.filter(shortcut => shortcut.favorito);
  return (
    <StyledModalContent>
      <div className='shortcuts pinned'>
        <div className='grid-items four-cols'>
          {favorites &&
            favorites?.map(item => (
              <Box sx={defaultStyle} key={item.idAtajo}>
                <Box sx={iconWrap}>
                  <Image w='42px' src={getShortcutIcon(item.categoria)} />
                </Box>
                <Text
                  fontSize={FONT_DIRECT_LINKS_POPUP.fontSizeTextMinus}
                  lineHeight={FONT_DIRECT_LINKS_POPUP.lineHeight}
                  color='neutral.130'
                  margin='0'
                >
                  {item.categoria}
                  <span className='bolderFont' style={{ fontFamily: FONT_DIRECT_LINKS_POPUP.fontFamily }}>
                    {item.nombreAtajo}
                  </span>
                </Text>
                {favorites && favorites.length > 1 && (
                  <Button
                    data-testid={`update-minus-button-${item.idAtajo}`}
                    sx={actionBtn}
                    background='danger.100'
                    onClick={() => updateItemsMenu(item, false)}
                    height='22px'
                  >
                    <Icon as={FiMinus} color='white' />
                  </Button>
                )}
              </Box>
            ))}
          {favorites && favorites.length < 4 && (
            <Box sx={defaultStyle} border='solid 1px var(--chakra-colors-primary-60)' display='flex' justifyContent='center'>
              <Icon as={FiPlusCircle} color='primary.100' />
            </Box>
          )}
        </div>
      </div>

      <div className='shortcuts'>
        <div className='filter'>
          <h3 style={{ fontFamily: FONT_DIRECT_LINKS_POPUP.fontFamily }}> {plainText.label.availableDirectLinks} </h3>
          <p>{plainText.label.selectDirectLinks}</p>
        </div>
        {availableShortcutList?.length > 0 && (
          <div className='contentItems'>
            {availableShortcutList?.map(item => {
              return (
                <div key={item[0].toString()}>
                  <div className='subTitleBold' style={{ fontFamily: FONT_DIRECT_LINKS_POPUP.fontFamily }}>
                    {item[0]}
                  </div>
                  <div className='grid-items'>
                    {item[1].map(i => (
                      <Box sx={defaultStyle} key={i.categoria}>
                        <Box sx={iconWrap}>
                          <Image w='42px' src={getShortcutIcon(i.categoria)} />
                        </Box>

                        <Text
                          fontSize={FONT_DIRECT_LINKS_POPUP.fontSizeTextPlus}
                          fontFamily={FONT_DIRECT_LINKS_POPUP.fontFamily}
                          lineHeight={FONT_DIRECT_LINKS_POPUP.lineHeight}
                          color='neutral.130'
                          margin='0'
                        >
                          {i.nombreAtajo.length > 15 ? (
                            <Tooltip label={i.nombreAtajo} hasArrow placement='top'>
                              <strong>{`${i.nombreAtajo.substring(0, 15)}...`}</strong>
                            </Tooltip>
                          ) : (
                            <strong>{i.nombreAtajo}</strong>
                          )}
                        </Text>
                        <Button
                          disabled={favorites && favorites.length >= 4}
                          data-testid={`update-plus-button-${i.idAtajo}`}
                          sx={actionBtn}
                          background='success.100'
                          onClick={() => updateItemsMenu(i, true)}
                          height='22px'
                        >
                          <Icon as={FiPlus} color='white' />
                        </Button>
                      </Box>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {availableShortcutList.length === 0 && (
          <div className='messageFavoritos'>
            <img src={ImageFavoritos} alt='Sin permisos' />
            <span>
              {plainText.message.noDirectLinks} <br /> {plainText.message.noPermissionsToEdit}
            </span>
          </div>
        )}
      </div>

      <div className='btns'>
        <Button data-testid='btn-cancel' variant='primary-text' className='cancel' onClick={onCancel}>
          {plainText.button.cancel}
        </Button>
        <Button data-testid='btn-saveChanges' variant='primary' onClick={saveItemsMenu}>
          {plainText.button.saveChanges}
        </Button>
      </div>
    </StyledModalContent>
  );
}

export default DirectLinksPopup;
