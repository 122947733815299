import { Button, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import styled from 'styled-components';

import chatIcon from '../../assets/images/icons/chat.svg';
import plainText from '../../lang/es.json';

const StyledChatbotContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  > button {
    background-color: transparent;
    width: 100%;
    min-width: 40px;
    padding: 0 12px 0 0;
    height: 100%;
    gap: 16px;
    justify-content: start;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    color: var(--chakra-colors-tertiary-40);

    p {
      color: var(--chakra-colors-tertiary-40);
    }

    &:focus {
      box-shadow: none;
      background: rgba(255, 255, 255, 0.08);
      border-color: rgba(255, 255, 255, 0.16);
    }

    &:hover {
      background: rgba(255, 255, 255, 0.08);
      border-color: rgba(255, 255, 255, 0.16);
      color: var(--chakra-colors-tertiary-50);
    }
    &:hover p {
      color: var(--chakra-colors-tertiary-50);
    }

    &:active {
      background: rgba(255, 255, 255, 0.08);
      border-color: rgba(255, 255, 255, 0.16);
      color: var(--chakra-colors-tertiary-60);
    }
    &:active p {
      color: var(--chakra-colors-tertiary-60);
    }

    > div {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 0;
      margin: 0;
      border-radius: 16px;
      height: 100%;
      width: 40px;
      min-width: 40px;
      background-color: #f7edff;
    }

    > svg {
      margin-left: auto;
    }
  }
`;

interface ChatbotButtonProps {
  onClick: () => void;
}

function ChatbotButton({ onClick }: ChatbotButtonProps) {
  return (
    <StyledChatbotContainer>
      <Button onClick={onClick}>
        <div>
          <Image src={chatIcon} />
        </div>

        <Text textStyle='labelMd' className='firstLevelItem'>
          {plainText.label.chatbot}
        </Text>

        <FiChevronRight />
      </Button>
    </StyledChatbotContainer>
  );
}

export default ChatbotButton;
