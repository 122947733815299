import { createGlobalStyle } from 'styled-components';

import imageHeaderBg from '../../assets/images/header/bg.png';

const WizardPopupGlobalStyles = createGlobalStyle`
	.toolTipBox {
		z-index: 160;

		&.triangle {
			padding: 0px 15px 20px 15px;
			
			&:after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 11px 8px 11px;
				border-color: transparent transparent var(--chakra-colors-grey-120) transparent;

				position: absolute;
				top: -7px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.bnt-close-wizard {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		margin-right: 3px;

		&:hover {
			background-color: var(--chakra-colors-secondary-100);
			color: var(--chakra-colors-primary-100)
		}
	}

	.actions-btn {
		display: flex;
		width: 100%;

		&__left {
			margin-right: auto;
			margin-left: 5px;
		}

		&__right {
			margin-right: 5px;
			margin-left: auto;
		}

		& .custom_btn {
			cursor: pointer;
			width: 40px;
			height: 40px;
			border: 0;
			color: var(--Tertiary-110, var(--Color-Tertiary-110, #3B0092));
			background: var(--Tertiary-40, #EBDDFF);
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
      height: 40px;
      padding: 0px;
      min-width: 40px;

			& .chakra-button__icon {
        margin: 0px;
        & svg {
          height: 24px;
          width: 24px;
        }
      }
	
			&:hover {
				color: var(--Tertiary-100, var(--Color-Tertiary-100, #5B31B2));
				background: var(--Tertiary-50, #D2BCFF);
			}
		}
	}

  body {
    margin:0;
		padding: 0;
		.headerBg {
			min-height: 95px;
		}
    &.header_fixed {
    .headerBg {
			background-image: url(${imageHeaderBg});
			background-position: center;
			background-size: cover;
			top: 0;
			left: 0;
			z-index: 150;
			width: 100%;
			min-height: 102px;
    }
    .hdr {
      min-height: 102px;
    }
    .welcome_user {
      display: none;
    }
    .header_right {
      padding-top: 0;
    }
  }

	&.wizard_active {
		overflow: auto;
		&:after {
			content: '';
			display: block;
			background-color: #2c2c2c6f;
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 151;
			overflow: hidden;
		}

		& .toolTipBox {
			display:flex
		}
	}

	&:not(.wizard_active) {
		.headerBg {
			position: sticky;
			z-index: 10;
		}

		& .toolTipBox {
			display:none
		}
	}

	&.step1 {
		.userInfo {
			z-index: 152;
			pointer-events: none;  
		}
	
		.toolTipBox1 {
		}
	}

	&.step2 { 
		.sidePanel {
			z-index: 152;
			pointer-events: none;
		}
	
		.toolTipBox2 {
			position: fixed;
			&.triangle {
				&:after {
					top: 50%;
					left: -14px;
					transform: translateY(-50%) rotate(-90deg); 
				}
			}
		} 
	}

	&.step3 { 
		#directLinkPending {
			z-index: 152;
			pointer-events: none;  
			background-color: white;
			border-radius: 20px;
		}
	
		.toolTipBox3 {
			position: fixed;
		} 
	}

	&.step4 { 
		.directLinks {
			z-index: 152;
			pointer-events: none;  
			ul {
				background-color: white;
			}
		}
	
		.toolTipBox4 {
			position: fixed;
		} 
	}

	&.step5 { 
		.bankLogo {
			z-index: 153;
		}
		.card_boxes {
			z-index: 152;
			pointer-events: none;  
			position: relative;
		}
		.card_boxes .alice-carousel__wrapper{
			z-index: 152;
			pointer-events: none;  
			position: relative;
		}

		.card_boxes .account_box{
			z-index: 152;
			pointer-events: none;  
			position: relative;
		}
	
		.toolTipBox5 {
			position: fixed;
			margin-top: -12px;

			&.triangle {
				&:after {
					top: auto;
					bottom: -7px;
					transform: translateX(-50%) rotate(-180deg);
				}
			} 
		} 
	}

	&.step6 { 
		.chart_box {
			z-index: 152;
			pointer-events: none;
			transition: none; 
			position: relative;
		}

		.toolTipBox6 {
			position: fixed;

			&.triangle {
				&:after {
					top: auto;
					bottom: -7px;
					transform: translateX(-50%) rotate(-180deg);
				}
			} 
		} 
	}

	&.step7 { 

		.sidePanel {
			z-index: 152;
			pointer-events: none;
		}

		.toolTipBox7 {
			position: fixed;
			&.triangle {
				&:after {
					top: 82%;
					left: -14px;
					transform: translateY(-50%) rotate(-90deg); 
				}
			} 
		} 
    }

	&.step8 { 

		#help {
			z-index: 152;
			pointer-events: none;
			background-color: white;
			border-radius: 25%;
		}
		
		.toolTipBox8 {
			margin-top: 12px;
			position: fixed;
		} 
    }
}
 `;

export default WizardPopupGlobalStyles;
