import {
  Button,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import errorCrossIcon from '../../../../assets/images/icons/errorCross.svg';
import plainText from '../../../../lang/es.json';

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRetry: () => void;
  isLoading: boolean;
}

function ErrorModal({ isOpen, onClose, onRetry, isLoading }: ErrorModalProps) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent borderRadius='12px'>
        <ModalHeader
          marginTop='40px'
          paddingBottom='8px'
          display='flex'
          flexDir='column'
          alignItems='center'
          alignSelf='stretch'
          gap='16px'
        >
          <Img src={errorCrossIcon} alt='x' />
          <Text textStyle='h3' margin='0'>
            {plainText.label.editProfileError}
          </Text>
        </ModalHeader>
        <ModalCloseButton border='0' backgroundColor='transparent' cursor='pointer' color='primary.100' _focus={{ boxShadow: 'none' }} />
        <ModalBody>
          <Text textStyle='bodyMd' textAlign='center' margin='0'>
            {plainText.updateProfile.yourSelection}
            <Text as='span' textStyle='bodyMdSemi'>
              {plainText.updateProfile.shareBalance}
            </Text>
            {plainText.updateProfile.notSaved}
          </Text>
          <Text textStyle='bodyMd' textAlign='center' margin='0'>
            {plainText.message.retryLater}
          </Text>
        </ModalBody>
        <ModalFooter display='flex' flexDir='column' gap='10px' marginBottom='20px'>
          <Button loadingText={plainText.button.process} w='328px' isLoading={isLoading} onClick={onRetry}>
            {plainText.button.retry}
          </Button>
          <Button w='328px' variant='primary-outline' onClick={onClose}>
            {plainText.button.anotherTime}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ErrorModal;
