import { Box, Button, Text } from '@chakra-ui/react';

import { FONT_TYC_DESCRIPTION } from '../../designTokens/fonts';
import plainText from '../../lang/es.json';
import cleanString from '../../utils/stringModifier';
import PDFViewer from './PDFViewer';

interface TermsAndConditionsProps {
  onChangeAcceptance: (accept: boolean) => void;
  showLaterButton?: boolean;
  isLoading?: boolean;
  title: string;
  description: string;
  document: number[] | string;
}

function TermsAndConditions({
  onChangeAcceptance,
  showLaterButton = true,
  isLoading = false,
  title,
  description,
  document,
}: TermsAndConditionsProps) {
  return (
    <Box mx='70px'>
      <Text textStyle='h3'>{cleanString(title)}</Text>
      <Box
        mt={20}
        display='flex'
        alignItems={{ base: 'center', '2xl': 'initial' }}
        flexDirection={{ base: 'column', '2xl': 'row' }}
        gap='10'
      >
        <Text
          fontFamily={FONT_TYC_DESCRIPTION.fontFamily}
          fontSize={FONT_TYC_DESCRIPTION.fontSize}
          fontWeight={FONT_TYC_DESCRIPTION.fontWeight}
          m={0}
        >
          {description}
        </Text>
        <Box>
          <PDFViewer document={document} />
        </Box>
      </Box>
      <Box display='flex' justifyContent='flex-end' marginTop={6} gap={8}>
        {showLaterButton && !isLoading && (
          <Button onClick={() => onChangeAcceptance(false)} variant='primary-text'>
            {plainText.button.anotherTime}
          </Button>
        )}
        <Button
          loadingText={plainText.button.process}
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => onChangeAcceptance(true)}
          variant='primary'
        >
          {plainText.button.accept}
        </Button>
      </Box>
    </Box>
  );
}

export default TermsAndConditions;
