import styled from 'styled-components';

import HeaderLeft from '../../assets/images/header-vector-left.png';
import HeaderRight from '../../assets/images/header-vector-right.png';
import LogoIB from '../../assets/images/horizontal-logo.png';
import LogoMobileIB from '../../assets/images/logotipo.png';

const StyledMigrationInfoView = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0px;
  }
  .grid-token {
    max-width: 1240px;
  }
  .migration-info-view {
    &__header {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      min-height: 96px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      background:
        url(${HeaderLeft}) left no-repeat,
        url(${HeaderRight}) right no-repeat;
      background-color: #22005d;

      &__logo {
        content: url(${LogoIB});
      }
    }

    &__title {
      margin-top: 113px;
      margin-bottom: 8px;
    }

    &__description {
      width: 80%;
      text-align: center;
      margin-bottom: 10px;
    }

    &__question {
      color: #0381a8;
      font-weight: 600;
    }

    &__subtitle {
      margin-bottom: 32px;
    }

    &__qr-wrapper {
      display: inline-flex;
      padding: 8px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      border-radius: 24px;
      /* Shadow/md */
      box-shadow:
        0px 4px 6px -1px rgba(19, 28, 44, 0.1),
        0px 2px 4px -1px rgba(19, 28, 44, 0.06);
      margin-bottom: 28px;
    }

    &__qr-banner {
      display: flex;
      align-items: center;
      gap: 20px;

      & img {
        width: 100px;
      }
    }

    &__disclaimer {
      margin-bottom: 100px;
    }

    &__step-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
      column-gap: 48px;
      row-gap: 32px;
      margin: 0px 170px 70px 170px;
      &__step {
        display: flex;
        flex-direction: column;
        min-width: 340px;
      }

      &__picture {
        display: flex;
        justify-content: center;

        & img {
          width: 250px;
          height: 250px;
        }

        & img.img-step1-token {
          width: 230px;
          height: 230px;
        }

        & img.img-step2-token {
          width: 148px;
          height: 300px;
        }
      }
    }

    &__footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 72px;
      background-color: white;
      box-shadow: 0px 30px 60px black;
      display: flex;
      justify-content: end;
      align-items: center;

      & button {
        margin-right: 50px;
      }
    }
  }

  @media (max-width: 1250px) {
    .migration-info-view__step-grid {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, auto);
    }

    .grid-item-1 {
      order: 1;
    }
    .grid-item-4 {
      order: 2;
    }
    .grid-item-2 {
      order: 3;
    }
    .grid-item-5 {
      order: 4;
    }
    .grid-item-3 {
      order: 5;
    }
    .grid-item-6 {
      order: 6;
    }

    .migration-info-view__header {
      height: 40px;

      &__logo {
        content: url(${LogoMobileIB});
      }
    }
  }
`;

export default StyledMigrationInfoView;
