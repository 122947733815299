import styled, { createGlobalStyle } from 'styled-components';

import {
  FONT_DIRECT_LINKS_SHORTCUT,
  FONT_DIRECT_LINKS_SHORTCUT_CATEGORY,
  FONT_DIRECT_LINKS_TEXT,
  FONT_DIRECT_LINKS_TITLE,
} from '../../../../designTokens/fonts';

const StyledDivButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-left: 128px;
`;

const StyledDirectLinks = styled.div`
  padding-right: 40px;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  width: 100%;

  h2 {
    font-style: ${FONT_DIRECT_LINKS_TITLE.fontStyle};
    font-size: ${FONT_DIRECT_LINKS_TITLE.fontSize};
    font-weight: ${FONT_DIRECT_LINKS_TITLE.fontWeight};
    color: var(--chakra-colors-tertiary-110);
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 12px 17px;
    transition: all 0.3s ease-in-out;
    flex: 1 0 0;
    gap: 40px;

    li {
      margin-bottom: 20px;
      width: 195px;
      height: 40px;
      a,
      button {
        display: flex;
        align-items: center;
        gap: 12px;

        text-align: left;
        transition: all 0.3s ease-in-out;
        span {
          font-weight: normal;
        }
      }
      a {
        text-decoration: none;
      }
      button {
        border: 0;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
      }
      .custom-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border-width: 10px;
        // border: 1px solid var(--chakra-colors-primary-100);
        border: 1px solid #2172dc;
        height: 40px;
        width: 40px;

        & img {
          padding: 5px;
        }
      }
      .shortcut-text {
        font-family: ${FONT_DIRECT_LINKS_SHORTCUT.fontFamily};
        font-size: 11px;
        font-weight: ${FONT_DIRECT_LINKS_SHORTCUT.fontWeight};
        color: var(--chakra-colors-neutral-130);

        &__category {
          font-family: ${FONT_DIRECT_LINKS_SHORTCUT_CATEGORY.fontFamily};
          font-size: 12px;
          font-weight: ${FONT_DIRECT_LINKS_SHORTCUT_CATEGORY.fontWeight};
          color: var(--chakra-colors-neutral-130);
        }
      }

      strong {
        font-style: ${FONT_DIRECT_LINKS_TEXT.fontStyle};
        font-size: ${FONT_DIRECT_LINKS_TEXT.fontSize};
        font-weight: ${FONT_DIRECT_LINKS_TEXT.fontWeight};
        line-height: ${FONT_DIRECT_LINKS_TEXT.lineHeight};
        color: var(--chakra-colors-neutral-110);
        span {
          display: block;
        }
      }
    }

    .edit {
      button {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        &:hover {
          background-color: var(--chakra-colors-primary-60);
        }
        &:focus {
          background-color: transparent;
        }
      }
    }
  }

  &.fixed {
    left: 0;
    z-index: 150;
    max-width: fit-content;
    padding-bottom: 18px;
    margin-top: -80px;

    ul {
      gap: 48px;
      background: white;
      flex-wrap: inherit;
    }
    li {
      width: auto;
    }
    p {
      display: none;
    }
    .edit {
      display: none;
    }
  }

  @media (min-width: 768px) {
    ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;

      li {
        margin-bottom: 0;
        &:last-child {
        }
      }

      .edit {
        margin-left: auto;
        max-width: fit-content;
        position: absolute;
        right: 0;
      }
    }
  }

  @media (max-width: 1342px) {
    ul {
      li {
        width: 40px;
        gap: 40px;
        .shortcut-text {
          display: none;
        }
      }
      .edit {
        margin-left: auto;
        max-width: fit-content;
        position: absolute;
        right: 10px;
      }
    }
  }
`;

const StyledDirectLinksGlobalStyles = createGlobalStyle`
 .closeModalMenus{
   background-color: white;
   border:0;
   cursor:pointer;
   &:focus{
    box-shadow: none!important;
   }
 }
`;

const StyledDirectLinksPending = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 208px;
  height: 65px;
  top: 0;
  a {
    text-decoration: none;
  }
  .directLinkPending {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 12px 17px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 208px;

    .custom-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2172dc;
      border-radius: 8px;
      height: 40px;
      width: 40px;

      & img {
        padding: 5px;
      }
    }
    .shortcut-text {
      font-family: ${FONT_DIRECT_LINKS_SHORTCUT.fontFamily};
      font-size: 11px;
      font-weight: ${FONT_DIRECT_LINKS_SHORTCUT.fontWeight};
      color: var(--chakra-colors-neutral-130);

      &__category {
        font-family: ${FONT_DIRECT_LINKS_SHORTCUT_CATEGORY.fontFamily};
        font-size: 12px;
        font-weight: ${FONT_DIRECT_LINKS_SHORTCUT_CATEGORY.fontWeight};
        color: var(--chakra-colors-neutral-130);
      }
    }

    strong {
      font-style: ${FONT_DIRECT_LINKS_TEXT.fontStyle};
      font-size: ${FONT_DIRECT_LINKS_TEXT.fontSize};
      font-weight: ${FONT_DIRECT_LINKS_TEXT.fontWeight};
      line-height: ${FONT_DIRECT_LINKS_TEXT.lineHeight};
      color: var(--chakra-colors-neutral-110);
      span {
        display: block;
      }
    }
  }

  &.fixed {
    left: 0;
    z-index: 150;
    max-width: fit-content;
    padding-bottom: 18px;
    margin-top: -72px;

    .directLinkPending {
      background: white;
      flex-wrap: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 12px;
      width: 64px;
    }
    p {
      display: none;
    }
    .edit {
      display: none;
    }
  }
`;

export { StyledDirectLinks, StyledDirectLinksGlobalStyles, StyledDirectLinksPending, StyledDivButtons };
