import { useEffect } from 'react';

interface UseHighlightMatchesProps {
  searchValue: string;
  selectors: string;
}

const useHighlightMatches = ({ searchValue, selectors }: UseHighlightMatchesProps) => {
  useEffect(() => {
    const regex = new RegExp(searchValue, 'gi');
    document.querySelectorAll(selectors).forEach(option => {
      const textOption = option.innerHTML.replace(/(<b>|<\/b>)/gim, '');
      const newTextOption = textOption.replace(regex, '<b>$&</b>');
      // eslint-disable-next-line no-param-reassign
      option.innerHTML = newTextOption;
    });
  }, [searchValue, selectors]);
};

export default useHighlightMatches;
