import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';

import { FONT_DIRECT_LINKS_MODAL } from '../../../../designTokens/fonts';
import { IShortcutListData } from '../../../../Interfaces/IDirectLinks';
import plainText from '../../../../lang/es.json';
import DirectLinksPopup from '../directLinksPopup';

interface IBDirectLinks {
  shortcutList: IShortcutListData[];
  onClose: () => void;
  isOpen: boolean;
  CloseModal: () => void;
  setShortcutList: React.Dispatch<React.SetStateAction<IShortcutListData[]>>;
}

function DirectLinksModal({ shortcutList, onClose, isOpen, CloseModal, setShortcutList }: IBDirectLinks): JSX.Element {
  const pushCloseModal = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Home',
      content_type: 'Editar accesos directos',
      item_id: 'Cerrar',
    });
  };

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset='slideInBottom' size='6xl'>
      <ModalOverlay />
      <ModalContent p='5'>
        <ModalHeader
          style={{
            display: 'block',
            textAlign: 'center',
            fontFamily: FONT_DIRECT_LINKS_MODAL.fontFamily,
            fontWeight: FONT_DIRECT_LINKS_MODAL.fontWeight,
          }}
        >
          {plainText.message.editDirectLinks}
        </ModalHeader>
        <ModalCloseButton className='closeModalMenus' onClick={pushCloseModal} />
        <ModalBody>
          <DirectLinksPopup closeModal={CloseModal} shortcutList={shortcutList} setShortcutList={setShortcutList} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DirectLinksModal;
