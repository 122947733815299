import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line import/no-unresolved
import { useToastOptions } from '@react-ib-mf/style-guide-ui';
import { ToastIsolated } from '@wow/toast';
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

function Toast() {
  const [toastOptions] = useToastOptions();
  const { position, containerStyle } = toastOptions;
  useEffect(() => {
    if (toastOptions?.status !== undefined) {
      toast(({ closeToast }) => (
        <ToastIsolated
          id={toastOptions.id || Date.now()}
          onClose={closeToast}
          title={toastOptions.title}
          status={toastOptions.status}
          description={toastOptions.description}
        />
      ));
    }
  }, [toastOptions]);

  return (
    <StyledContainer
      position={position !== undefined ? position : 'bottom-left'}
      autoClose={5000}
      hideProgressBar
      closeButton={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      style={{ width: 'fit-content', marginLeft: '102px', ...containerStyle }}
    />
  );
}

export default Toast;

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast {
    padding: 0;
    min-height: unset;
    background-color: unset;
    box-shadow: unset;

    .Toastify__toast-body {
      padding: 0;

      [class^='toast-'] {
        width: max-content;

        .icon {
          display: flex;
        }
      }
    }
    button[aria-label='Close'] {
      color: #fff;
    }
  }
`;
