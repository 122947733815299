import { useDisclosure } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useEffect, useState } from 'react';

import { IShortcutList, IShortcutListData } from '../../../../Interfaces/IDirectLinks';
import DirectLinksDefault from './DirectLinksDefault';
import DirectLinksModal from './DirectLinksModal';

interface DirectLinksContentProps {
  status?: boolean;
  toolTip?: boolean;
  data: IShortcutList;
  isLoading: boolean;
}

function DirectLinksContent({ status, toolTip, data, isLoading }: DirectLinksContentProps): JSX.Element {
  const [shortcutList, setShortcutList] = useState<IShortcutListData[]>();

  useEffect(() => {
    if (!isLoading) {
      setShortcutList(data?.data);
    }
  }, [data, isLoading]);

  const MAvailables = shortcutList && shortcutList?.filter(item => item.favorito === true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const CloseModal = () => {
    onClose();
  };

  return (
    <>
      <DirectLinksDefault
        datos={{ status, toolTip }}
        MAvailables={MAvailables}
        shortcutList={shortcutList}
        onOpen={onOpen}
        isLoading={isLoading}
      />
      <DirectLinksModal
        shortcutList={shortcutList}
        onClose={onClose}
        isOpen={isOpen}
        CloseModal={CloseModal}
        setShortcutList={setShortcutList}
      />
    </>
  );
}

export default DirectLinksContent;
