import { Icon, Tooltip } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@react-ib-mf/style-guide-ui';
import { FiYoutube } from 'react-icons/fi';
import styled from 'styled-components';

function VideoHelp() {
  const videoHelpLink = 'https://www.youtube.com/playlist?list=PLRMpBLIlvK1fkBITMqFc2nzh9pNog1tHg';
  const tooltipText = 'Mirá los tutoriales en nuestro canal de YouTube';
  const customToolTip = {
    width: '234px',
  };

  return (
    <OpenLink url={videoHelpLink} openMode='extApplication'>
      <Tooltip hasArrow label={tooltipText} sx={customToolTip}>
        <StyledVideoHelpButton className='video-help'>
          <Icon as={FiYoutube} w={7} h={7} />
        </StyledVideoHelpButton>
      </Tooltip>
    </OpenLink>
  );
}

export default VideoHelp;

const StyledVideoHelpButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  cursor: pointer;

  svg {
    // color: var(--chakra-colors-primary-100);
    color: #2172dc;
  }
`;
