export const DEFAULT_COOKIE_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;

export const getCookies = (): string => document.cookie;

export const getCookie = (name: string) => {
  return `; ${getCookies()}`.split(`; ${name}=`).pop()?.split(';')[0];
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const setCookie = (name: string, value: string, domain: string = DEFAULT_COOKIE_DOMAIN) => {
  const domainvariable = `;domain=${domain}`;
  document.cookie = `${name}=${value}${domain ? domainvariable : ''};path=/`;
};
