import { Avatar } from '@chakra-ui/react';

import logotipo from '../../assets/images/logotipo.svg';
import { getAvatarName } from '../../utils/names';
import StyledSimpleBanner from './styled';

interface SimpleBannerProps {
  firstName?: string;
  lastName?: string;
  entityName?: string;
}

function SimpleBanner({ firstName, lastName, entityName }: SimpleBannerProps) {
  const fullName = `${firstName} ${lastName}`;

  return (
    <StyledSimpleBanner>
      <div className='simple-banner'>
        <img className='simple-banner__logo' src={logotipo} alt='Interbanking Logo' />

        <div className='simple-banner__profile'>
          <div className='simple-banner__profile__avatar'>
            <Avatar name={getAvatarName(firstName, lastName)} />
          </div>

          <div className='simple-banner__profile__data'>
            <div className='simple-banner__profile__data__name'>{fullName}</div>
            <div className='simple-banner__profile__data__entity-name'>{entityName}</div>
          </div>
        </div>
      </div>
    </StyledSimpleBanner>
  );
}

export default SimpleBanner;
