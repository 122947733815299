/**
 * Hook used to keep the Sib session alive when using P2
 */

import { useEffect } from 'react';
import { useIdleTimer as idleTimer } from 'react-idle-timer';

import { storeLastActivity } from '../utils/sibSession';

const useKeepSibAlive = () => {
  useEffect(() => {
    storeLastActivity();
  }, []);

  idleTimer({
    onAction: storeLastActivity,
    events: ['keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove'],
    throttle: 60000,
    crossTab: true,
    name: 'sib-alive-timer',
    syncTimers: 1,
    leaderElection: false,
  });
};

export default useKeepSibAlive;
