/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Icon } from '@chakra-ui/icon';
import { Box, Text } from '@chakra-ui/layout';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import React, { ChangeEvent, useState } from 'react';
import { FiArrowLeft, FiCheck, FiSearch, FiX } from 'react-icons/fi';

import useHighlightMatches from '../../../hooks/useHighlightMatches';
import { Company } from '../../../Interfaces/IDropdownProfile';
import plainText from '../../../lang/es.json';
import StyledChangeEntity from './StyledChangeEntity';

interface ChangeEntityProps {
  companies: Array<Company>;
  closeChangeEntity: () => void;
  onChangeEntity: (entity: Company) => void;
}

function ChangeEntity({ companies, closeChangeEntity, onChangeEntity }: ChangeEntityProps) {
  const [searchValue, setSearchValue] = useState('');

  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useHighlightMatches({ searchValue, selectors: '.entityName' });

  const renderEntities = () => {
    // Companies that match with the search value
    const value = searchValue.toLowerCase();
    const companiesToRender = companies?.filter(({ label, operatorId }) => operatorId && label.toLowerCase().includes(value));

    if (!companiesToRender?.length) {
      return (
        <Text mx='15px' data-testid='lbl-noResults'>
          {plainText.message.noResultsSearch} “<b>{searchValue}</b>”
        </Text>
      );
    }

    const onClick = (entity: Company) => () => {
      pushAnalyticsEvent({
        event: 'change_company',
        content_group: 'Home',
        item_id: 'Selecciona empresa',
      });
      !entity.checked && onChangeEntity(entity);
    };

    return companiesToRender?.map(({ label, checked, operatorId, entityId, companyStatus }) => (
      <Box
        as='li'
        key={operatorId}
        onClick={onClick({ label, checked, operatorId, entityId, companyStatus })}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        className={`link ${checked ? 'selected' : ''}`}
        data-testid={`item-entity-${operatorId}`}
      >
        <p className='entityName'>{label}</p>
        {checked && <Icon data-testid='icon-selectedEntity' as={FiCheck} w={8} h={6} />}
      </Box>
    ));
  };
  const closeChangeEntityWithAnalitycs = () => {
    pushAnalyticsEvent({
      event: 'change_company',
      content_group: 'Home',
      item_id: 'Volver',
    });
    closeChangeEntity();
  };
  return (
    <StyledChangeEntity>
      <Box>
        <Box
          onClick={closeChangeEntityWithAnalitycs}
          display='flex'
          className='link'
          alignItems='center'
          pl='15px'
          mb={2}
          data-testid='btn-closeChangeEntity'
        >
          <Icon as={FiArrowLeft} w={8} h={6} />
          <h3>{plainText.button.changeEntity}</h3>
        </Box>
        {companies.length > 5 && (
          <InputGroup px='10px'>
            <InputLeftElement mx='10px'>
              <Icon as={FiSearch} w={8} h={6} />
            </InputLeftElement>
            <Input
              placeholder={plainText.placeHolder.search}
              onChange={onChangeSearchValue}
              value={searchValue}
              data-testid='input-searchEntity'
            />
            {!!searchValue?.length && (
              <InputRightElement mx='10px'>
                <Icon data-testid='btn-clearSearch' as={FiX} onClick={() => setSearchValue('')} w={8} h={6} color='primary.100' />
              </InputRightElement>
            )}
          </InputGroup>
        )}
        <Box className='entityList' as='ul' maxH='350px' overflowY='auto' overflowX='hidden'>
          {renderEntities()}
        </Box>
      </Box>
    </StyledChangeEntity>
  );
}

export default ChangeEntity;
