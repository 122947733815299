// eslint-disable-next-line import/no-unresolved
import { getOidcStoraged } from '@react-ib-mf/style-guide-ui';

import useAivo from './useAivo';

const closedMenuChatbotStyles = `.aivochat-container {
  left: 76.5px !important;
  bottom: 0px !important;
  top: auto !important;
}`;
const openedMenuChatbotStyles = `.aivochat-container {
  left: 325.5px !important;
  bottom: 0px !important;
  top: auto !important;
}`;

const oidc = getOidcStoraged();
const user = { name: oidc?.profile?.nombre, lastname: oidc?.profile?.apellido };

const useInMenuAivo = () => {
  const { aivo, updateStyles } = useAivo({
    src: process.env.AIVO_SRC,
    token: process.env.AIVO_TOKEN,
    user,
    styles: closedMenuChatbotStyles,
  });

  const onClose = () => updateStyles(closedMenuChatbotStyles);

  const onOpen = () => updateStyles(openedMenuChatbotStyles);

  return { aivo, onClose, onOpen };
};

export default useInMenuAivo;
