import styled from 'styled-components';

import { FONT_USER_INFO_BUTTON, FONT_USER_INFO_ITEM, FONT_USER_INFO_MORE_OPTIONS, FONT_USER_INFO_NAME } from '../../../designTokens/fonts';

export const StyledUserInfoContainer = styled.div`
  height: 68px;
`;

const StyledUserInfo = styled.div`
  padding: 10px 0px;

  background: rgba(255, 255, 255, 48%);
  border: 1px solid white;
  backdrop-filter: blur(10px);
  width: 269px;
  border-radius: 12px;

  position: relative;
  z-index: 150;

  &.active {
    background-color: white;
    box-shadow: 0px 10px 15px -3px #131c2c1a;
    padding-bottom: 22px;

    .more-options {
      transform: rotate(180deg);
    }

    .dropdown {
      display: block;
      position: relative;
      top: 10px;

      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
  .profile_avatar {
    height: 40px;
    width: 40px;
  }

  .head_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: var(--chakra-colors-secondary-130);
    padding: 0 40px 0 16px;
    cursor: pointer;
  }

  & .chakra-avatar {
    border-color: transparent;
    background-color: #2172dc;
    // background-color: var(--chakra-colors-primary-100);
    color: white;
    &__initials {
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.1px;
      text-align: center;
    }
  }

  .name {
    margin-left: 8px;
    flex: 1;
    strong {
      white-space: nowrap;
      font-family: Inter;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding-bottom: 2px;
      font-size: ${FONT_USER_INFO_NAME.fontSize};
      width: 137px;
      color: #2f3034;
    }
    small {
      font-family: Inter;
      white-space: normal;
      width: 137px;
      height: auto;
      font-size: 14px;
      color: #2f3034;
      margin-top: -3px;
      margin-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      line-height: 20px;
    }
  }

  .more-options {
    background-color: transparent;
    border: 0;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    padding: 1px 24px;
    // color: var(--chakra-colors-primary-100);
    color: #2172dc;

    right: 0;
    &:hover,
    :active,
    :focus {
      background-color: transparent;
      color: var(--chakra-colors-primary-100);
    }
    &:hover {
      color: var(--chakra-colors-primary-100);
    }
  }

  .more-options svg {
    width: 20px;
    height: 20px;
  }
`;

export const MoreOptions = styled.div`
  width: 100%;
  display: none;

  .btn-default-active {
    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    padding: 12px 18px;
    margin: 0;
    color: var(--chakra-colors-tertiary-110);
    font-size: ${FONT_USER_INFO_MORE_OPTIONS.fontSize};
  }
  .last_login {
    padding: 3px 16px 0px;

    color: #5e5e61;
  }

  .profile_type {
    display: flex;
    align-items: center;
    padding-top: 12px;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 16px 6px;
      gap: 14px;
      position: relative;
      background-color: transparent;
      border: 0;

      transition: all 0.3s ease-in-out;

      cursor: pointer;
      &:hover {
        background: #e3e2e6;
        border-radius: 0px;
      }
    }
    .profile_name {
      display: flex;
      align-items: center;
      gap: 14px;
      color: black;
      i {
        min-width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          display: block;
        }
      }

      span {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        display: block;
        color: #2f3034;
      }
    }
    .profile_mode {
      span {
        display: flex;
        font-family: Inter;
        font-size: 10px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.10000000149011612px;
        text-align: center;
        padding: 4px 8px 2px;
        gap: 4px;
        border-radius: 12px;
        background: #d0f3ff;
        color: #037193;
        height: auto;
      }
      // .admin {
      //   color: var(--chakra-colors-tertiary-90);
      //   background: var(--chakra-colors-tertiary-30);
      // }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    button {
      font-size: ${FONT_USER_INFO_BUTTON.fontSize};
      line-height: ${FONT_USER_INFO_BUTTON.lineHeight};
      padding: 8px 16px;
      &:hover {
        color: var(--chakra-colors-secondary-130);
      }
    }
  }
  .profile_menu {
    > li {
      position: relative;
      :nth-last-child(2) {
        padding-top: 14px;
        margin-top: 10px;

        button {
          // color: var(--chakra-colors-primary-100);
        }
        &:after {
          content: '';
          display: block;
          border-top: 1px solid #c7c6ca;

          width: -webkit-calc(100% - 40px);
          width: calc(100% - 40px);

          position: absolute;
          box-sizing: border-box;
          top: 0;
          left: 20px;
        }
      }
    }

    li {
      padding: 0px;
      button {
        font-weight: ${FONT_USER_INFO_ITEM.fontWeight};
        justify-content: flex-start;
        color: black;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 18px;
        position: relative;
        background-color: transparent;
        border: 0;
        border-radius: 8px;
        padding-left: 16px;
        padding-bottom: 12px;
        transition: all 0.3s ease-in-out;
        height: auto;

        &:disabled {
          color: var(--chakra-colors-grey-60);
          background: transparent;
          cursor: default;
        }
        &:hover {
          background: #e3e2e6;
          border-radius: 0px;
          &:disabled {
            background: transparent;
          }
        }
      }

      i {
        min-width: 24px;
        min-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a {
        text-decoration: none;
      }

      ul {
        div {
          position: absolute;
          right: 100%;
          padding: 12px 0;
          margin-right: -10px;

          top: 0;
          background-color: white;
          min-width: 212px;
          border-radius: 20px;
          box-shadow: 0px 4px 17px var(--chakra-colors-secondary-90);

          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease-in-out;
          max-height: 550px;
          overflow-y: auto;

          li {
            padding-left: 15px;
            padding-right: 15px;
            button {
              padding: 4px 8px;
              text-align: left;
            }
          }
        }
        .cambiar-empresa-active {
          opacity: 1;
          visibility: visible;
          margin-right: 0;
        }
      }
    }

    .more_arrow {
      position: absolute;
      right: 15px;
    }
  }
`;

export default StyledUserInfo;
