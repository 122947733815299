/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
import {
  pushAnalyticsFromError,
  setCheckOtp,
  setPermissions,
  throttle,
  useErrorMessage,
  useSesionKey,
  // eslint-disable-next-line import/no-unresolved
} from '@react-ib-mf/style-guide-ui';
import { LoadingScreenComponent, LoadingScreenComponentProps } from '@wow/loading-screen';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import Sidebar from './components/aside/sidebar';
import HeaderNav from './components/header/HeaderNav';
import IdleModal from './components/iddleModal';
import ErrorScreen from './components/main/ErrorScreen';
import MigrationInfoView from './components/migrationInfoView';
import RenewToken from './components/migrationInfoView/RenewToken';
import SimpleBanner from './components/SimpleBanner';
import TermsAndConditions from './components/termsAndConditionsView';
import Toast from './components/toast';
import VinculationView from './components/vinculationView';
import WelcomePopup from './components/welcomePopup';
import WizardPopup from './components/wizardPopup';
import useAxiosErrorHandler from './hooks/useAxiosErrorHandler';
import useIdleTimer from './hooks/useIdleTimer';
import useKeepSibAlive from './hooks/useKeepSibAlive';
import plainText from './lang/es.json';
import { AuthContext } from './providers/authProvider';
import { getAuthorization, postUpdateTyC } from './services/api';
import { AUTHORIZATION_STATUS, TYPE_MIGRATION } from './utils/constants';
import { CHANGE_ENTITY_SIB_FORM, submitSibForm } from './utils/sibForms';
import { hasSibSession, isSibActive } from './utils/sibSession';

interface Acceptance {
  offeringId: number | string;
  legalNotificationId: number | string;
  status: boolean;
}

export type TypeMigration = null | 'Y' | 'HT' | 'ST';

function App() {
  const { hasAuthParams, signinRedirect, signinRedirectCallback, logout, validateSession } = useContext(AuthContext);
  const [isAuth, setIsAuth] = useState(false);
  const [, setSessionKey] = useSesionKey();
  const { showErrorMessage } = useErrorMessage();
  const [showLoading, setShowLoading] = useState(true);
  const [showMigrationInfo, setShowMigrationInfo] = useState<TypeMigration>(null);

  const realoadEntirePage = () => {
    window.location.href = window.location.origin;
  };

  const onError = error => {
    showErrorMessage({ title: plainText.toast.error.appLastDelayedDoc, error });
  };

  function LoadingScreen(props: LoadingScreenComponentProps) {
    return (
      <>
        <style>@import url('https://fonts.cdnfonts.com/css/red-hat-display');</style>
        <style>`@import url(`https://fonts.cdnfonts.com/css/inter`);`</style>
        <LoadingScreenComponent {...props} />
      </>
    );
  }

  const dataForLoadingScreen = useMemo(() => {
    return {
      title: plainText.loadingScreen.app.title,
      description: plainText.loadingScreen.app.description,
    };
  }, [plainText.loadingScreen.app.title, plainText.loadingScreen.app.description]);

  useEffect(() => {
    const dataLoadingScreen: LoadingScreenComponentProps = {
      title: plainText.loadingScreen.app.title,
      description: plainText.loadingScreen.app.description,
    };
    LoadingScreen(dataLoadingScreen);
  }, [dataForLoadingScreen]);

  // Authorizations endpoint
  const {
    isLoading: loadingAuthorization,
    isRefetching: refetchingAuthorization,
    refetch: refetchAuthorization,
    isError: isErrorAuthorization,
    data: authorizationData,
  } = useQuery('authorization', () => getAuthorization().then(resp => resp?.data?.data), {
    enabled: isAuth,
    onError: pushAnalyticsFromError,
    onSuccess: data => {
      setPermissions(data?.user?.permissions || []);
      setCheckOtp(data?.user?.additionalPermissions?.hasOtp);
      // If the authorizations endpoint return a ok status the app can continue
      if (`0${data?.codeStatus}`.slice(-2) === AUTHORIZATION_STATUS.SUCCESSFUL) {
        setSessionKey({ auth: true });
      }

      if (data?.user?.additionalInformation?.migrationTypeOtpLegacy && !sessionStorage.getItem('CheckMigrateTokenLegacy')) {
        setShowMigrationInfo(data?.user?.additionalInformation?.migrationTypeOtpLegacy);
        setSessionKey({ auth: false });
      }
    },
  });

  // Accept/Pospone TyC endpoint
  const { mutate: updateAcceptance, isLoading: loadingAcceptance } = useMutation((data: Acceptance) => postUpdateTyC(data));

  const onChangeAcceptance = (legalNotificationId, offeringId) => (accept: boolean) =>
    updateAcceptance(
      { offeringId, legalNotificationId, status: accept },
      {
        onSuccess: () => refetchAuthorization(),
        onError,
      },
    );

  const onChangeEntity = async () => {
    try {
      // Remove this to disable SIB-P2 session sync
      if (process.env.CHANGE_ENTITY_USING_SIB) {
        if (hasSibSession()) {
          // If there is session in sib, Do a post to sib listEntidades method
          submitSibForm(CHANGE_ENTITY_SIB_FORM);
        } else {
          // If there is not sib session
          // Do a Get to the sib listEntidades method, but creating a session.
          window.location.replace(process.env.URL_CHANGE_ENTITY_SIB);
        }
      } else {
        // Just call cas change entity method
        await signinRedirect({ prompt: 'login' });
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (changeEntityError) {
      showErrorMessage({ description: plainText.toast.error.appChangeEntity });
      setShowLoading(false);
    }
  };

  const backToSignin = () => {
    if (authorizationData?.entity?.isMultipleEntities) {
      // Redirect entities selection
      onChangeEntity();
    } else {
      // Close session
      logout();
    }
  };

  const renderCurrentState = () => {
    // Define different rendering according to the status
    if (showLoading || loadingAuthorization || refetchingAuthorization) {
      // LOADING state
      return <LoadingScreen title={plainText.loadingScreen.app.title} description={plainText.loadingScreen.app.description} />;
    }
    // TO-DO: Avoid this... "00" can be parsed to 0 or '0' after bff response
    const codeStatus = `0${authorizationData?.codeStatus}`.slice(-2);
    if (!isErrorAuthorization) {
      // SUCCESS state
      if (codeStatus === AUTHORIZATION_STATUS.OPERATOR_NOT_ENABLED) {
        // Operator not enabled -> Redirect SIB
        window.location.replace(process.env.URL_HOME_SIB);
        return <LoadingScreen title={plainText.loadingScreen.app.title} description={plainText.loadingScreen.app.description} />;
      }

      // Pending vinculation key code status
      if (codeStatus === AUTHORIZATION_STATUS.PENDING_VINCULATION_KEY || codeStatus === AUTHORIZATION_STATUS.BLOCKED_BY_VINCULATION_KEY) {
        return (
          <div>
            <VinculationView backToSignin={backToSignin} refetchAuthorization={refetchAuthorization} vinculationData={authorizationData} />
            <Toast />
          </div>
        );
      }

      // Pending T&C code status
      if (
        (codeStatus === AUTHORIZATION_STATUS.TYC_PENDING || codeStatus === AUTHORIZATION_STATUS.TYC_PENDING_NOT_POSTPONE) &&
        authorizationData?.entity?.pendingDocument
      ) {
        const { subject, resume, content, legalNotificationId, offeringId } = authorizationData.entity.pendingDocument;
        return (
          <>
            <SimpleBanner
              entityName={authorizationData?.entity?.name}
              firstName={authorizationData?.user?.firstName}
              lastName={authorizationData?.user?.lastName}
            />
            <TermsAndConditions
              showLaterButton={codeStatus === AUTHORIZATION_STATUS.TYC_PENDING}
              title={subject}
              description={resume}
              document={content}
              onChangeAcceptance={onChangeAcceptance(legalNotificationId, offeringId)}
              isLoading={loadingAcceptance || showLoading}
            />
            <Toast />
          </>
        );
      }

      // Success code status
      if (codeStatus === AUTHORIZATION_STATUS.SUCCESSFUL) {
        switch (showMigrationInfo) {
          case TYPE_MIGRATION.Y:
            return (
              <MigrationInfoView
                dateLimitRenewOtpLegacy={authorizationData?.user?.additionalInformation?.dateLimitRenewOtpLegacy}
                setShowMigrationInfo={setShowMigrationInfo}
                setSessionKey={setSessionKey}
              />
            );

          case TYPE_MIGRATION.HARDTOKEN:
            return <RenewToken typeToken='Token' setShowMigrationInfo={setShowMigrationInfo} setSessionKey={setSessionKey} />;

          case TYPE_MIGRATION.SOFTTOKEN:
            return <RenewToken typeToken='Token' viewBtnLogout />;

          default:
            return (
              <BrowserRouter>
                <IdleModal isOpen={showIdleModal} onClose={closeIdleModal} remainingTime={remainingTime} />
                <HeaderNav onChangeEntity={onChangeEntity} logout={logout} />
                <Sidebar />
                <WelcomePopup />
                <WizardPopup />
                <Toast />
              </BrowserRouter>
            );
        }
      }
    }

    // ERROR state
    return <ErrorScreen onRetry={isAuth ? refetchAuthorization : realoadEntirePage} />;
  };

  const { prompted, remainingTime, closePrompt, activate } = useIdleTimer(logout);

  const throttledValidateSession = throttle(validateSession, 5000);
  useAxiosErrorHandler({
    '401': throttledValidateSession,
    '403': throttledValidateSession,
  });

  // Remove this to disable SIB-P2 session sync
  // Use prompted and closePrompt as isOpen and onClose IdleModal props
  const [showIdleModal, setShowIdleModal] = useState(false);
  useKeepSibAlive();
  useEffect(() => {
    if (prompted && isSibActive()) {
      activate();
      return;
    }
    setShowIdleModal(prompted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompted]);
  const closeIdleModal = () => {
    setShowIdleModal(false);
    closePrompt();
  };
  // SIB-P2 session sync - End

  useEffect(() => {
    if (hasAuthParams()) {
      signinRedirectCallback()
        .then(() => {
          window.history.replaceState({}, document.title, localStorage.getItem('redirectUri'));
          setIsAuth(true);
        })
        .finally(() => setShowLoading(false));
    } else {
      signinRedirect().catch(() => setShowLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return renderCurrentState();
}

export default App;
