import styled from 'styled-components';

import {
  FONT_DIRECT_LINKS_BOLDER,
  FONT_DIRECT_LINKS_POPUP_FIELD,
  FONT_DIRECT_LINKS_POPUP_FILTER_TEXT,
  FONT_DIRECT_LINKS_POPUP_FILTER_TITLE,
  FONT_DIRECT_LINKS_POPUP_MESSAGE_FAV,
  FONT_DIRECT_LINKS_POPUP_NAME,
} from '../../../../designTokens/fonts';

const StyledModalContent = styled.div`
  .shortcuts {
    background-color: var(--chakra-colors-white);
    border-radius: 10px;
    border: solid 1px var(--chakra-colors-secondary-60);

    .grid-items {
      display: grid;
      margin-right: 5px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 25px;
    }
    .contentItems {
      overflow: auto;
      &::-webkit-scrollbar {
        height: 0px;
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: var(--chakra-colors-white);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--chakra-colors-grey-60);
        border-radius: 8px;
        border: solid 1px var(--chakra-colors-grey-60);
      }
    }
    .four-cols {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    &.pinned {
      background-color: var(--chakra-colors-grey-40);
      border: 0;
      margin-bottom: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      row-gap: 23px;

      li {
      }
    }

    .item {
      border: 1px solid var(--chakra-colors-tertiary-50);
      backdrop-filter: blur(10px);
      border-radius: 10px;
      width: 100%;
      position: relative;

      display: flex;
      align-items: center;
      gap: 12px;
      min-height: 48px;

      &.more {
        text-align: center;
        justify-content: center;
      }

      button {
        border: 0;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        width: 22px;
        height: 22px;
        background-color: var(--chakra-colors-grey-100);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        position: absolute;
        right: -8px;
        top: -8px;

        svg {
        }

        &:hover {
          background-color: var(--chakra-colors-tertiary-120);
          transform: scale(1.1);
        }
      }
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--chakra-colors-tertiary-40);
        border: 1px solid var(--chakra-colors-tertiary-50);
        box-sizing: border-box;
        box-shadow: inset 0px 0px 5px var(--chakra-colors-tertiary-40);
        border-radius: 10px;
        min-height: 49px;
        min-width: 48px;
        width: fit-content;
        position: relative;
        margin-left: -1px;
        margin-top: -2px;
        top: 1px;
      }
      .custom-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--chakra-colors-tertiary-40);
        border: 1px solid var(--chakra-colors-tertiary-50);
        box-sizing: border-box;
        box-shadow: inset 0px 0px 5px var(--chakra-colors-tertiary-40);
        border-radius: 10px;
        min-height: 49px;
        min-width: 48px;
        width: fit-content;
        position: relative;
        margin-left: -1px;
        margin-top: -2px;
        top: 1px;
        padding: 8px;
      }
      strong {
        font-style: ${FONT_DIRECT_LINKS_POPUP_NAME.fontStyle};
        font-size: ${FONT_DIRECT_LINKS_POPUP_NAME.fontSize};
        font-weight: ${FONT_DIRECT_LINKS_POPUP_NAME.fontWeight};
        line-height: ${FONT_DIRECT_LINKS_POPUP_NAME.lineHeight};
        color: var(--chakra-colors-tertiary-110);
        padding-right: 12px;
      }
    }

    &.pinned {
      padding: 15px;
    }
    &:not(.pinned) {
      padding: 0 15px 15px 15px;
    }
  }
  .messageFavoritos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    span {
      display: block;
      font-size: ${FONT_DIRECT_LINKS_POPUP_MESSAGE_FAV.fontSize};
    }
    img {
      display: block;
    }
  }
  .filter {
    text-align: center;
    padding: 30px 30px 3px;
    h3 {
      font-size: ${FONT_DIRECT_LINKS_POPUP_FILTER_TITLE.fontSize};
      font-weight: ${FONT_DIRECT_LINKS_POPUP_FILTER_TITLE.fontWeight};
      line-height: ${FONT_DIRECT_LINKS_POPUP_FILTER_TITLE.lineHeight};
      color: var(--chakra-colors-grey-120);
      margin: 0 0 0px 0;
    }
    p {
      margin: 0 0 12px 0;
      font-size: ${FONT_DIRECT_LINKS_POPUP_FILTER_TEXT.fontSize};
      color: var(--chakra-colors-grey-120);
    }

    .field {
      position: relative;
      max-width: 335px;
      margin: auto;
      input {
        width: 100%;
        height: 38px;
        border-radius: 10px;
        font-size: ${FONT_DIRECT_LINKS_POPUP_FIELD.fontSize};
        line-height: ${FONT_DIRECT_LINKS_POPUP_FIELD.lineHeight};
        padding: 0px 30px 2px 12px;
        border: solid 1px var(--chakra-colors-grey-60);
        color: var(--chakra-colors-tertiary-110);
        &::-webkit-input-placeholder {
          color: var(--chakra-colors-grey-60);
        }
        &::-moz-placeholder {
          color: var(--chakra-colors-grey-60);
        }
        &:-ms-input-placeholder {
          color: var(--chakra-colors-grey-60);
        }
        &:-moz-placeholder {
          color: var(--chakra-colors-grey-60);
        }
      }
      button {
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 100%;
        padding: 0;
        border: 0;
        background-color: transparent;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .btns {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 24px;
    padding: 20px 0 0 0;
  }

  @media (max-width: 767px) {
    .shortcuts {
      ul {
        li {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding: 30px;

    .shortcuts {
      padding: 20px 30px 20px 30px;
      .contentItems {
        max-height: 190px;
        padding: 0px 45px 0px 45px;
      }
      ul {
      }

      &.pinned {
        padding: 20px;
        ul {
          li {
            width: 48%;
          }
        }
      }

      &:not(.pinned) {
        ul {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 23px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .shortcuts {
      &:not(.pinned) {
        ul {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .shortcuts {
      &.pinned {
        ul {
          li {
            width: 18%;
            &:last-child {
              max-width: 48px;
            }
          }
        }
      }

      &:not(.pinned) {
        ul {
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }
      }
    }
  }

  @media (min-width: 1660px) {
    padding: 10px;
  }

  .bolderFont {
    font-size: ${FONT_DIRECT_LINKS_BOLDER.fontSize};
    font-weight: ${FONT_DIRECT_LINKS_BOLDER.fontWeight};
    margin-top: 2px;
    display: block;
  }
  .subTitleBold {
    font-size: ${FONT_DIRECT_LINKS_BOLDER.fontSize};
    font-weight: ${FONT_DIRECT_LINKS_BOLDER.fontWeight};
    margin: 15px 0 15px 0;
  }
`;

export default StyledModalContent;
