import { CHANGE_ENTITY_SIB_FORM } from '../../utils/sibForms';
import SibForm from './index';

function ChangeEntityForm() {
  return (
    <SibForm
      fields={[
        { name: 'isLoggingOff', value: 'Y' },
        { name: 'logoffAnyway', value: 'Y' },
        { name: 'ojoConElNetscape', value: '' },
        { name: 'dispatch', value: 'listEntidades' },
      ]}
      identifier={CHANGE_ENTITY_SIB_FORM}
    />
  );
}

export default ChangeEntityForm;
